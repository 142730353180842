import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
function SocioArea() {
    return (

        <div className='socioParallax'>
            <div className='container socioArea'>
                <div>
                    <h1 className='h1 mt-5 text-center'>Torne-se Sócio</h1>
                </div>
                <div>
                    <p className='text-center'>Faça parte desta associação</p>
                </div>
                <div>

                    <Link to='/socio' className='text-decoration-none'><Button className='btnSocio mb-5'>Inscrever-me</Button></Link>

                </div>
            </div >
        </div>


    )
}

export default SocioArea