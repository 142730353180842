import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import axios from 'axios';
import CryptoJS from 'crypto-js'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Formacoes() {
    const [allFormacoes, setAllFormacoes] = useState([])
    const [encIDs, setEncIDs] = useState([])

    const encrptID = (id) => {

        let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
        
        while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
            encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
        }
        return encryptedId

    }
    useEffect(() => {
        axios.get(`/formacao/all_formacoes`).then((response) => {
            setAllFormacoes(response.data.formacoes)
            const theID = response.data.formacoes.map(form => encrptID(form.id_formacao))
            setEncIDs(theID)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerFormacoes P-5 mb-5'>
                <h1 className='h1 text-center'>Formações Disponíveis</h1>
            </div>
            <div className='container mb-5'>
                <div className='row'>
                    {

                        allFormacoes.length > 0 ? (
                            allFormacoes.map((value, index) => {
                                const id_formacao = encIDs[index]
                                return (
                                    <div className="col-md-3 formArea" key={index}>
                                        <img src={value.imagem_formacao} alt="Avatar" className="image img-fluid" />
                                        <div className="overlay">
                                            <h4 className='h4 mb-5 mt-5 formTitle text-center'>{value.designacao}</h4>
                                            <Link type='button' className='btn btnFormacao' to={`/selected_formacao/${encodeURIComponent(id_formacao)}`}>Ler Mais</Link>
                                        </div>
                                    </div>
                                )
                            })
                        )

                            :
                            <div className='container'>
                                <br /><br />
                                <h1 className='h1 text-center mt-5 mb-5'>Sem Formações neste momento</h1>
                                <br /><br />
                            </div>
                    }

                </div>
            </div>

        </motion.div>
    )
}

export default Formacoes