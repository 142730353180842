import React from 'react'
import { motion } from 'framer-motion'
import Navbar from '../../principal/Navbar'
import Footer from '../../principal/Footer'
import TabelaNoticia from '../../administracao/TabelaNoticia'
import TabelaSocio from '../../administracao/TabelaSocio'
import TabelaCorporacao from '../../administracao/TabelaCoporacao'

const containerTransition = {
    hidden: {
        x: '-100vw',
        opacity: 0
    },
    visible: {
        x: '0',
        opacity: 1,
        transition: { delay: 1, duration: 1.5 },
    },
    exit: {
        x: '+100vw',
        transition: { ease: 'easeInOut' }
    }
}

function Corporacao() {
    const adminPage = 1
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Navbar isAdmin={adminPage} />
            <TabelaCorporacao/>
            <Footer />
        </motion.div>
    )
}

export default Corporacao