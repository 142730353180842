import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import axios from 'axios'

import CryptoJS from 'crypto-js'

const backgroundModal = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
}

const modalIntro = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "200px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const encrptID = (id) => {

    let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()

    while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
        encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
    }

    return encryptedId

}

function Modal({ showModal, setShowModal }) {
    const [noticia, setNoticia] = useState([])
    let the_id = '';

    useEffect(() => {
        axios.get('/noticia/destaque_noticia')
            .then((response) =>
                setNoticia(response.data.noti)
            ).catch((err) => console.log(err))
    }, [])

    const handleCloseModal = () => {
        //localStorage.setItem('modalClosed', true)
        setShowModal(false);
    }
    return (
        <AnimatePresence exitBeforeEnter>
            {
                showModal && (
                    <motion.div className='backgroundModal'
                        variants={backgroundModal}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"

                    >
                        <motion.div className='modalIntro'
                            variants={modalIntro}
                        >
                            {
                                noticia.map((value) => {
                                    return (
                                        <div className='container-fluid' id='img-area'>
                                            <h4 className='h4 text-center'>{value.titulo_noticia}</h4>
                                            <Link to={`/noticia/${the_id = encrptID(value.id_noticia)}`}><img src={value.imagem_noticia} className='img-fluid' alt='' /></Link>
                                        </div>
                                    )
                                })
                            }
                            <button onClick={handleCloseModal} className="btn mt-3">Fechar</button>
                        </motion.div>



                    </motion.div>
                )
            }
        </AnimatePresence >
    )
}

export default Modal