import React, { useState, useRef, useEffect } from 'react'
import { Row, Carousel } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import * as Yup from 'yup'
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from 'antd';
import photoOne from '../../images/photo_1.jpg'
import photoTwo from '../../images/photo_2.jpg'
import photoThree from '../../images/photo_4.jpg'
import parceria_1 from '../../images/parcerias/afben_saude.png'
import parceria_2 from '../../images/parcerias/cross_fundao.png'
import parceria_3 from '../../images/parcerias/detelamend.png'
import parceria_4 from '../../images/parcerias/domingos_ramos.png'
import parceria_5 from '../../images/parcerias/fisiabeira.png'
import parceria_6 from '../../images/parcerias/sorriso_da_cereja.png'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Socio() {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const captchaRef = useRef()
    const [useCheck, setUseCheck] = useState(false)


    const initialValues = {
        nomeSocio: "",
        moradaSocio: "",
        codSocio: "",
        localidadeSocio: "",
        nascSocio: "",
        contatoSocio: "",
        emailSocio: "",
        nifSocio: "",
        imageSocio: "",
        conhecimentoSocio: ""


    }
    const validationSchema = Yup.object().shape({
        nomeSocio: Yup.string().required("Campo Vazio"),
        moradaSocio: Yup.string().required("Campo Vazio"),
        codSocio: Yup.string().required("Campo Vazio").matches(/^[0-9]{4}-[0-9]{3}$/, 'O código postal deve ter o formato 0000-000'),
        localidadeSocio: Yup.string().required("Campo Vazio"),
        nascSocio: Yup.date().required("Campo Vazio").max(new Date(), 'Data de nascimento inválida').typeError('Inválido'),
        contatoSocio: Yup.string().matches(/^(9[1236][0-9]{7})|(2\d{8})$/, 'Insira um número de telefone válido').required("Campo Vazio"),
        emailSocio: Yup.string().required('Campo Vazio').test('verifica_email', 'Email já existente', async (value) => {
            if (!value) { return true }


            const response = await axios.get(`/socio/check_email`, { params: { email: value } })

            return !response.data.exists

        }),
        nifSocio: Yup.string().test('nifSocio', 'NIF inválido', (value) => {
            const nif = value.replace(/\D/g, '');
            if (nif.length !== 9) {
                return false;
            }
            const checkDigit = parseInt(nif.substring(8, 9), 10);
            let totalSum = 0;
            for (let i = 0; i < 8; i++) {
                totalSum += parseInt(nif.substring(i, i + 1), 10) * (9 - i);
            }
            const computedCheckDigit = 11 - (totalSum % 11);
            return computedCheckDigit === checkDigit;
        })
            .test('verifica_nif', 'NIF já existente', async (value) => {
                if (!value) { return true }

                const response = await axios.get(`/socio/check_nif`, { params: { nif: value } })
                return !response.data.exists

            }).required("Campo Vazio"),
        /*
    imageSocio: Yup
        .mixed()
        .test('fileType', 'Apenas imagens são permitidas!', value => {

            if (value) {
                return value[0] && ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value[0].type)
            }
            return true;
        })
        .test('fileSize', 'A imagem deve ter no máximo 10MB!', value => {
            if (value) {
                return value[0] && value[0].size <= 10000000;
            }
            return true;
        }).required('Campo Vazio'),*/
        conhecimentoSocio: Yup.boolean().oneOf([true], 'Tem que concordar com os termos e condições').required("Tem que concordar com os termos e condições"),
        /*repSocio: Yup.string().test('recaptcha', 'Por favor, confirme que é um humano', () => {
            return useCheck.current?.getValue();
        })*/

    })

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }

    const downloadFile = () => {
        axios.get('/download_ficha', { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))

                const link = document.getElementById('link_file')
                link.href = url
            })
            .catch(error => {
                console.error(error);
                openModal('Erro')
            })
    }

    const onSubmit = (data) => {

        axios.post('/socio/new_socio', data)
            .then((response) => {
                console.log(response)
            })
            .catch((err) =>
                console.error(err)
            )
        openModal('Inscrição realizada com sucesso')
        reset()

    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })
    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Row className='bannerSocio P-5 mb-5'>
                <h1 className='h1 text-center'>Sócio</h1>
            </Row>
            <div className='container'>
                <h2 className='h2 text-center titleSection mb-5'>Apoie esta causa</h2>
                <p className='text-center'><b>A sua participação, o seu contributo são fundamentais para concretizarmos eficientemente a nossa missão.</b></p>
                <div className='container textArea'>
                    <p className='text-justify'>
                        Associe-se a esta nobre causa dos Bombeiros, pode fazê-lo utilizando os formulários respetivos ou imprimindo e preenchendo o documento
                        que disponibilizamos para download.
                        Para os associados que optarem por imprimir o documento disponibilizado, solicitamos a sua entrega posterior junto da nossa secretaria,
                        no endereço disponível na nossa página de contatos.
                    </p>
                </div>
            </div>
            <div className='container-fluid '>
                <h2 className='h2 text-center titleSection mt-5'>Parcerias</h2>
                <hr className='lineSection' />
                <div className='container parcerias_zone'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_1} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'>Descontos<br />5% a 10% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_2} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'> Desconto<br />10% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_3} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'>Desconto<br />20% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_4} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'>Desconto<br />10% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_5} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'>Desconto<br />10% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-4 col_parceria'>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <img src={parceria_6} alt="Avatar" className='img-fluid' />
                                    </div>
                                    <div className="flip-card-back">
                                        <p className='fs-4 mt-5 fw-bold'>Desconto<br />10% nos serviços prestados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='lineSection' />
            <div className='mt-5 container newSocio'>
                <h1 className='h1 text-center titleSection mb-5'>Torne-se Sócio</h1>
                <p className='text-center mt-3'>Preencha o formulário ou então clique  
                <b><a id='link_file' href='ficha_socio.pdf' download="ficha_socio.pdf"> neste link</a></b>
                </p>

                <div className='mb-5 container'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-floating mb-3'>
                            <input type="text" className='form-control' autoComplete='off' {...register('nomeSocio')} />
                            <label className='floatingInput'>Nome Completo</label>
                            <p className='text-danger'>{errors.nomeSocio?.message}</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-xs-6'>
                                <div className='form-floating mb-3'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('moradaSocio')} />
                                    <label className='floatingInput'>Morada</label>
                                    <p className='text-danger'>{errors.moradaSocio?.message}</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('codSocio')} />
                                    <label className='floatingInput'>Código Postal</label>
                                    <p className='text-danger'>{errors.codSocio?.message}</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('localidadeSocio')} />
                                    <label className='floatingInput'>Localidade</label>
                                    <p className='text-danger'>{errors.localidadeSocio?.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="date" className='form-control' autoComplete='off' {...register('nascSocio')} />
                                    <label className='floatingInput'>Data Nascimento</label>
                                    <p className='text-danger'>{errors.nascSocio?.message}</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('contatoSocio')} />
                                    <label className='floatingInput'>Contato</label>
                                    <p className='text-danger'>{errors.contatoSocio?.message}</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="email" className='form-control' autoComplete='off' {...register('emailSocio')} />
                                    <label className='floatingInput'>Email</label>
                                    <p className='text-danger'>{errors.emailSocio?.message}</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-xs-3'>
                                <div className='form-floating mb-3'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('nifSocio')} />
                                    <label className='floatingInput'>NIF</label>
                                    <p className='text-danger'>{errors.nifSocio?.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {
                                /**
                                 * <div className='col-md-4 col-xs-4'>
                                <input type="file" className='form-control' name='imageSocio' {...register('imageSocio')} />
                                <p className='text-danger'>{errors.imageSocio?.message}</p>
                            </div>
                                 */
                            }
                            <div className='col-md-12 col-xs-12'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" {...register('conhecimentoSocio')} />
                                    <label className="form-check-label" htmlFor="conhecimentoSocio">
                                        Autorizo recolher e tratar os meus dados pessoais para efeitos de contactos posteriores para
                                        divulgação de eventos e tratamento de questões relacionadas com o meu estatuto de associado/a.
                                        Tenho consciência de que estes dados serão apenas para uso exclusivo da Associação e não serão
                                        facultados a terceiros,seja por que motivo for, sem a minha autorização expressa nesse sentido.
                                    </label>
                                    <p className='text-danger'>{errors.conhecimentoSocio?.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2 mt-5 mb-5">
                            <button type='submit' className='btn recrutamento_button'>Inscrever-se</button>
                            <button type='reset' className='btn recrutamento_button'>Limpar</button>
                        </div>
                        <Modal
                            title={
                                <div>
                                    <h4 className='text-center fw-bold text-danger'>Inscrição de novo sócio</h4>
                                    <hr className='text-danger' />
                                </div>
                            }
                            open={isModalVisible}
                            onOk={() => {
                                setIsModalVisible(false)
                            }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>

                        </Modal>
                    </form>
                </div>
            </div>
        </motion.div>
    )
}

export default Socio