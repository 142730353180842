import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import axios from 'axios';
import CryptoJS from 'crypto-js'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function PovosConstrucao() {

    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Container fluid>
                <Row className='bannerNoticia P-5 mb-5'>
                    <h1 className='h1 text-center'>Três Povos</h1>
                </Row>
            </Container>
            <div className='container'>
                <br/><br/>
                <h1 className='h1 text-center mb-5'>Página em construção</h1>
                <br/><br/>
            </div>
        </motion.div>
    )
}

export default PovosConstrucao