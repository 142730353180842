import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Typography } from '@mui/material';
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'


function TabelaRecrutamento() {
    const [listRecrutamento, setListRecrutamento] = useState([])
    

    const downloadPDF = (pdfData, pdfFilename) => {
        const link = document.createElement('a');
        link.href = pdfData;
        link.download = pdfFilename;
        link.click();
      }
    

    const columns = useMemo(() => [
        {
            accessorKey: 'nome',
            header: 'Nome',
        },
        {
            accessorKey: 'localidade',
            header: 'Localidade',
        },
        {
            accessorKey: 'contacto',
            header: 'Contacto',
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            id: 'downloadCV',
            header: 'Download CV',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <button className='btn btn-outline-secondary btnNovoSocio' onClick={() => downloadPDF(row.original.cv,`cv_${row.original.nome}.PDF`)}>Baixar</button>
            )
        },
        {
            id: 'verPerfil',
            header: 'Ver',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <Link className='btn btn-outline-secondary btnNovoSocio' to={`/administracao/detalhe_recruta/${row.original.cc}`}>Baixar</Link>
            )
        }
    ], [])
    useEffect(() => {
        axios.get('/recrutamento/all_recrutamentos')
            .then((response) => {
                setListRecrutamento(response.data.recrutas)

            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Recrutamento</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'></div>
            <div className='container-fluid'>
                <MaterialReactTable columns={columns} data={listRecrutamento}
                    enableColumnFilters={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnResizing
                    enableColumnActions={false}
                    enableSelectAll={false}
                    muiTableHeadCellProps={{
                        style: {
                            color: 'white',
                            fontSize: 24

                        }
                    }}
                    muiTableHeadRowProps={{
                        style: {
                            backgroundColor: 'orange'
                        }
                    }}
                    muiTopToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                    muiBottomToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                />
            </div>
            <br /><br />
        </div>
    )
}

export default TabelaRecrutamento