import { Routes, Route, useLocation } from 'react-router-dom'
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import HomePage from './components/pages/HomePage'
import MensagemPresidente from './components/pages/MPresidente'
import AEstatutos from './components/pages/Estatutos_Associacao'
import AOrgaos from './components/pages/Orgaos_Associacao'
import ANoticias from './components/pages/Noticias_Associacao'
import MensagemComandante from './components/pages/MComandante'
import BCorpo from './components/pages/BCorpo'
import BHistoria from './components/pages/BHistoria'
import BMissao from './components/pages/BMissao'
import BHonra from './components/pages/BHonra'
import BViaturas from './components/pages/BViaturas'
import BFanfarra from './components/pages/BFanfarra'
import SeccaoSilvares from './components/pages/SeccaoSilvares'
import SeccaoSoalheira from './components/pages/SeccaoSoalheira'
import SeccaoPovos from './components/pages/SeccaoPovos'
import BEscolinha from './components/pages/BEscolinha'
import Contactos from './components/pages/Contactos'
import FormacaoSel from './components/pages/FormacaoSel'
import FormacaoGeral from './components/pages/FormacaoGeral'
import AreaSocio from './components/pages/AreaSocio'
import BRecrutamento from './components/pages/BRecrutamento'
import DepartamentoFormacao from './components/pages/Departamento_Formacao'
import Principios from './components/pages/Principios'
import AdminArea from './components/pages/AdminArea'
import ANoticia from './components/pages/Noticia_Associacao'
import AdminPanel from './components/administracao/AdminPanel'
import AdminNoticia from './components/administracao/AdminNoticia'
import AdminHub from './components/pages/adminPages/AdminHub'
import NewNotice from './components/pages/adminPages/NewNotice'
import NoticeList from './components/pages/adminPages/NoticeList'
import SocioList from './components/pages/adminPages/SocioList'
import NewFormacao from './components/pages/adminPages/NewFormacao'
import InscricaoFormacao from './components/pages/InscricaoFormacao'
import FormacaoList from './components/pages/adminPages/FormacaoList'
import DetailFormacao from './components/pages/adminPages/DetailFormacao'
import RecrutamentoList from './components/pages/adminPages/RecrutamentoList'
import CadeteList from './components/pages/adminPages/CadeteList'
import RecrutamentoDetalhe from './components/pages/adminPages/RecrutamentoDetalhe'
import MessagesList from './components/pages/adminPages/MessagesList'
import EnviarEmail from './components/pages/adminPages/EnviarEmail'
import ChangeFormacao from './components/pages/adminPages/ChangeFormacao'
import ChangeNotice from './components/pages/adminPages/ChangeNotice'
import PovosConstrucao from './components/bombeiros/PovosConstrucao'

import RequireAuth from './components/reqAuth/RequireAuth';
import LinkButtons from './components/pages/LinkButtons';
import useAuth from './auth/useAuth';
import Corporacao from './components/pages/adminPages/Corporacao'
function App() {

  const location = useLocation()
  const { auth } = useAuth()

  const routes = [
    {
      path: "/",
      element: <HomePage />
    },
    {
      path: "/mensagem_presidente",
      element: <MensagemPresidente />
    },
    {
      path: "/estatutos",
      element: <AEstatutos />
    },
    {
      path: "/orgaos_sociais",
      element: <AOrgaos />
    },
    {
      path: "/noticias",
      element: <ANoticias />
    },
    {
      path: "/noticia/:id_noticia",
      element: <ANoticia />
    },
    {
      path: "/mensagem_comandante",
      element: <MensagemComandante />
    },
    {
      path: "/missao",
      element: <BMissao />
    },
    {
      path: "/historia",
      element: <BHistoria />
    },
    {
      path: "/corpo_ativo",
      element: <BCorpo />
    },
    {
      path: "/quadro_honra",
      element: <BHonra />
    },
    {
      path: "/viaturas",
      element: <BViaturas />
    },
    {
      path: "/silvares",
      element: <SeccaoSilvares />
    },
    {
      path: "/soalheira",
      element: <SeccaoSoalheira />
    },
    {
      path: "/tresPovos",
      element: <SeccaoPovos />
    },
    {
      path: "/fanfarra",
      element: <BFanfarra />
    },
    {
      path: "/escolinha",
      element: <BEscolinha />
    },
    {
      path: "/recrutamento",
      element: <BRecrutamento />
    },
    {
      path: "/contactos",
      element: <Contactos />
    },
    {
      path: "/socio",
      element: <AreaSocio />
    },
    {
      path: "/formacoes",
      element: <FormacaoGeral />
    },
    {
      path: "/departamento",
      element: <DepartamentoFormacao />
    },
    {
      path: "/principios",
      element: <Principios />
    },
    {
      path: "/selected_formacao/:id_formacao",
      element: <FormacaoSel />
    },
    {
      path: "/inscrever_formacao/id_formacao/:id_formacao",
      element: <InscricaoFormacao />
    },
    {
      path: "/administracao",
      element: <AdminArea />
    },

  ]
  const adminRoutes = [
    {
      path: "/painel_administracao",
      element: <AdminPanel />
    },
    {
      path: "/admin_noticia",
      element: <AdminNoticia />
    },
    {
      path: "/administracao/admin_hub",
      element: <AdminHub />
    },
    {
      path: "/administracao/nova_noticia",
      element: <NewNotice />
    },
    {
      path: "/administracao/lista_noticias",
      element: <NoticeList />
    },
    {
      path: "/administracao/lista_noticias",
      element: <NoticeList />
    },
    {
      path: "/administracao/lista_socios",
      element: <SocioList />
    },
    {
      path: "/administracao/lista_formacoes",
      element: <FormacaoList />
    },
    {
      path: "/administracao/nova_formacao",
      element: <NewFormacao />
    },
    {
      path: "/administracao/detalhe_formacao/:id_formacao",
      element: <DetailFormacao />
    },
    {
      path: "/administracao/altera_formacao/:id_formacao",
      element: <ChangeFormacao />
    },
    {
      path: "/administracao/altera_noticia/:id_noticia",
      element: <ChangeNotice />
    },
    {
      path: "/administracao/lista_recrutamento",
      element: <RecrutamentoList />
    },
    {
      path: "/administracao/detalhe_recruta/:cc",
      element: <RecrutamentoDetalhe />
    },
    {
      path: "/administracao/lista_cadetes",
      element: <CadeteList />
    },
    {
      path: "/administracao/lista_mensagens",
      element: <MessagesList />
    },
    {
      path: "/administracao/corporacao",
      element: <Corporacao />
    },
    {
      path: "/administracao/enviar_email/:id",
      element: <EnviarEmail />
    },
  ]
  return (
    <div>
      {!auth.accessToken ? <LinkButtons /> : ''}

      <ParallaxProvider>
        <Routes location={location} key={location.key} >
          {
            routes.map((value) => {
              return (
                <Route path={value.path} element={value.element} key={value.path} />
              )
            })
          }
          <Route element={<RequireAuth />}>
            {
              adminRoutes.map((value) => (
                <Route path={value.path} element={value.element} key={value.path} />
              ))
            }
          </Route>

        </Routes>
      </ParallaxProvider>

    </div>
  );
}

export default App;

