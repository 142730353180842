import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import ABSC02 from '../../galeria/corpo_ativo/fundao/viaturas/ABSC02.jpg'
import ABSC04 from '../../galeria/corpo_ativo/fundao/viaturas/ABSC04.jpg'
import ABSC05 from '../../galeria/corpo_ativo/fundao/viaturas/ABSC05.jpg'
import ABTD15 from '../../galeria/corpo_ativo/fundao/viaturas/ABTD15.jpg'
import ABTD23 from '../../galeria/corpo_ativo/fundao/viaturas/ABTD23.jpg'
import ABTM24 from '../../galeria/corpo_ativo/fundao/viaturas/ABTM24.jpg'
import ABTM31 from '../../galeria/corpo_ativo/fundao/viaturas/ABTM31.jpg'
import TDT06 from '../../galeria/corpo_ativo/fundao/viaturas/TDT06.jpg'
import VDTD07 from '../../galeria/corpo_ativo/fundao/viaturas/VDTD07.jpg'
import VE301 from '../../galeria/corpo_ativo/fundao/viaturas/VE3001.jpg'
import VFCI08 from '../../galeria/corpo_ativo/fundao/viaturas/VFCI08.jpg'
import VCOT01 from '../../galeria/corpo_ativo/fundao/viaturas/VCOT01.jpg'
import VCOT02 from '../../galeria/corpo_ativo/fundao/viaturas/VCOT02.jpg'
import VLC05 from '../../galeria/corpo_ativo/fundao/viaturas/VLC05.jpg'
import VLCI07 from '../../galeria/corpo_ativo/fundao/viaturas/VLCI07.jpg'
import VLCI10 from '../../galeria/corpo_ativo/fundao/viaturas/VLCI10.jpg'
import VRCI09 from '../../galeria/corpo_ativo/fundao/viaturas/VRCI09.jpg'
import VSAE03 from '../../galeria/corpo_ativo/fundao/viaturas/VSAE03.jpg'
import VSAT02 from '../../galeria/corpo_ativo/fundao/viaturas/VSAT02.jpg'
import VTTR03 from '../../galeria/corpo_ativo/fundao/viaturas/VTTR03.jpg'
import VTTU02 from '../../galeria/corpo_ativo/fundao/viaturas/VTTU02.jpg'
import VUCI03 from '../../galeria/corpo_ativo/fundao/viaturas/VUCI03.jpg'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Teste() {
    const [descriptionMaxLines] = useState(10);
    const [descriptionTextAlign] = useState("start");
    const [veiculos] = useState([
        { nome: ' ABSC02', foto: ABSC02, tipo: 'ambulancia_de_socorro' },
        { nome: ' ABSC04', foto: ABSC04, tipo: 'ambulancia_de_socorro' },
        { nome: ' ABSC05', foto: ABSC05, tipo: 'ambulancia_de_socorro' },
        { nome: ' ABTD15', foto: ABTD15, tipo: 'ambulancia_de_transportes_de_doentes' },
        { nome: ' ABTD23', foto: ABTD23, tipo: 'ambulancia_de_transportes_de_doentes' },
        { nome: ' ABTM24', foto: ABTM24, tipo: 'ambulancia_de_transporte_multiplo' },
        { nome: ' ABTM31', foto: ABTM31, tipo: 'ambulancia_de_transporte_multiplo' },
        { nome: ' TDT06', foto: TDT06, tipo: 'ambulancia_de_transporte_multiplo' },
        { nome: ' VCOT01', foto: VCOT01, tipo: 'veiculos_de_comando_e_operaçoes_taticas' },
        { nome: ' VCOT02', foto: VCOT02, tipo: 'veiculos_de_comando_e_operaçoes_taticas' },
        { nome: ' VDTD07', foto: VDTD07, tipo: 'veiculos_dedicado_ao_transporte_de_doentes' },
        { nome: ' VE301', foto: VE301, tipo: 'veiculos_escada' },
        { nome: ' VFCI08', foto: VFCI08, tipo: 'veiculos_florestal_de_combate_a_incendios' },
        { nome: ' VLCI05', foto: VLC05, tipo: 'veiculos_ligeiro_de_combate_a_incendios' },
        { nome: ' VLCI07', foto: VLCI07, tipo: 'veiculos_ligeiro_de_combate_a_incendios' },
        { nome: ' VLCI10', foto: VLCI10, tipo: 'veiculos_ligeiro_de_combate_a_incendios' },
        { nome: ' VRCI09', foto: VRCI09, tipo: 'veiculos_rural_de_combate_a_incendios' },
        { nome: ' VSAE03', foto: VSAE03, tipo: 'veiculos_de_salvamento_e_assitencia_especial' },
        { nome: ' VSAT02', foto: VSAT02, tipo: 'veiculos_de_socorro_e_assistencia_tatico' },
        { nome: ' VTTU02', foto: VTTU02, tipo: 'veiculos_tanque_grande_capacidade' },
        { nome: ' VTTR03', foto: VTTR03, tipo: 'veiculos_tanque_grande_capacidade' },
        { nome: ' VUCI03', foto: VUCI03, tipo: 'veiculos_urbano_de_combate_a_incendios' },
    ])






    const [index, setIndex] = useState(-1);
    const getIndex = (i) => {
        setIndex(i)

    }
    useEffect(() => {
        console.log(index)
    }, [index])
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Container fluid>
                <Row className='bannerViaturas P-5 mb-5'>
                    <h1 className='h1 text-center'>Viaturas</h1>
                </Row>
                <div className='container mb-5'>
                    <h1 className='h1 titleSection'>Ambulância de Socorro</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'ambulancia_de_socorro' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Ambulância de Transporte de Doentes</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'ambulancia_de_transportes_de_doentes' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Ambulância de Transporte de Múltiplo</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'ambulancia_de_transporte_multiplo' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Dedicado ao Transporte de Doentes</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_dedicado_ao_transporte_de_doentes' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo de Comando e Operações Táticas</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_de_comando_e_operaçoes_taticas' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo de Escada</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_escada' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Florestal de Combate a Incêndios</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_florestal_de_combate_a_incendios' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Ligeiro de Combate a Incêndios</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_ligeiro_de_combate_a_incendios' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Rural de Combate a Incêndios</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_rural_de_combate_a_incendios' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Urbano de Combate a Incêndios</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_urbano_de_combate_a_incendios' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo de Salvamento e Assitência Especial</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_de_salvamento_e_assitencia_especial' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo de Socorro e Assistência Tático</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_de_socorro_e_assistencia_tatico' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <br/><br/>
                    <h1 className='h1 titleSection'>Veículo Tanque de Grande Capacidade</h1>
                    <hr className='lineSection' />
                    <div className='row mb-05'>
                        {
                            veiculos.map((value, i) => {
                                return (
                                    value.tipo === 'veiculos_tanque_grande_capacidade' ?
                                        <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                            <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                            <div className='overlayViatura'>
                                                <div className='textCar'>{value.nome}</div>
                                            </div>
                                        </div>
                                        : ''
                                )

                            })

                        }
                        
                    </div>
                    <Lightbox
                        slides={veiculos.map((veiculo) => ({
                            src: veiculo.foto,
                            title: <h1 className='h1'>{veiculo.nome}</h1>,


                        }))}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Zoom, Captions]}
                        captions={{ descriptionTextAlign, descriptionMaxLines }}
                    />

                </div>



            </Container>
        </motion.div>
    )
}

export default Teste