import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import fanfarra_1 from '../../galeria/fanfarra/fanfarra_1.jpg'
import fanfarra_2 from '../../galeria/fanfarra/fanfarra_2.jpg'
import fanfarra_3 from '../../galeria/fanfarra/fanfarra_3.jpg'
import fanfarra_4 from '../../galeria/fanfarra/fanfarra_4.jpg'
import fanfarra_5 from '../../galeria/fanfarra/fanfarra_5.jpg'
import fanfarra_6 from '../../galeria/fanfarra/fanfarra_6.jpg'
import fanfarra_7 from '../../galeria/fanfarra/fanfarra_7.jpg'
import fanfarra_8 from '../../galeria/fanfarra/fanfarra_8.jpg'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}

function Fanfarra() {

    
    const [index, setIndex] = useState(-1);

    const photos = [
        { src: fanfarra_1, width: 1080, height: 800 },
        { src: fanfarra_2, width: 1080, height: 800 },
        { src: fanfarra_3, width: 950, height: 750 },
        { src: fanfarra_4, width: 1080, height: 800 },
        { src: fanfarra_5, width: 1080, height: 800 },
        { src: fanfarra_6, width: 1080, height: 800 },
        { src: fanfarra_7, width: 1180, height: 850 },
        { src: fanfarra_8, width: 650, height: 1080 },

    ]

    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerFanfarra p-5 mb-5'>
                <h1 className='text-center'>Fanfarra</h1>
            </div>
            <div className='container mt-5 mb-5'>
                <h2 className='h2 mt-5 titleSection'>História</h2>
                <hr className='lineSection' />
                <p className='historyFanfarra mt-5 mb-5'>
                    Fundada nos anos 60, foi composta, no seu início, por apenas 2 caixas, 1 tarola
                    e 6 clarins. Por ocasião da inauguração do atual quartel em maio de 1979,
                    integraram-se novos elementos com diferentes instrumentos musicais, tais
                    como; trompete, saxofone contrabaixo, bombos, timbalão, caixas, tarolas,
                    pratos e clarins. os quais vieram dar uma maior ênfase à fanfarra de então e
                    que a levou a ser considerada uma das melhores e mais diferenciadas do País.
                    Em 1989, formou-se com os infantes e cadetes da corporação, um grupo de
                    majorettes, que com a sua jovialidade, trouxeram nova vida e cor à então
                    reestruturada fanfarra, que se tem mantido até aos dias de hoje.
                    Atualmente conta com a presença de cerca de 35 elementos (masculinos e
                    femininos), com idades compreendidas entre os 7 e os 65 anos.<br /><br />
                    Para continuar a ter estatuto de fanfarra foram retirados todos os instrumentos
                    de sopro expeto os clarins e neste momento conta com os seguintes
                    instrumentos; bombos, timbalão, caixas, tarolas, pratos e clarins.<br /><br />
                    A fanfarra dos bombeiros do Fundão tem uma atividade por vezes intensa,
                    sendo solicitada para diversas efemérides, tais como; aniversários de
                    corporações de bombeiros, encontros e festivais de fanfarras e ainda,
                    participação em outros eventos nomeadamente, sociais e religiosos.
                    Ao longo da sua existência marcou também presença em vários congressos
                    nacionais da liga dos Bombeiros Portugueses nomeadamente, em Cascais,
                    Barcelos, Sintra e outros.<br /><br />
                    Espera esta fanfarra continuar a dar provas de muita alegria e dinamismo, não
                    só para o enriquecimento da Corporação dos Bombeiros Voluntários do Fundão,
                    como também dos próprios elementos, que assim ocupam os seus tempos
                    livres na valorização pessoal e de serviço e apoio à comunidade
                </p>
                <h2 className='h2 mt-5 titleSection'>Galeria</h2>
                <hr className='lineSection' />
                <div className='container-fluid'>
                    <PhotoAlbum layout='columns' photos={photos} padding={3} onClick={({ index }) => setIndex(index)} />
                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Zoom]}
                    />
                </div>
            </div>

        </motion.div>
    )
}

export default Fanfarra