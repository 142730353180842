import React, { useState } from 'react'
import { Container, Carousel } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import Photo_1 from '../../galeria/historia/historia_3.jpg'
import Photo_2 from '../../images/photo_2.jpg'
import Photo_3 from '../../galeria/corpo_ativo.jpg'
import Photo_4 from '../../galeria/formacoes.jpg'
import Photo_5 from '../../images/photo_5.jpg'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function CarouselSlide() {

    const [photos, setPhotos] = useState([
        { alt: 'História', image: Photo_1, linkTo: '/historia' },
        { alt: 'Estatutos', image: Photo_2, linkTo: '/estatutos' },
        { alt: 'Corpo Activo', image: Photo_3, linkTo: '/corpo_ativo' },
        { alt: 'Formação', image: Photo_4, linkTo: '/departamento' },
        { alt: 'Contactos', image: Photo_5, linkTo: '/contactos' },
    ])

    return (
        <Container fluid className='g-0'>
            <Carousel fade controls={false} id="carouselHome">
                {
                    photos.map((value, key) => {
                        return (
                            <Carousel.Item key={key}>
                                <Link to={value.linkTo}>
                                    <img className='d-block w-100 img-fluid' src={value.image} alt={value.alt} />
                                    <Carousel.Caption className='carouselTitle'>
                                        <h1 className='h1'>{value.alt}</h1>
                                    </Carousel.Caption>
                                </Link>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </Container>
    )
}

export default CarouselSlide