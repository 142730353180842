import React, { useState } from 'react'
//import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Map, { NavigationControl, Marker } from 'react-map-gl'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { motion } from 'framer-motion'
import { BsHouseFill, BsTelephoneFill, BsEnvelopeFill } from "react-icons/bs";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import { Modal } from 'antd';
import mapa_localizacao from '../../images/mapa_localizacao.PNG'
const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Contacto() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    /*const [positionMap, setPositionMap] = useState({
        lat: 40.142939,
        lng: -7.497121
    })*/

    const initialValues = {
        nomeContato: "",
        emailContato: "",
        assuntoContato: "",
        mensagemContato: ""
    }

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    };


    const onSubmit = (data, { resetForm }) => {
        axios.post('/form_contato/new_message', data)
            .then((response) => {
                console.log(response)
            })
            .catch((err) =>
                console.error(err)
            )
        resetForm({ data: '' })
        openModal('Mensagem enviada com sucesso')

    }

    const validationSchema = Yup.object().shape({
        nomeContato: Yup.string().required("Campo Vazio"),
        emailContato: Yup.string().required("Campo Vazio").email("Formato inválido"),
        assuntoContato: Yup.string().required("Campo Vazio"),
        mensagemContato: Yup.string().required("Campo Vazio")
    })
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
            className='container-fluid'
        >

            <div className='row bannerContacto P-5 mb-5'>
                <h1 className='h1 text-center'>Contactos</h1>
            </div>
            <div className='container mb-5'>
                <div className='row mt-5'>
                    <div className='col-md-5 col-sm-5 contactInfo'>
                        <h3 className='h3  titleSection'>Deixe-nos a sua Mensagem</h3>
                        <div className='container'>
                            <div className='container  '>
                                <p>
                                    <BsHouseFill className='mb-1' /> Rua Cidade da Covilhã<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;nº 18 6230-346 Fundão
                                </p>
                                <p>
                                    <BsTelephoneFill /> 275 772 700 <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;962 993 960
                                </p>
                                <p>
                                    <BsEnvelopeFill /> sec.dir@bvfundao.pt
                                </p>
                                <p className='text-uppercase mt-5'>
                                    <b>Em Caso de Emergência Ligue</b> <b className='text-danger text-underline'><u>112</u></b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7 col-sm-7'>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            <Form className='mt-3'>
                                <div className='form-floating mb-3'>
                                    <Field autoComplete='off' className='form-control' name='nomeContato' id='nomeContato' />
                                    <ErrorMessage name='nomeContato' component="span" />
                                    <label htmlFor='nomeContato'>Nome</label>
                                </div>
                                <div className='form-floating mb-3'>
                                    <Field autoComplete='off' className='form-control' name='emailContato' id='emailContato' />
                                    <ErrorMessage name='emailContato' component="span" />
                                    <label htmlFor='emailContato'>Email</label>
                                </div>
                                <div className='form-floating mb-3'>
                                    <Field autoComplete='off' className='form-control' name='assuntoContato' id='assuntoContato' />
                                    <ErrorMessage name='assuntoContato' component="span" />
                                    <label htmlFor='assuntoContato'>Assunto</label>
                                </div>
                                <div className="form-floating">
                                    <Field component="textarea" className="form-control" id='mensagemContato' name='mensagemContato' style={{ height: '210px' }} />
                                    <ErrorMessage name='mensagemContato' component="span" />
                                    <label htmlFor="mensagemContato">Mensagem</label>
                                </div>
                                <button type='submit' className="btn btn-outline-secondary mt-3 float-end">Enviar</button>
                                <Modal
                                    title={
                                        <div>
                                            <h4 className='text-center fw-bold text-danger'>Envio de Mensagem</h4>
                                            <hr className='text-danger'/>
                                        </div>
                                    }
                                    open={isModalVisible}
                                    onOk={() => {
                                        setIsModalVisible(false)
                                    }}
                                    cancelButtonProps={{ style: { display: 'none' } }}
                                >
                                    <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                                
                                </Modal>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <div className='mapArea'>
                <div className='theMap'>
                    <img src={mapa_localizacao} className='img-fluid' />
                </div>
            </div>
        </motion.div>
    )
}

export default Contacto