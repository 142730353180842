import React, { useState } from 'react'
import { motion } from 'framer-motion'

import portrait from '../../images/portait.jpg'
import corpo_ativo from '../../galeria/corpo_ativo/ex_retrato.png'
import chefeDivisa from '../../images/divisas/chefe.png'
import subChefeDivisa from '../../images/divisas/sub-chefe.png'
import bombeiro1Divisa from '../../images/divisas/bombeiro-1.png'
import bombeiro2Divisa from '../../images/divisas/bombeiro-2.png'
import bombeiro3Divisa from '../../images/divisas/bombeiro-3.png'

/**COMANDO */
import hugo_silva from '../../galeria/corpo_ativo/fundao/comando/hogo_silva_2_comandante.jpg'
import jorge_garcez from '../../galeria/corpo_ativo/fundao/comando/jorge_garcez_adjunto_comando_2.jpg'
import jose_sousa from '../../galeria/corpo_ativo/fundao/comando/jose_sousa_comandante_2.jpg'
import vitor_duarte from '../../galeria/corpo_ativo/fundao/comando/vitor_duarte_adjunto_comando_2.jpg'



/**CHEFE */
import carlos_santos from '../../galeria/corpo_ativo/fundao/chefia/carlos_santos_chefe_2.jpg'
import joao_goncalves from '../../galeria/corpo_ativo/fundao/chefia/joao_goncalves_chefe_2.jpg'
import paulo_pires from '../../galeria/corpo_ativo/fundao/chefia/paulo_pires_chefe.jpg'



/**SUB CHEFE */
import carlos_magueijo from '../../galeria/corpo_ativo/fundao/chefia/carlos_magueijo_sub_chefe_2.jpg'
import pedro_silva from '../../galeria/corpo_ativo/fundao/chefia/pedro_silva_sub_chefe_2.jpg'
import rui_marques from '../../galeria/corpo_ativo/fundao/chefia/rui_marques_sub_chefe.jpg'
import sandra_reis from '../../galeria/corpo_ativo/fundao/chefia/sandra_reis_sub_chefe_2.jpg'
import vera_antunes from '../../galeria/corpo_ativo/fundao/chefia/vera_antunes_sub_chefe_2.jpg'
import daniel_silva from '../../galeria/corpo_ativo/fundao/chefia/daniel_silva_sub_chefe.jpg'



/**BOMBEIRO_1*/
import antonio_lopes from '../../galeria/corpo_ativo/fundao/primeira/antonio_lopes_bombeiro_1_2.jpg'
import fernando_lindeza from '../../galeria/corpo_ativo/fundao/primeira/fernando_lindeza_bombeiro_1_2.jpg'
import luis_vaz from '../../galeria/corpo_ativo/fundao/primeira/luis_vaz_bombeiro_1_2.jpg'
import marcia_silverio from '../../galeria/corpo_ativo/fundao/primeira/marcia_silverio_bombeiro_1_2.jpg'
import paulo_costa from '../../galeria/corpo_ativo/fundao/primeira/paulo_costa_bombeiro_1.jpg'
import pedro_caldinho from '../../galeria/corpo_ativo/fundao/primeira/pedro_caldinho_bombeiro_1_2.jpg'
import roberto_costa from '../../galeria/corpo_ativo/fundao/primeira/roberto_costa_bombeiro_1.jpg'
import sandro_silvares from '../../galeria/corpo_ativo/fundao/primeira/sandro_silvares_bombeiro_1_2.jpg'






/**BOMBEIRO_2*/
import alexandre_nascimento from '../../galeria/corpo_ativo/fundao/segunda/alexandre_nascimento_bombeiro_2_2.jpg'
import andreia_primo from '../../galeria/corpo_ativo/fundao/segunda/andreia_primo_bombeiro_2_2.jpg'
import daniel_robalo from '../../galeria/corpo_ativo/fundao/segunda/daniel_robalo_bombeiro_2_2.jpg'
import david_fernandes from '../../galeria/corpo_ativo/fundao/segunda/david_fernandes_bombeiro_2_2.jpg'
import diogo_ramos from '../../galeria/corpo_ativo/fundao/segunda/diogo_ramos_bombeiro_2_2.jpg'
import eugenio_ambrosio from '../../galeria/corpo_ativo/fundao/segunda/eugenio_ambrosio_bombeiro_2_2.jpg'
import fabio_nogueira from '../../galeria/corpo_ativo/fundao/segunda/fabio_nogueira_bombeiro_2_2.jpg'
import fransico_martins from '../../galeria/corpo_ativo/fundao/segunda/fransico_martins_bombeiro_2.jpg'
import joao_gomes from '../../galeria/corpo_ativo/fundao/segunda/joao_gomes_bombeiro_2.jpg'
import joao_ponciano from '../../galeria/corpo_ativo/fundao/segunda/joao_ponciano_bombeiro_2_1.jpg'
import joao_sanches from '../../galeria/corpo_ativo/fundao/segunda/joao_sanches_bombeiro_2_3.jpg'
import jorge_goncalves from '../../galeria/corpo_ativo/fundao/segunda/jorge_goncalves_bombeiro_2_2.jpg'
import jorge_pereira from '../../galeria/corpo_ativo/fundao/segunda/jorge_pereira_bombeiro_2_2.jpg'
import luis_cerdeira from '../../galeria/corpo_ativo/fundao/segunda/luis_cerdeira_bombeiro_2_3.jpg'
import nelson_monteiro from '../../galeria/corpo_ativo/fundao/segunda/nelson_monteiro_bombeiro_2_2.jpg'
import nuno_bras from '../../galeria/corpo_ativo/fundao/segunda/nuno_bras_bombeiro_2_2.jpg'
import nuno_calvario from '../../galeria/corpo_ativo/fundao/segunda/nuno_calvario_bombeiro_2_2.jpg'
import ricardo_fernandes from '../../galeria/corpo_ativo/fundao/segunda/ricardo_fernandes_bombeiro_2_2.jpg'
import ricardo_marques from '../../galeria/corpo_ativo/fundao/segunda/ricardo_marques_bombeiro_2_2.jpg'
import rui_guterres from '../../galeria/corpo_ativo/fundao/segunda/rui_guterres_bombeiro_2_2.jpg'
import susana_gaspar from '../../galeria/corpo_ativo/fundao/segunda/susana_gaspar_bombeira_2_2.jpg'
import vitor_dias from '../../galeria/corpo_ativo/fundao/segunda/vitor_dias_bombeiro_2_2.jpg'


/**BOMBEIRO_3*/
import ana_ramos from '../../galeria/corpo_ativo/fundao/terceira/ana_ramos_bombeira_3.jpg'
import andreia_goncalves from '../../galeria/corpo_ativo/fundao/terceira/andreia_goncalves_bombeiro_3.jpg'
import andreia_mesquita from '../../galeria/corpo_ativo/fundao/terceira/andreia_mesquita_bombeira_3_2.jpg'
import daniel_antunes from '../../galeria/corpo_ativo/fundao/terceira/daniel_antunes_bombeiro_3_2.jpg'
import daniel_belcorca from '../../galeria/corpo_ativo/fundao/terceira/daniel_belcorca_bombeiro_3.jpg'
import diogo_correia from '../../galeria/corpo_ativo/fundao/terceira/diogo_correia_bombeiro_3_2.jpg'
import eduardo_ribeiro from '../../galeria/corpo_ativo/fundao/terceira/eduardo_ribeiro_bombeiro_3_3.jpg'
import filipa_andreia from '../../galeria/corpo_ativo/fundao/terceira/Filipa_Andreia_bombeira_3_2.jpg'
import joao_madeira from '../../galeria/corpo_ativo/fundao/terceira/joao_madeira_bombeiro_3_2.jpg'
import luis_jesus from '../../galeria/corpo_ativo/fundao/terceira/luis_jesus_bombeiro_3_2.jpg'
import manuel_morais from '../../galeria/corpo_ativo/fundao/terceira/manuel_morais_bombeiro_3.jpg'
import marco_henriques from '../../galeria/corpo_ativo/fundao/terceira/marco_henriques_bombeiro_3_2.jpg'
import marco_nunes from '../../galeria/corpo_ativo/fundao/terceira/marco_nunes_bombeiro_3_2.jpg'
import maria_de_deus from '../../galeria/corpo_ativo/fundao/terceira/maria_de_deus_bombeiro_3_2.jpg'
import marisa_henriques from '../../galeria/corpo_ativo/fundao/terceira/marisa_henriques_bombeiro_3_2.jpg'
import nuria_santos from '../../galeria/corpo_ativo/fundao/terceira/nuria_santos_bombeiro_3_2.jpg'
import pedro_goncalves from '../../galeria/corpo_ativo/fundao/terceira/pedro_goncalves_bombeiro_3_2.jpg'
import ramiro_quaresma from '../../galeria/corpo_ativo/fundao/terceira/ramiro_quaresma_bombeiro_3_2.jpg'
import raquel_bras from '../../galeria/corpo_ativo/fundao/terceira/raquel_bras_bombeiro_3_3.jpg'
import raul_proenca from '../../galeria/corpo_ativo/fundao/terceira/raul_proenca_bombeiro_3_2.jpg'
import ruben_santos from '../../galeria/corpo_ativo/fundao/terceira/ruben_santos_bombeiro_3.jpg'
import samuel_calvario from '../../galeria/corpo_ativo/fundao/terceira/samuel_calvario_bombeiro_3_2.jpg'
import andre_fernandes from '../../galeria/corpo_ativo/fundao/terceira/andre_fernandes_bombeiro_3_2.jpg'
import tomas_veloso from '../../galeria/corpo_ativo/fundao/terceira/tomas_veloso_bombeiro_3_2.jpg'
import ricardo_calvario from '../../galeria/corpo_ativo/fundao/terceira/ricardo_calvario_bombeiro_3_2.jpg'



const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}



function CorpoActivo() {
    const [classes, setClasses] = useState([
        { classe: 'Comando', id: 1 },
        { classe: 'Chefe', id: 2 },
        { classe: 'Subchefe', id: 3 },
        { classe: 'Bombeiro de 1ª', id: 4 },
        { classe: 'Bombeiro de 2ª', id: 5 },
        { classe: 'Bombeiro de 3ª', id: 6 },

    ])
    const [comando, setComando] = useState([
        { nome: 'José Sousa', categoria: 'Comandante', foto: jose_sousa },
        { nome: 'Hugo Silva', categoria: '2º Comandante', foto: hugo_silva },
        { nome: 'Jorge Garcez', categoria: 'Adjunto de Comando', foto: jorge_garcez },
        { nome: 'Vítor Duarte', categoria: 'Adjunto de Comando', foto: vitor_duarte },
    ])
    const [chefe, setChefe] = useState([
        { nome: 'Carlos Santos', divisa: chefeDivisa, foto: carlos_santos },
        { nome: 'João Gonçalves', divisa: chefeDivisa, foto: joao_goncalves },
        { nome: 'Paulo Pires', divisa: chefeDivisa, foto: paulo_pires }
    ])


    const [subchefe, setSubchefe] = useState([
        { nome: 'Carlos Magueijo', divisa: subChefeDivisa, foto: carlos_magueijo },
        { nome: 'Daniel Silva', divisa: subChefeDivisa, foto: daniel_silva },
        { nome: 'Pedro Silva', divisa: subChefeDivisa, foto: pedro_silva },
        { nome: 'Rui Marques', divisa: subChefeDivisa, foto: rui_marques },
        { nome: 'Sandra Reis', divisa: subChefeDivisa, foto: sandra_reis },
        { nome: 'Vera Antunes', divisa: subChefeDivisa, foto: vera_antunes },
    ])
 
    const [bombeirosPrimeira, setBombeirosPrimeira] = useState([
        { nome: 'António Lopes', divisa: bombeiro1Divisa, foto: antonio_lopes },
        { nome: 'Fernando Lindeza', divisa: bombeiro1Divisa, foto: fernando_lindeza },
        { nome: 'Luís Vaz', divisa: bombeiro1Divisa, foto: luis_vaz },
        { nome: 'Marcia Silvério', divisa: bombeiro1Divisa, foto: marcia_silverio },
        { nome: 'Paulo Costa', divisa: bombeiro1Divisa, foto: paulo_costa },
        { nome: 'Pedro Caldinho', divisa: bombeiro1Divisa, foto: pedro_caldinho },
        { nome: 'Roberto Costa', divisa: bombeiro1Divisa, foto: roberto_costa },
        { nome: 'Sandro Silvares', divisa: bombeiro1Divisa, foto: sandro_silvares }
    ])


    const [bombeirosSegunda, setBombeirosSegunda] = useState([
        { nome: 'Alexandre Nascimento', divisa: bombeiro2Divisa, foto: alexandre_nascimento },
        { nome: 'Andreia Primo', divisa: bombeiro2Divisa, foto: andreia_primo },
        { nome: 'Daniel Robalo', divisa: bombeiro2Divisa, foto: daniel_robalo },
        { nome: 'David Fernandes', divisa: bombeiro2Divisa, foto: david_fernandes },
        { nome: 'Eugénio Ambrósio', divisa: bombeiro2Divisa, foto: eugenio_ambrosio },
        { nome: 'Diogo Ramos', divisa: bombeiro2Divisa, foto: diogo_ramos },
        { nome: 'Fábio Nogueira', divisa: bombeiro2Divisa, foto: fabio_nogueira },
        { nome: 'Francisco Martins', divisa: bombeiro2Divisa, foto: fransico_martins },
        { nome: 'João Gomes', divisa: bombeiro2Divisa, foto: joao_gomes },
        { nome: 'João Ponciano', divisa: bombeiro2Divisa, foto: joao_ponciano },
        { nome: 'João Sanches', divisa: bombeiro2Divisa, foto: joao_sanches },
        { nome: 'Jorge Gonçalves', divisa: bombeiro2Divisa, foto: jorge_goncalves },
        { nome: 'Jorge Pereira', divisa: bombeiro2Divisa, foto: jorge_pereira },
        { nome: 'Luís Cerdeira', divisa: bombeiro2Divisa, foto: luis_cerdeira },
        { nome: 'Nelson Monteiro', divisa: bombeiro2Divisa, foto: nelson_monteiro },
        { nome: 'Nuno Brás', divisa: bombeiro2Divisa, foto: nuno_bras },
        { nome: 'Nuno Calvário', divisa: bombeiro2Divisa, foto: nuno_calvario },
        { nome: 'Ricardo Fernandes', divisa: bombeiro2Divisa, foto: ricardo_fernandes },
        { nome: 'Ricardo Marques', divisa: bombeiro2Divisa, foto: ricardo_marques },
        { nome: 'Rui Guterres', divisa: bombeiro2Divisa, foto: rui_guterres },
        { nome: 'Susana Gaspar', divisa: bombeiro2Divisa, foto: susana_gaspar },
        { nome: 'Vitor Dias', divisa: bombeiro2Divisa, foto: vitor_dias }
    ])


    const [bombeirosTerceira, setBombeirosTerceira] = useState([
        { nome: 'Ana Ramos', divisa: bombeiro3Divisa, foto: ana_ramos },
        { nome: 'André Fernandes', divisa: bombeiro3Divisa, foto: andre_fernandes },
        { nome: 'Andreia Mesquita', divisa: bombeiro3Divisa, foto: andreia_mesquita },
        { nome: 'Andreia Gonçalves', divisa: bombeiro3Divisa, foto: andreia_goncalves },
        { nome: 'Daniel Antunes', divisa: bombeiro3Divisa, foto: daniel_antunes },
        { nome: 'Daniel Bercorca', divisa: bombeiro3Divisa, foto: daniel_belcorca },
        { nome: 'Diogo Correia', divisa: bombeiro3Divisa, foto: diogo_correia },
        { nome: 'Eduardo Ribeiro', divisa: bombeiro3Divisa, foto: eduardo_ribeiro },
        { nome: 'Filipa Andreia', divisa: bombeiro3Divisa, foto: filipa_andreia },
        { nome: 'João Madeira', divisa: bombeiro3Divisa, foto: joao_madeira },
        { nome: 'Luís Jesus', divisa: bombeiro3Divisa, foto: luis_jesus },
        { nome: 'Manuel Morais', divisa: bombeiro3Divisa, foto: manuel_morais },
        { nome: 'Marco Henriques', divisa: bombeiro3Divisa, foto: marco_henriques },
        { nome: 'Marco Nunes', divisa: bombeiro3Divisa, foto: marco_nunes },
        { nome: 'Maria de Deus', divisa: bombeiro3Divisa, foto: maria_de_deus },
        { nome: 'Marisa Henriques', divisa: bombeiro3Divisa, foto: marisa_henriques },
        { nome: 'Núria Santos', divisa: bombeiro3Divisa, foto: nuria_santos },
        { nome: 'Pedro Gonçalves', divisa: bombeiro3Divisa, foto: pedro_goncalves },
        { nome: 'Ramiro Quaresma', divisa: bombeiro3Divisa, foto: ramiro_quaresma },
        { nome: 'Raquel Brás', divisa: bombeiro3Divisa, foto: raquel_bras },
        { nome: 'Raúl Proença', divisa: bombeiro3Divisa, foto: raul_proenca },
        { nome: 'Ruben Santos', divisa: bombeiro3Divisa, foto: ruben_santos },
        { nome: 'Samuel Calvário', divisa: bombeiro3Divisa, foto: samuel_calvario },
        { nome: 'Tomás Veloso', divisa: bombeiro3Divisa, foto: tomas_veloso },
        { nome: 'Ricardo Calvário', divisa: bombeiro3Divisa, foto: ricardo_calvario }

    ])

    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerCorpo p-5 mb-5'>
                <h1 className='text-center'>Corpo Activo</h1>
            </div>
            <div className='container mb-5'>
                <div className='row'>
                    <h2 className='h2 mt-5 titleSection'>Comando</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        comando.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.categoria}</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <h2 className='h2 mt-5 titleSection'>Chefe</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        chefe.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Subchefe</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        subchefe.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 1ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeirosPrimeira.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 2ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeirosSegunda.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 3ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeirosTerceira.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default CorpoActivo