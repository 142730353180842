import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'
import axios from 'axios';
import CryptoJS from 'crypto-js'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Noticias() {
    const [noticias, setNoticias] = useState([])
    const [encIDs, setEncIDs] = useState([])


    const encrptID = (id) => {

        let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()

        while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
            encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
        }
        
        return encryptedId

    }
    
    useEffect(() => {
        axios.get('/noticia/all_noticias')
            .then((response) => {
                setNoticias(response.data.noticias)
                const theID = response.data.noticias.map(noti => encrptID(noti.id_noticia))
                setEncIDs(theID)
            })
            .catch((err) => console.error(err))
    }, [])
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Container fluid>
                <Row className='bannerNoticia P-5 mb-5'>
                    <h1 className='h1 text-center'>Notícias</h1>
                </Row>
            </Container>
            <div className='container'>
                <motion.h1 className='h1 ml-5 titleSection'
                    initial={{
                        opacity: 0,
                        x: '-100vw'
                    }}
                    transition={{ delay: 2.5, duration: 2 }}
                    animate={{
                        x: 0,
                        opacity: 1
                    }}
                >Últimas Notícias</motion.h1>
                <hr className='lineSection mb-5' />
                <div className='container'>
                    {

                        noticias.length > 0 ?
                            noticias.map((value, index) => {
                                const id_noticia = encIDs[index]
                                return (
                                    <div className='row mb-2' key={index}>
                                        <div className='col-md-4 col-sm-4'>
                                            <img src={value.imagem_noticia} className="img-fluid float-end img-thumbnail img_noticia" />
                                        </div>
                                        <div className='col-md-8 col-sm-8'>
                                            <h5 className='h5 titleSection'>{value.titulo_noticia}</h5>
                                            <p><b>Publicado em:</b> {value.data_noticia}</p>
                                            <Link className='text-decoration-none text-danger fs-4' to={`/noticia/${encodeURIComponent(id_noticia)}`}>Ler</Link>
                                        </div>
                                        <hr className='mt-3' />
                                    </div>
                                )
                            })
                            :
                            <div className='container'>
                                <br /><br />
                                <h1 className='h1 text-center mt-5 mb-5'>Sem notícias neste momento</h1>
                                <br /><br />
                            </div>
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default Noticias