import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function FormacaoAdmin() {
    const id_formacao = useParams()['id_formacao']
    const [formacao, setFormacao] = useState([])
    useEffect(() => {
        axios.get(`/formacao/selected_formacao/${id_formacao}`).then((response) => {
            setFormacao(response.data.form)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {
                formacao.map((value, key) => {
                    return (
                        <div className='container mt-5' key={key}>
                            <Link className='float-end text-decoration-none fs-3' to={'/administracao/lista_formacoes'}>Voltar</Link>
                            <motion.h1 className='h1 titleSection'
                                initial={{
                                    opacity: 0,
                                    x: '-100vw'
                                }}
                                transition={{ delay: 2.5, duration: 2 }}
                                animate={{
                                    x: 0,
                                    opacity: 1
                                }}
                            >{value.designacao}</motion.h1>
                            <hr className='lineSection mb-5' />
                            <div className='container containerImage p-1'>
                                <img src={value.imagem_formacao} alt='formacao' className='img-fluid imgNoticia' />
                            </div>
                            <div className='row mt-5'>
                                <hr className='lineSection' />
                                <div className='col-md col-xs'>
                                    <h5 className='h5 text-center formacaoTitle'>Data Realização</h5>
                                    <p className='text-center fw-bold'>{value.data_realizacao}</p>
                                </div>
                                <div className='col-md col-xs '>
                                    <h5 className='h5 text-center formacaoTitle'>Local Realização</h5>
                                    <p className='text-center fw-bold'>{value.local_realizacao}</p>
                                </div>
                                <div className='col-md col-xs'>
                                    <h5 className='h5 text-center formacaoTitle'>Duração</h5>
                                    <p className='text-center fw-bold'>{value.duracao}</p>
                                </div>
                                <div className='col-md col-xs'>
                                    <h5 className='h5 text-center formacaoTitle'>Data Limite Inscrição</h5>
                                    <p className='text-center fw-bold'>{value.data_limite_inscricao}</p>
                                </div>
                                <hr className='lineSection' />
                            </div>
                            <h5 className='h5 formacaoTitle mt-3 mb-3'>Enquadramento</h5>
                            <div dangerouslySetInnerHTML={{ __html: value.enquadramento }} />
                            <h5 className='h5 formacaoTitle mt-5 mb-3'>Objetivos</h5>
                            <div dangerouslySetInnerHTML={{ __html: value.objetivos }} />
                            <h5 className='h5 formacaoTitle mt-5 mb-3'>Programa</h5>
                            <div className='mb-5' dangerouslySetInnerHTML={{ __html: value.programa }} />
                        </div>
                    )
                })
            }

        </motion.div>
    )
}

export default FormacaoAdmin