import React from 'react'
import { motion } from 'framer-motion'

import logoDepartamento from '../../images/departamento_formacao.jpg'


function Departamento() {

    const containerTransition = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { delay: 1.5, duration: 1.5 },
        },
        exit: {
            x: '-100vw',
            transition: { ease: 'easeInOut' }
        }
    }


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerFormacao p-5 mb-5'>
                <h1 className='text-center'>Departamento de Formação</h1>
            </div>
            <div className='container mb-5'>
                <img src={logoDepartamento} className='img-fluid imgEstatutos mb-5' alt='' />
                <div className='container'>
                    <p className='departementText'>
                        A Associação Humanitária de Bombeiros Voluntários do Fundão é a génese da criação do Departamento de Formação, tendo em conta a necessidade de formação dos seus
                        colaboradores e associados, das empresas públicas e privadas, assim como comunidade em geral. O Departamento de Formação tem por objetivo promover a
                        oferta formativa e atualização ao longo da vida, para bombeiros, empresas, instituições e público em geral, bem como incentivar e reforçar a
                        cooperação entre estas entidades e os Bombeiros Voluntários do Fundão. Foi a pensar no estímulo formativo das pessoas que se decidiu criar esta estrutura.
                        Desde a sua abertura, tem promovido cursos de formação para a comunidade, em domínios de primeiros socorros, extinção de incêndios,
                        segurança contra incêndios, entre outros.
                    </p>
                    <br />
                    <p className='departementText'>
                        <p className='mb-2'>Com um âmbito de atuação nacional, a atividade formativa dinamizada pelo departamento de formação assume duas lógicas distintas:</p>
                        <ul>
                            <li>
                                O departamento de formação para garantir a qualidade da sua oferta formativa é acreditado pela DGERT nas seguintes áreas de formação:
                                <ul className='m-2'>
                                    <li>623 – Silvicultura e Caça</li>
                                    <li>729 – Saúde</li>
                                    <li>861 – Proteção de Pessoas e Bens</li>
                                </ul>
                            </li>
                            <li>
                                O departamento de formação é também reconhecido pelo INEM (Instituto Nacional de Emergência Médica) como entidade acreditada para a
                                dinamização do Curso de SBV (Suporte Básico de Vida),
                                do Curso de SBV-DAE (Suporte Básico de Vida e Desfibrilhação Automática Externa).
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

export default Departamento