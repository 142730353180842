import React, { useState, useEffect, useContext } from 'react'
import '../../App.css'
import Modal from '../../components/Modal'
import Carousel from '../principal/Carousel'
import Apresentacao from '../principal/Apresentacao'
import UltimasNoticias from '../principal/UltimasNoticias'
import SocioArea from '../principal/SocioArea'
import TestemunhosIntro from '../principal/TestemunhosIntro'
import Navbar from '../principal/Navbar'
import Footer from '../principal/Footer'
import { AnimatePresence } from 'framer-motion'
import axios from 'axios'

function HomePage() {
  const [showModal, setShowModal] = useState(true)
  const adminPage = 0

  useEffect(() => {
    axios.get('/noticia/check_noticia')
      .then((response) => {
        if (response.data == false) {
          setShowModal(false)
        } else {
          //const modalClosed = localStorage.getItem('modalClosed')
          /*if (!modalClosed) {
            setShowModal(false)
          }*/
        }
      })
  }, [])


  return (
    <div>
      <AnimatePresence onExitComplete={() => setShowModal(false)}>
        <Modal showModal={showModal} setShowModal={setShowModal} />
        <Navbar isAdmin={adminPage} />
        <Carousel />
        <Apresentacao />
        <SocioArea />
        <UltimasNoticias />
        <TestemunhosIntro />
        <Footer />
      </AnimatePresence>
    </div>
  )
}

export default HomePage