import React from 'react'
import { motion } from 'framer-motion'
import Navbar from '../principal/Navbar'
import OrgaosSociais from '../associacao/OrgaosSociais'
import Footer from '../principal/Footer'

const containerTransition = {
  hidden: {
    x: '-100vw',
    opacity: 0
  },
  visible: {
    x: '0',
    opacity: 1,
    transition: { delay: 1, duration: 1.5 },
  },
  exit: {
    x: '+100vw',
    transition: { ease: 'easeInOut' }
  }
}


function AOrgaos() {
  const adminPage = 0
  return (
    <motion.div
      variants={containerTransition}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Navbar isAdmin={adminPage}/>
      <OrgaosSociais />
      <Footer />
    </motion.div>
  )
}

export default AOrgaos