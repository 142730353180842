import React, { useState } from 'react'
import { Chrono } from 'react-chrono'
import { motion, useAnimation } from 'framer-motion'
import FsLightbox from 'fslightbox-react';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

function Seccoes({ nameSeccao, chefeSec, subChefeSec, secBomb_1, secBomb_2, secBomb_3, SecViaturas }) {

    const [descriptionMaxLines] = useState(10);
    const [descriptionTextAlign] = useState("start");
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    })
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }
    const [index, setIndex] = useState(-1);
    const getIndex = (i) => {
        setIndex(i)

    }
    const contarViaturas = (veiculos, tipo) => {
        return veiculos.filter(veiculo => veiculo.tipo === tipo).length;
    };


    const changeBanner = (name) => {

        switch (name) {
            case 'Silvares':
                return 'bannerSilvares'
                break;
            case 'Soalheira':
                return 'bannerSoalheira'
                break;
            default:
                return 'bannerFormacao'
                break;
        }
    }
    const num_socorro = contarViaturas(SecViaturas, 'ambulancia_de_socorro')
    const num_transporte = contarViaturas(SecViaturas, 'ambulancia_de_transportes_de_doentes')
    const num_multiplo = contarViaturas(SecViaturas, 'ambulancia_de_transporte_multiplo')
    const num_dedicado = contarViaturas(SecViaturas, 'veiculos_dedicado_ao_transporte_de_doentes')
    const num_taticos = contarViaturas(SecViaturas, 'veiculos_de_comando_e_operaçoes_taticas')
    const num_pessoal = contarViaturas(SecViaturas, 'veiculo_de_transporte_tático_de_pessoal')
    const num_escada = contarViaturas(SecViaturas, 'veiculos_escada')
    const num_florestal = contarViaturas(SecViaturas, 'veiculos_florestal_de_combate_a_incendios')
    const num_ligeiro = contarViaturas(SecViaturas, 'veiculos_ligeiro_de_combate_a_incendios')
    const num_rural = contarViaturas(SecViaturas, 'veiculos_rural_de_combate_a_incendios')
    const num_urbano = contarViaturas(SecViaturas, 'veiculos_urbano_de_combate_a_incendios')
    const num_salvamento = contarViaturas(SecViaturas, 'veiculos_de_salvamento_e_assitencia_especial')
    const num_assistencia = contarViaturas(SecViaturas, 'veiculos_de_socorro_e_assistencia_tatico')
    const num_capacidade = contarViaturas(SecViaturas, 'veiculos_tanque_grande_capacidade')
    return (
        <div className='container-fluid'>
            <div className={`row ${changeBanner(nameSeccao)} p-5 mb-5`}>
                <h1 className='text-center'>{nameSeccao}</h1>
            </div>
            <div className='container'>
                <div className='row'>
                    {
                        nameSeccao !== 'Silvares' ? <h2 className='h2 mt-5 titleSection'>Chefe</h2> : ''
                    }
                    {
                        nameSeccao !== 'Silvares' ? <hr className='mb-5 lineSection' /> : ''
                    }

                    {
                        nameSeccao !== 'Silvares' ?
                            chefeSec.map((value, index) => {
                                return (
                                    <div className='col-md-4 col-sm-6 my-3'>
                                        <div className='boxGallery' key={index}>
                                            <img src={value.foto} alt='' className='img-fluid rounded' />
                                            <div className='contentGallery'>
                                                <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                                <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                    }

                    {
                        nameSeccao !== 'Soalheira' ? <h2 className='h2 mt-5 titleSection'>Subchefe</h2> : ''
                    }

                    {
                        nameSeccao !== 'Soalheira' ? <hr className='mb-5 lineSection' /> : ''
                    }
                    {
                        nameSeccao !== 'Soalheira' ?
                            subChefeSec.map((value, index) => {
                                return (
                                    <div className='col-md-4 col-sm-6 my-3' key={index}>
                                        <div className='boxGallery'>
                                            <img src={value.foto} alt='' className='img-fluid rounded' />
                                            <div className='contentGallery'>
                                                <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                                <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : ''
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 1ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        secBomb_1.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 2ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        secBomb_2.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 3ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        secBomb_3.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-3'>{value.nome}</h4>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <br /><br />
            <div className='container mb-5'>
                {
                    num_socorro > 0 ?
                        <>
                            <h1 className='h1 titleSection'>Ambulância de Socorro</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'ambulancia_de_socorro' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }
                            </div>
                        </>
                        : ''
                }
                {
                    num_transporte > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Ambulância de Transporte de Doentes</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'ambulancia_de_transportes_de_doentes' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''

                                        )

                                    })

                                }
                            </div>
                        </> : ''
                }
                {
                    num_multiplo > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Ambulância de Transporte de Múltiplo</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'ambulancia_de_transporte_multiplo' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }
                            </div>
                        </> : ''
                }
                {
                    num_dedicado > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Dedicado ao Transporte de Doentes</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_dedicado_ao_transporte_de_doentes' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }
                            </div>
                        </> : ''
                }
                {
                    num_taticos > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo de Comando e Operações Táticas</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_de_comando_e_operaçoes_taticas' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }
                            </div>
                        </> : ''
                }
                {
                    num_pessoal > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Transporte Tático de Pessoal</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculo_de_transporte_tático_de_pessoal' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }
                            </div>
                        </> : ''
                }
                {
                    num_escada > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo de Escada</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_escada' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_florestal > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Florestal de Combate a Incêndios</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_florestal_de_combate_a_incendios' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_ligeiro > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Ligeiro de Combate a Incêndios</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_ligeiro_de_combate_a_incendios' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_rural > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Rural de Combate a Incêndios</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_rural_de_combate_a_incendios' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_urbano > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Urbano de Combate a Incêndios</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_urbano_de_combate_a_incendios' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_salvamento > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo de Salvamento e Assitência Especial</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_de_salvamento_e_assitencia_especial' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_assistencia > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo de Socorro e Assistência Tático</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_de_socorro_e_assistencia_tatico' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }
                {
                    num_capacidade > 0 ?
                        <>
                            <br /><br />
                            <h1 className='h1 titleSection'>Veículo Tanque de Grande Capacidade</h1>
                            <hr className='lineSection' />
                            <div className='row mb-05'>
                                {
                                    SecViaturas.map((value, i) => {
                                        return (
                                            value.tipo === 'veiculos_tanque_grande_capacidade' ?
                                                <div className='col-lg-3 col-md-4 col-6 containerViatura p-2' key={i} onClick={() => getIndex(i)}>
                                                    <img src={value.foto} alt="" className='img-fluid imageViatura' />
                                                    <div className='overlayViatura'>
                                                        <div className='textCar'>{value.nome}</div>
                                                    </div>
                                                </div>
                                                : ''
                                        )

                                    })

                                }

                            </div>
                        </> : ''
                }

                <Lightbox
                    slides={SecViaturas.map((veiculo) => ({
                        src: veiculo.foto,
                        title: <h1 className='h1'>{veiculo.nome}</h1>,


                    }))}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Fullscreen, Slideshow, Zoom, Captions]}
                    captions={{ descriptionTextAlign, descriptionMaxLines }}
                />

            </div>


        </div>
    )
}

export default Seccoes