import React, { useState } from 'react'
import { motion } from 'framer-motion'
import logo from '../../images/logo_image.png'


function Estatutos() {
    /**
     * const [open, setOpen] = useState(false)
    const [panelID, setPanelID] = useState(0)
    const openPane = (id) => {

        if (id == 1) {
            setOpen(true)
            setPanelID(id)
        }

        if (id == 2) {
            setOpen(true)
            setPanelID(id)
        }
    }
     */
    const containerTransition = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { delay: 1.5, duration: 1.5 },
        },
        exit: {
            x: '-100vw',
            transition: { ease: 'easeInOut' }
        }
    }


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <linkButtons/>
            <div className='row bannerEstatutos p-5 mb-5'>
                <h1 className='text-center'>Estatutos</h1>
            </div>
            <div className='container mb-5'>
                <img src={logo} className='img-fluid imgEstatutos mb-5' />
                <h2 className='h2 text-center mb-5'>
                    Estatutos da Associação Humanitária de<br />Bombeiros Voluntários de Fundão
                </h2>
                <div className="accordion accordion-flush mt-5" id="estatutosDiv">
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header h2" id="flush-capOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_1" aria-expanded="false" aria-controls="capitulo_1">
                                Capítulo I - Denominação, Natureza, Sede e Fins
                            </button>
                        </h2>
                        <div id="capitulo_1" className="accordion-collapse collapse" aria-labelledby="flush-capOne">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>ARTIGO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        A Associação Humanitária de Bombeiros Voluntários de Fundão, fundada em vinte e três de Maio de mil novecentos e vinte e sete,
                                        reforma, com as presentes alterações, os estatutos já aprovados em Assembleias Gerais de seis de Janeiro de mil novecentos e
                                        cinquenta e sete e de dezanove de Janeiro de mil novecentos e noventa e três.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        A Associação tem a denominação de <b>ASSOCIAÇÃO HUMANITÁRIA DE BOMBEIROS VOLUNTÁRIOS DE FUNDÃO</b> e a sua sede na cidade de Fundão.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>PRIMEIRO –</b>  A Associação tem como escopo principal a protecção de pessoas e bens, designadamente o socorro a feridos,
                                        doentes ou náufragos e a extinção de incêndios, detendo e mantendo em actividade, para o efeito, um corpo de
                                        bombeiros voluntários, com observância do definido no regime jurídico dos corpos de bombeiros e demais legislação aplicável.
                                    </p>
                                    <p>
                                        <b>SEGUNDO –</b> Com estrita observância do seu fim não lucrativo e sem prejuízo do seu escopo principal,
                                        a Associação pode desenvolver outras actividades, individualmente ou em associação, com outras pessoas
                                        singulares ou colectivas, desde que permitidas por deliberação da Assembleia Geral, nomeadamente:
                                        <ol type='a' className='mt-2'>
                                            <li>
                                                Prestação de cuidados de saúde, actividades desportivas, culturais e recreativas,
                                                conducentes a uma melhor preparação física e intelectual dos seus associados;
                                            </li>
                                            <li>
                                                Actividades de carácter social de apoio e protecção à infância, à juventude, à deficiência e aos idosos ou em
                                                qualquer situação de carência que justifique uma actuação pró humanitária.
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        O Corpo de Bombeiros Voluntários reger-se-à, para além do estabelecido na legislação aplicável e em vigor, também por regulamento próprio, denominado Regulamento do Corpo de Bombeiros.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        A Associação tem um número ilimitado de sócios, capital indeterminado e duração indefinida.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_2" aria-expanded="false" aria-controls="capitulo_2">
                                Capítulo II - Dos Sócios
                            </button>
                        </h2>
                        <div id="capitulo_2" class="accordion-collapse collapse" aria-labelledby="flush-capTwo">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>SECÇÃO I</h4>
                                <h5 className='h5 text-center mb-5'>Sua Classificação e Admissão</h5>
                                <h4 className='h4 text-center'>ARTIGO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Os sócios da Associação dividem-se em cinco categorias:
                                        <ol type='a' className='mt-2'>
                                            <li>Efectivos;</li>
                                            <li>Apoiantes;</li>
                                            <li>Humanitários;</li>
                                            <li>Beneméritos;</li>
                                            <li>Honorários.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> São sócios efectivos as pessoas, singulares ou colectivas, que contribuam para a prossecução dos fins da Associação, mediante o pagamento de uma quota semestral ou anual.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> São sócios apoiantes as pessoas, singulares ou colectivas, que contribuam com uma quota anual mínima, do valor que vier a ser determinado pela Assembleia Geral.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> São sócios humanitários todos os que façam parte do Corpo de Bombeiros da Associação, cuja admissão deverá ser proposta à Direcção pelo respectivo Comando.
                                    </p>
                                    <p>
                                        <b>QUINTO -</b> São sócios beneméritos as pessoas, singulares ou colectivas, que, por serviços ou dádivas importantes, sejam, como tal, consideradas por deliberação da Assembleia Geral e sob proposta da Direcção.
                                    </p>
                                    <p>
                                        <b>SEXTO -</b> São sócios honorários as pessoas, singulares ou colectivas, que, por serviços relevantes prestados à Associação, mereçam essa distinção por deliberação da Assembleia Geral e sob proposta da Direcção.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Os sócios efectivos serão admitidos pela Direcção, mediante proposta preenchida e assinada pelo próprio.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Tratando-se de menor, a proposta de admissão deve ser assinada pelo seu representante legal, o qual tomará a responsabilidade pelo pagamento das quotas até o sócio atingir a maioridade.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Da rejeição da admissão, poderá o proponente interpor recurso para a Assembleia Geral, no prazo de vinte dias a contar da notificação.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO II</h4>
                                <h5 className='h5 text-center mb-5'>Dos Direitos e Deveres</h5>
                                <h4 className='h4 text-center'>ARTIGO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Os sócios gozam dos seguintes direitos:
                                        <ol type='a' className='mt-2'>
                                            <li>
                                                Usufruir, nas condições regulamentarmente estabelecidas, das regalias concedidas pela Associação;
                                            </li>
                                            <li>
                                                Participar nas reuniões da Assembleia Geral, discutindo e votando todos os assuntos que aí forem tratados;
                                            </li>
                                            <li>
                                                Eleger e ser eleitos para qualquer cargo social;
                                            </li>
                                            <li>
                                                Examinar os livros, relatórios e contas e demais documentos, desde que o requeiram por escrito, com a antecedência, mínima, de oito dias;
                                            </li>
                                            <li>
                                                Reclamar, perante a Direcção, de todos os actos que considerem contrários à lei, estatutos e regulamentos, com recurso para a Assembleia Geral;
                                            </li>
                                            <li>
                                                Recorrer, para o Tribunal competente, das resoluções da Assembleia Geral, contrárias à lei ou aos estatutos;
                                            </li>
                                            <li>
                                                Receber o cartão de sócio, após a admissão;
                                            </li>
                                            <li>
                                                Desistir da qualidade de sócio, o que deve ser comunicado, por escrito, à Direcção.
                                            </li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Os associados só podem exercer os direitos referidos no número anterior se tiverem em dia o pagamento das suas quotas.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Os sócios que tenham sido admitidos há menos de três meses não gozam dos direitos referidos no número um deste artigo, com excepção das alíneas g. e h..
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> Aos sócios menores são vedados, até atingirem a maioridade, os direitos referidos nas alíneas b., c., d., e. e f., do número um deste artigo.
                                    </p>
                                    <p>
                                        <b>CINCO -</b> Os cônjuges e filhos menores de sócios beneficiarão das regalias previstas na alínea a. deste artigo, com exclusão de quaisquer outras.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        São deveres dos sócios:
                                        <ol type='a' className='mt-2'>
                                            <li>
                                                Honrar a Associação, em todas as circunstâncias, e contribuir para o seu prestígio;
                                            </li>
                                            <li>
                                                Observar e fazer cumprir as disposições estatutárias e regulamentares;
                                            </li>
                                            <li>
                                                Acatar as deliberações dos corpos sociais, legitimamente tomadas, respeitando-as, bem como aos funcionários da Associação, quando no exercício das suas funções;
                                            </li>
                                            <li>
                                                Exercer, com dedicação, zelo e eficiência, os cargos sociais para que foram eleitos ou nomeados, salvo pedido de escusa, por doença ou outro motivo atendível, apresentado ao Presidente da Mesa da Assembleia Geral e por este considerado justificado;
                                            </li>
                                            <li>
                                                Não cessar a actividade, nos cargos sociais, sem prévia participação, fundamentada e por escrito, ao Presidente do órgão a que pertencer;
                                            </li>
                                            <li>
                                                Zelar pelos interesses da Associação, comunicando, por escrito, à Direcção, quaisquer irregularidades de que tenham conhecimento;
                                            </li>
                                            <li>
                                                Pagar, pontualmente, a quota fixada, à excepção dos humanitários, que dela ficarão isentos;
                                            </li>
                                            <li>
                                                Comparecer às Assembleias Gerais extraordinárias cuja convocação tenham requerido;
                                            </li>
                                            <li>
                                                Comunicar, por escrito, à Direcção, o local de cobrança das quotas e qualquer situação que altere os seus elementos de identificação, designadamente, a mudança de residência;
                                            </li>
                                            <li>
                                                Apresentar sugestões de interesse colectivo para uma melhor realização dos fins da Associação.
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO III</h4>
                                <h5 className='h5 text-center mb-3'>Sanções e Recompensas</h5>
                                <h4 className='h4 text-center'>Subsecção I</h4>
                                <h4 className='h4 text-center mb-2'>Sanções</h4>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Constitui infracção disciplinar, punível com as sanções estabelecidas nos artigos seguintes, a violação dos deveres consignados no artigo nono.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Os sócios que incorrerem em responsabilidade disciplinar ficam sujeitos, consoante a natureza e gravidade da infracção, às seguintes sanções:
                                        <ol type='a' className='mt-3'>
                                            <li>Advertência verbal;</li>
                                            <li>Censura por escrito;</li>
                                            <li>Suspensão até doze meses;</li>
                                            <li>Expulsão.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A aplicação das sanções referidas nas alíneas a., b. e c. do artigo anterior é da competência da Direcção.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A expulsão é da competência da Assembleia Geral, sob proposta da Direcção.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Os sócios humanitários que sejam punidos com suspensão, nos termos do Regulamento do Corpo de Bombeiros,
                                        ficam impedidos de acesso às instalações da Associação, durante o período da suspensão, incluindo ao respectivo bar,
                                        mesmo que a exploração deste esteja cedida a terceiros.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        A advertência verbal e a censura por escrito são aplicáveis a faltas leves, designadamente, aos casos de violação dos estatutos e regulamentos por mera negligência e sem consequências graves para a Associação.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A suspensão até doze meses é aplicável aos casos de:
                                        <ol type='a' className='mt-3'>
                                            <li>Violação dos estatutos e regulamentos com consequências graves para a Associação;</li>
                                            <li>Reincidência em infracções que tenham dado lugar a advertência ou censura;</li>
                                            <li>Em geral, quando, podendo ter lugar a expulsão, ao sócio se apliquem circunstâncias atenuantes especiais.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A suspensão envolve, enquanto perdurar, a suspensão dos direitos consignados no artigo oitavo, mas não desobriga do pagamento das quotas.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A expulsão implica a eliminação da qualidade de sócio e será aplicável, em geral, quando a infracção seja de tal forma grave que torne impossível o vínculo associativo, por afectar o bom nome da Associação
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Ficam sujeitos, designadamente, à sanção de expulsão, os sócios que:
                                        <ol type='a' className='mt-3'>
                                            <li>Defraudarem dolosamente a Associação;</li>
                                            <li>
                                                Agredirem, injuriarem ou desrespeitarem, gravemente, qualquer membro dos corpos sociais, do corpo activo ou do comando, por motivos relacionados com o exercício do cargo.
                                            </li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Os sócios expulsos não poderão ser readmitidos, salvo se forem reabilitados, em revisão do processo, mediante factos que não tenham podido ser anteriormente ponderados.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>As sanções de suspensão e de expulsão serão sempre precedidas de processo disciplinar com audiência obrigatória do associado.</p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Da sanção de suspensão cabe recurso para a Assembleia Geral, a interpor pelo sócio suspenso, no prazo de trinta dias a contar da notificação da sanção.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Da sanção de expulsão cabe recurso, nos termos das lei, para o Tribunal da Comarca de Fundão.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>Subsecção II</h4>
                                <h4 className='h4 text-center mb-2'>RECOMPENSAS</h4>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Aos sócios que prestaram à Associação serviços relevantes poderão ser atribuídas as seguintes distinções:
                                        <ol type='a' className='mt-3'>
                                            <li>Louvor concedido pela Direcção;</li>
                                            <li>Louvor concedido pela Assembleia Geral;</li>
                                            <li>Nomeação de sócio benemérito ou honorário;</li>
                                            <li>Condecoração concedida pela Direcção, a ratificar pela Assembleia Geral, nos termos do Regulamento Interno.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO QUARTA</h4>
                                <h5 className='h5 text-center mb-3'>DA ELIMINAÇÃO E READMISSÃO</h5>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Perdem a qualidade de sócios:
                                        <ol type='a' className='mt-3'>
                                            <li>Os que forem expulsos, nos termos do artigo décimo quinto, ou demitidos, nos termos do regulamento, do corpo de bombeiros;</li>
                                            <li>Os que pedirem exoneração;</li>
                                            <li>Os que não pagarem as quotas correspondentes a um ano e não satisfizerem o débito no prazo de trinta dias a contar da notificação;</li>
                                            <li>Os que, por motivos ponderosos, devidamente sancionados pela Direcção, pedirem a suspensão da sua qualidade de sócio.</li>
                                        </ol>
                                    </p>
                                    <p><b>DOIS -</b> A eliminação, pelos motivos referidos nas alíneas b. e c., são da competência da Direcção.</p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO DÉCIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Podem ser readmitidos, sem prejuízo da parte final do número três, do artigo décimo quinto, os sócios que tiverem sido:
                                        <ol type='a' className='mt-3'>
                                            <li>Exonerados a seu pedido;</li>
                                            <li>Eliminados por falta de pagamento de quotas;</li>
                                            <li>Suspensos a seu pedido, ao abrigo da alínea d., do artigo décimo nono, e solicitarem a sua readmissão.</li>
                                        </ol>
                                    </p>
                                    <p><b>DOIS -</b> A readmissão só se efectivará a pedido do próprio ex - sócio e desde que pague as quotizações até à data em que pediu a sua suspensão.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_3" aria-expanded="false" aria-controls="capitulo_3">
                                Capítulo III - Dos Corpos Sociais
                            </button>
                        </h2>
                        <div id="capitulo_3" class="accordion-collapse collapse" aria-labelledby="flush-capThree">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>SECÇÃO I</h4>
                                <h5 className='h5 text-center mb-5'>DISPOSIÇÕES GERAIS</h5>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        São órgãos da Associação:
                                        <ol type='a' className='mt-2'>
                                            <li>A Assembleia Geral;</li>
                                            <li>A Direcção;</li>
                                            <li>O Conselho Fiscal.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A duração do mandato dos corpos sociais é de três anos, sem prejuízo de destituição, nos termos da lei, podendo ser reeleitos uma ou mais vezes.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A posse será dada pelo Presidente da Mesa da Assembleia Geral cessante ou pelo seu substituto, no prazo máximo de trinta
                                        dias a contar da data do acto eleitoral; se o Presidente não conferir a posse dentro desse prazo, os membros eleitos
                                        entrarão em exercício, salvo havendo impugnação judicial do acto eleitoral.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> A posse deverá ser assistida pelos corpos sociais cessantes, que farão entrega de todos os valores, documentos, inventário e arquivo da associação.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Não podem ser reeleitos ou novamente designados membros dos órgãos sociais aqueles que,
                                        mediante processo disciplinar ou judicial, tenham sido declarados responsáveis por irregularidades
                                        cometidas no exercício dessas funções ou removidos dos cargos que desempenhavam.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> O disposto no número anterior é extensível à reeleição ou nova designação para órgãos sociais da mesma ou de outra Associação Humanitária de Bombeiros.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Os titulares dos órgãos sociais não podem votar em assuntos que directamente lhes digam respeito ou nos quais sejam interessados os respectivos cônjuges, ascendentes, descendentes ou afins.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> É vedado à Associação contratar directa ou indirectamente com os titulares dos órgãos sociais, seus cônjuges, ascendentes, descendentes e afins ou com sociedades em que qualquer destes tenha interesses.
                                    </p>
                                    <p>
                                        <b>CINCO -</b> Os presidentes da Assembleia Geral e dos órgãos de administração e fiscalização estão impedidos de exercer quaisquer funções no quadro de comando e no quadro activo do respectivo Corpo de Bombeiros.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> O exercício de qualquer cargo dos corpos sociais é gratuito, mas pode justificar o pagamento de despesas dele derivadas.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Sempre que o exercício do cargo, pela complexidade das funções, exija a presença prolongada do seu titular, pode este ser remunerado por deliberação da Assembleia Geral, sob proposta da Direcção.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        É vedado aos membros dos corpos sociais tomar parte em actividades inseridas no escopo da Associação que sejam de fim interessado não altruístico.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Os membros dos corpos sociais não podem recusar-se a votar nas reuniões a que estiverem presentes e são responsáveis, civil e criminalmente, pelas irregularidades cometidas no exercício do mandato, salvo se:
                                        <ol type='a' className='mt-3'>
                                            <li>Não tiverem tomado parte na reunião em que foi tomada a deliberação;</li>
                                            <li>Tiverem votado contra essa deliberação e o fizerem consignar na respectiva acta.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A aprovação dada pela Assembleia Geral ao relatório e contas de gerência da Direcção e ao parecer do Conselho
                                        Fiscal iliba os membros dos corpos sociais da responsabilidade para com a Associação, salvo provando-se omissões por má fé
                                        ou falsas indicações.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>Nenhum sócio poderá ser eleito para mais do que um cargo social, no mesmo mandato.</p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO II</h4>
                                <h5 className='h5 text-center mb-5'>DA ASSEMBLEIA GERAL</h5>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Assembleia Geral é constituída por todos os sócios, maiores ou emancipados, no pleno gozo dos seus direitos sociais e nela reside o poder supremo da Associação.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Consideram-se como sócios no pleno gozo dos seus direitos sociais os que, admitidos, pelo menos, há três meses, tiverem as quotas em dia e não se encontrem suspensos.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO VIGÉSIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Assembleia Geral é dirigida pela respectiva Mesa que se compõe de um Presidente, um Vice - Presidente, um Primeiro Secretário, um Segundo Secretário e dois suplentes.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Na falta ou impedimento do Presidente, o Vice - Presidente desempenhará as suas funções.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Na falta ou impedimento dos secretários, o Presidente designará um dos suplentes para desempenhar as suas funções.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> Na falta ou impedimento de todos os membros da Mesa da Assembleia Geral, competirá a esta eleger os membros substitutos de entre os sócios presentes, os quais cessarão as suas funções depois de lavrarem a respectiva acta.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete à Assembleia Geral deliberar sobre todas as matérias não compreendidas nas atribuições dos outros órgãos sociais e, em especial:
                                        <ol type='a' className='mt-3'>
                                            <li>Definir as linhas fundamentais da actuação da Assembleia e zelar pelo cumprimento da lei, dos estatutos e dos regulamentos;</li>
                                            <li>
                                                Eleger e destituir, por votação secreta, os membros da Assembleia Geral, da Direcção e do Conselho Fiscal;
                                            </li>
                                            <li>
                                                Discutir e votar os relatórios e contas da gerência, do ano anterior, bem como o parecer do Conselho Fiscal;
                                            </li>
                                            <li>
                                                Deliberar sobre a reforma ou alteração dos estatutos;
                                            </li>
                                            <li>
                                                Autorizar a Associação a demandar judicialmente os membros dos corpos sociais por actos praticados no exercício das suas funções;
                                            </li>
                                            <li>
                                                Deliberar sobre todos os recursos que lhe forem interpostos por qualquer dos membros dos corpos sociais, sócios ou trabalhadores da Associação;
                                            </li>
                                            <li>
                                                Fixar, sob proposta da Direcção, os montantes das quotas;
                                            </li>
                                            <li>
                                                Deliberar sobre a atribuição da categoria de sócio benemérito e de sócio honorário, nos termos dos números cinco e seis do artigo sexto;
                                            </li>
                                            <li>
                                                Deliberar sobre o aquisição onerosa ou alienação de bens imóveis e ainda de bens de valor artístico e histórico;
                                            </li>
                                            <li>
                                                Vigiar a fidelidade do exercício dos corpos sociais aos objectivos estatutários;
                                            </li>
                                            <li>
                                                Fixar a retribuição prevista no número dois do artigo vigésimo quarto;
                                            </li>
                                            <li>
                                                Deliberar sobre a filiação ou agrupamento da Associação com outras, em uniões, federações ou confederações, bem como sobre a sua associação com outras pessoas colectivas;
                                            </li>
                                            <li>
                                                Deliberar sobre todas as outras funções que lhe estejam estatutariamente atribuídas.
                                            </li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>Compete ao Presidente da Mesa:
                                        <ol type='a' className='mt-3'>
                                            <li>Convocar as reuniões da Assembleia Geral e dirigir os respectivos trabalhos;</li>
                                            <li>Assinar os termos de abertura e encerramento e rubricar os livros de actas;</li>
                                            <li>Dar posse aos membros dos corpos sociais eleitos;</li>
                                            <li>Verificar a regularidade das listas concorrentes ao acto eleitoral e a elegibilidade dos candidatos;</li>
                                            <li>Aceitar e dar andamento, nos prazos devidos, aos recursos interpostos para a Assembleia Geral;</li>
                                            <li>Convocar os respectivos suplentes, no caso de impedimento prolongado ou pedido de escusa justificada de qualquer dos membros dos corpos sociais;</li>
                                            <li>Exercer as competências que lhe sejam conferidas pela lei, estatutos ou deliberações da Assembleia Geral;</li>
                                            <li>Fixar o limite de tempo e o número de intervenções permitidas a cada associado na discussão de cada assunto, exceptuando-se os membros dos corpos sociais, enquanto tais.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>Compete ao Vice - Presidente coadjuvar o Presidente da Mesa no exercício das suas funções e substitui-lo nas suas faltas ou impedimentos.</p>

                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete aos Secretários:
                                        <ol type='a' className='mt-3'>
                                            <li>Lavrar as actas das reuniões;</li>
                                            <li>Preparar todo o expediente da Mesa e dar-lhe seguimento;</li>
                                            <li>Tomar nota dos sócios presentes às reuniões da Assembleia Geral e dos que durante a sessão pedirem a palavra pela respectiva ordem;</li>
                                            <li>Servir de escrutinadores no acto eleitoral;</li>
                                            <li>Auxiliarem-se, mutuamente, no desempenho das suas funções.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>Os membros da Mesa da Assembleia Geral poderão, sempre que o entenderem por conveniente, assistir às reuniões da Direcção e do Conselho Fiscal, mas sem direito a voto.</p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Assembleia Geral será convocada pelo Presidente da Mesa ou pelo seu substituto, com a antecedência mínima de oito dias, por meio de avisos afixados na sede e em quaisquer outras instalações da Associação e ainda de anúncio num jornal local.
                                    </p>
                                    <p><b>DOIS -</b> Da convocatória constarão obrigatoriamente o dia, a hora, o local da reunião e a respectiva agenda de trabalhos.</p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> As reuniões da Assembleia Geral são ordinárias e extraordinárias.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A Assembleia Geral reunirá ordinariamente até final de Março de cada ano, para discussão, votação e aprovação do relatório de contas do exercício do ano anterior e do parecer do Conselho Fiscal.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Na Assembleia que coincidir com o fim de cada mandato, proceder-se-à, ainda, à eleição dos corpos sociais para o triénio seguinte.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> A Assembleia Geral reunirá extraordinariamente sob convocação do Presidente da Mesa ou do seu substituto, a pedido da Direcção, do Conselho Fiscal ou a requerimento fundamentado e subscrito por cinquenta sócios no pleno gozo dos seus direitos sociais.
                                    </p>
                                    <p>
                                        <b>CINCO -</b> A reunião da Assembleia Geral que seja convocada a requerimento dos sócios só poderá efectuar-se se estiverem presentes, pelo menos, três quartos dos requerentes.
                                    </p>
                                    <p>
                                        <b>SEIS -</b> Quando a reunião prevista no número anterior não se realizar por falta do número mínimo de sócios, ficam,
                                        os que faltaram, inibidos, pelo prazo de dois anos, de requerer a reunião extraordinária da Assembleia Geral e são obrigados
                                        a pagar as despesas decorrentes da convocação, salvo se justificarem a falta por motivos de força maior.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Assembleia Geral só poderá reunir à hora marcada com a presença da maioria dos sócios ou, uma hora depois, com qualquer número de presenças.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> A Assembleia Geral convocada para a dissolução da Associação só poderá funcionar estando presentes ou representados três quartos de todos os associados com direito a nela participarem.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Salvo o disposto no número seguinte, as deliberações da Assembleia Geral serão tomadas por maioria simples de votos, cabendo ao Presidente da Mesa voto de qualidade, em caso de empate.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> As deliberações sobre a reforma ou alteração dos estatutos só serão válidas se merecerem a aprovação de três quartos dos sócios presentes na reunião.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO TRIGÉSIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        São anuláveis as deliberações contrárias à lei e aos estatutos, seja pelo seu objecto, seja por irregularidades
                                        havidas na convocação dos associados ou no funcionamento da Assembleia, salvo tratando-se de deliberações estranhas
                                        à ordem do dia em reuniões em que estejam presentes ou representados todos os sócios e todos concordarem com o aditamento.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        De todas as reuniões da Assembleia Geral serão lavradas actas, em livro próprio, onde constarão as discussões e deliberações tomadas, as quais serão assinadas por todos os membros da mesa.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Os sócios fornecedores ou empregados da Associação não poderão tomar parte nas votações sobre assuntos em que estejam directamente interessados.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        É admitida a representação do sócio mediante carta assinada pelo próprio e exibição do seu bilhete de identidade ou assinatura
                                        reconhecida notarialmente, dirigida ao Presidente da Mesa, delegando poderes noutro sócio no pleno gozo dos seus direitos,
                                        não podendo, contudo, cada associado representar mais do que um sócio.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO III</h4>
                                <h5 className='h5 text-center mb-5'>DA DIRECÇÃO</h5>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Direcção é composta por um Presidente, um Vice - Presidente, um Primeiro Secretário, um Segundo Secretário, um Tesoureiro, um Tesoureiro Adjunto e cinco Vogais.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Três dos vogais serão representantes das Secções destacadas da Associação.
                                    </p>
                                    <p>
                                        <p>TRÊS -</p> Haverá, simultaneamente, três suplentes que se tornarão efectivos à medida que se derem vagas e pela ordem em que tiverem sido eleitos.
                                    </p>
                                    <p>
                                        <b>QUARTO -</b> Os vogais suplentes podem assistir às reuniões da Direcção e participar nos respectivos trabalhos, mas sem direito a voto.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete à Direcção administrar a Associação e designadamente:
                                        <ol type='a' className='mt-3'>
                                            <li>Assegurar a organização e funcionamento dos serviços;</li>
                                            <li>Promover a escrituração dos livros nos termos da lei;</li>
                                            <li>Organizar o quadro de pessoal e gerir os recursos humanos da Associação;</li>
                                            <li>Cumprir e fazer cumprir os estatutos e regulamentos, bem como as deliberações da Assembleia Geral;</li>
                                            <li>Aprovar ou rejeitar as inscrições para a admissão de sócios;</li>
                                            <li>Elaborar o relatório e contas do exercício com referência a trinta e um de Dezembro e submetê-los, com o parecer do Conselho Fiscal, à apreciação da Assembleia Geral;</li>
                                            <li>Elaborar o orçamento e programa de acção para o ano seguinte em colaboração com o Comandante;</li>
                                            <li>Propor à Assembleia Geral a nomeação de sócios beneméritos e honorários;</li>
                                            <li>Propor à Assembleia Geral a reforma ou alteração dos estatutos e a dissolução da Associação;</li>
                                            <li>Fixar ou modificar a estrutura dos serviços da Associação, elaborando os respectivos regulamentos, após ouvir, previamente, o Comandante, naqueles que, directamente, respeitarem à actividade do Corpo Activo;</li>
                                            <li>Fornecer ao Conselho Fiscal os elementos que lhe forem solicitados para o cumprimento das suas atribuições;</li>
                                            <li>Solicitar a convocação da Assembleia Geral extraordinária sempre que o julgar conveniente;</li>
                                            <li>Manter sob a sua guarda e responsabilidade os bens e valores da Associação;</li>
                                            <li>Elaborar e manter actualizado o inventário do património da Associação, entregando, ao Comandante,
                                                um inventário discriminativo de todo o material e viaturas afectas ao Corpo de Bombeiros que ficarão sob a
                                                directa responsabilidade daquele;
                                            </li>
                                            <li>Ordenar a instauração de processos disciplinares e aplicar sanções aos sócios, nos termos dos presentes estatutos;</li>
                                            <li>Representar a Associação em juízo e fora dele;</li>
                                            <li>Submeter à apreciação e votação da Assembleia Geral os assuntos que, pela sua importância, exijam uma tomada de posição da maioria dos sócios;</li>
                                            <li>Propor à Assembleia Geral a alteração do valor das quotas mínimas;</li>
                                            <li>Fixar as taxas eventualmente devidas pela utilização dos serviços da Associação;</li>
                                            <li>Admitir, despedir e readmitir, nos termos legais, o pessoal remunerado da Associação, fixando os vencimentos e horário de trabalho, sob parecer do Comandante;</li>
                                            <li>Nomear comissões ou grupos de trabalho que entenda convenientes para uma melhor prossecução dos objectivos estatutários;</li>
                                            <li>Atribuir louvores e condecorações de acordo com o regulamento honorífico da Associação;</li>
                                            <li>Exercer todas as demais funções que lhe sejam atribuídas pelos estatutos e regulamentos e praticar todos os actos necessários à defesa dos interesses morais e patrimoniais da Associação.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Presidente da Direcção:
                                        <ol type='a' className='mt-3'>
                                            <li>Superintender na administração da Associação, orientar e fiscalizar os respectivos serviços;</li>
                                            <li>Representar a Associação em juízo e fora dele;</li>
                                            <li>Convocar e presidir às reuniões da Direcção;</li>
                                            <li>Promover o cumprimento das deliberações da Assembleia Geral e da Direcção;</li>
                                            <li>Assinar os termos de abertura e encerramento e rubricar o livro das actas da Direcção;</li>
                                            <li>Exercer todas as demais funções que lhe sejam atribuídas pelos estatutos e regulamentos.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Vice - Presidente substituir o Presidente nas suas faltas ou impedimentos e superintender nas actividades da Associação, designadamente:
                                        <ol type='a' className='mt-3'>
                                            <li>Na elaboração do resumo anual das actividades, o qual constituirá elemento para o relatório da Direcção a apresentar em Assembleia Geral;</li>
                                            <li>Na elaboração das propostas dos orçamentos da Associação, submetendo-os à apreciação da Direcção e Comando;</li>
                                            <li>No cumprimento dos serviços de contabilidade e expediente, mantendo-os sempre organizados e actualizados;</li>
                                            <li>No cumprimento das disposições legais em relação aos trabalhadores;</li>
                                            <li>Zelar pela conservação do património da Associação que lhe está afecto;</li>
                                            <li>Planear o desenvolvimento das actividades da Associação.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Compete ao Primeiro Secretário:
                                        <ol type='a' className='mt-3'>
                                            <li>Organizar e orientar todo o serviço de secretaria;</li>
                                            <li>Preparar a agenda de trabalho para as reuniões da Direcção;</li>
                                            <li>Redigir o respectivo livro de actas, mantendo-o, sempre, em dia;</li>
                                            <li>Prover todo o expediente da Associação.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Compete ao Segundo Secretário coadjuvar o Primeiro Secretário nas funções que a este pertencem, executar as tarefas que lhe forem designadas e substitui-lo na sua ausência ou impedimento.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Compete ao Tesoureiro:
                                        <ol type='a' className='mt-3'>
                                            <li>A arrecadação de receitas;</li>
                                            <li>A satisfação das despesas autorizadas;</li>
                                            <li>Assinar as autorizações de pagamento e as guias de receita, arquivando todos os documentos de despesa e receita;</li>
                                            <li>Depositar em qualquer instituição de crédito as disponibilidades que não sejam de aplicação imediata;</li>
                                            <li>A orientação e controlo da escrituração de todos os livros de contabilidade, velando pela segurança de todos os haveres e conferindo o cofre, pelo menos, uma vez por mês;</li>
                                            <li>A apresentação à Direcção do balancete em que se discriminem as receitas e as despesas do mês anterior, bem como a prestação de contas sempre que a Direcção o entenda;</li>
                                            <li>A elaboração anual de um orçamento em que se discriminem as receitas e despesas previstas para o exercício do ano seguinte;</li>
                                            <li>Efectuar o necessário provimento de fundos para que, nas datas estabelecidas, a Associação possa solver os seus compromissos;</li>
                                            <li>A actualização do inventário do património associativo;</li>
                                            <li>Em geral, prestar todos os esclarecimentos sobre assuntos de contabilidade e tesouraria.</li>
                                        </ol>
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Compete ao Tesoureiro Adjunto substituir o Tesoureiro nos seus impedimentos e coadjuvá-lo nas tarefas que lhe estão atribuídas, nomeadamente:
                                        <ol type='a' className='mt-3'>
                                            <li>Organizar o serviço de cobrança de quotas;</li>
                                            <li>Organizar e manter actualizado o ficheiro de sócios;</li>
                                            <li>Providenciar as cobranças das facturas dos serviços prestados pela Associação.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUADRAGÉSIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Aos vogais compete colaborar em todos os serviços respeitantes à gestão da Associação, exercendo as funções que a Direcção lhes atribuir, de acordo com o plano de actividades que estabelecer.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A Direcção reunirá sempre que o julgar conveniente, sob convocação do Presidente, por iniciativa deste ou da
                                        maioria dos seus membros, ou a pedido do Conselho Fiscal, e, em princípio, semanalmente, em dia por ela estabelecido.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> As deliberações serão tomadas por maioria dos votos, cabendo ao Presidente voto de qualidade em caso de empate.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> A Direcção não poderá reunir sem a presença da maioria dos seus membros eleitos.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> Às reuniões da Direcção poderá participar o Comandante, não tendo, contudo, direito a voto nas deliberações tomadas;
                                        será, porém, tido em atenção o seu parecer nos assuntos que digam respeito ao corpo activo, às aquisições de equipamento e
                                        contratação de pessoal permanente, especificamente destinado à actividade do Corpo de Bombeiros.
                                    </p>
                                    <p>
                                        <b>QUINTO -</b> Das reuniões da Direcção serão lavradas actas em livro próprio, que deverão ser assinadas pelos presentes.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Para obrigar a Associação são necessárias e bastantes as assinaturas de dois membros efectivos da Direcção,
                                        uma das quais será a do Presidente, ou, na sua falta ou impedimento, a do Vice - Presidente.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Nas operações financeiras são obrigatórias as assinaturas conjuntas do Presidente da Direcção, ou, na sua
                                        falta ou impedimento, do Vice - Presidente, e a de um dos Tesoureiros, ou a de outro elemento designado pela Direcção para o efeito.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Os actos de mero expediente poderão ser assinados por qualquer membro da Direcção.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>SECÇÃO IV</h4>
                                <h5 className='h5 text-center mb-5'>DO CONSELHO FISCAL</h5>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        O Conselho Fiscal é constituído por um Presidente, um Vice - Presidente, um Secretário - Relator e dois suplentes, os quais se tornarão efectivos à medida que se derem vagas e pela ordem que tiverem sido eleitos.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Conselho Fiscal inspeccionar e fiscalizar os actos de administração, zelando pelo cumprimento da lei, dos estatutos e regulamentos e, em especial:
                                        <ol type='a' className='mt-3'>
                                            <li>Examinar a escrituração e demais documentos, sempre que o julgar conveniente;</li>
                                            <li>Solicitar a convocação da Assembleia Geral, sempre que o julgar conveniente;</li>
                                            <li>Dar parecer sobre o orçamento e relatório e contas do exercício apresentados pela Direcção;</li>
                                            <li>Fiscalizar a administração da Direcção;</li>
                                            <li>Solicitar à Direcção reuniões extraordinárias para discussão conjunta de assuntos cuja importância o justifique;</li>
                                            <li>Assistir às reuniões da Direcção sempre que o julgue conveniente e tomar parte na discussão dos assuntos tratados, mas sem direito a voto;</li>
                                            <li>Emitir parecer, aos outros órgãos sociais, sobre quaisquer assuntos para que seja consultado, designadamente, sobre a aquisição onerosa e alienação de imóveis, reforma ou alteração dos estatutos e dissolução da Associação;</li>
                                            <li>Exercer todas as outras funções que lhe sejam atribuídas pelos estatutos e regulamentos.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Presidente do Conselho Fiscal:
                                        <ol type='a' className='mt-3'>
                                            <li>Convocar e presidir às reuniões do Conselho Fiscal;</li>
                                            <li>Assinar os termos de abertura e encerramento e rubricar o respectivo livro de actas;</li>
                                            <li>Exercer as demais funções que lhe sejam atribuídas pelos estatutos e regulamentos.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Vice - Presidente do Conselho Fiscal substituir o Presidente nas suas faltas ou impedimentos.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Compete ao Secretário - Relator do Conselho Fiscal:
                                        <ol type='a' className='mt-3'>
                                            <li>Preparar as agendas de trabalhos para as reuniões;</li>
                                            <li>Prover a todo o expediente;</li>
                                            <li>Lavrar o respectivo livro de actas;</li>
                                            <li>Relatar os pareceres sobre os assuntos que forem submetidos ao Conselho Fiscal.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO SÉTIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> O Conselho Fiscal reunirá sempre que o entender e obrigatoriamente sempre que lhe sejam apresentados pedidos de pareceres pela Direcção, nomeadamente, sobre o orçamento e contas de exercício anuais.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> O Conselho Fiscal só poderá reunir com a maioria dos seus membros e as suas deliberações são tomadas por maioria simples de votos, cabendo ao Presidente, em caso de empate, voto de qualidade.
                                    </p>
                                    <p>
                                        <b>TRÊS</b> - As deliberações constarão de livro de actas, as quais serão assinadas pelos presentes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_4" aria-expanded="false" aria-controls="capitulo_4">
                                Capítulo IV - Das Eleições
                            </button>
                        </h2>
                        <div id="capitulo_4" class="accordion-collapse collapse" aria-labelledby="flush-capFour">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO OITAVO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A eleição dos corpos sociais será feita por votação secreta dos sócios, tendo cada um direito a um voto e em lista ou listas separadas, nas quais se identificarão os candidatos e se indicará o órgão e cargo para que são propostos.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Concorrendo uma só lista, o Presidente da Mesa da Assembleia Geral pode propor que ela seja aprovada por aclamação.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> As listas serão subscritas por um mínimo de vinte e cinco sócios, salvo a que for apresentada pela Direcção.
                                    </p>
                                    <p>
                                        <b>QUATRO - </b>A lista ou listas serão entregues ao Presidente da Mesa da Assembleia Geral até final do mês de Janeiro do ano da
                                        eleição dos corpos sociais, que as mandará afixar na sede e outras instalações da Associação,
                                        com a antecedência mínima de oito dias da data marcada para as eleições.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO QUINQUAGÉSIMO NONO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> A eleição dos membros dos corpos sociais realizar-se-à em Assembleia Geral ordinária, no mês de Fevereiro do ano seguinte àquele em que terminar o mandato dos corpos sociais em exercício.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Quando as eleições não sejam realizadas atempadamente, considera-se prorrogado o mandato em curso até à posse dos novos corpos sociais.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> É admitido o voto por correspondência, sendo o respectivo boletim metido em envelope fechado, que será
                                        remetido em carta endereçada ao Presidente da Mesa da Assembleia Geral, devendo esta conter, ainda,
                                        a identificação completa do votante e a sua assinatura reconhecida notarialmente.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> O escrutínio realizar-se-à, imediatamente, após concluída a votação, sendo proclamados eleitos os componentes da lista mais votada.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> As mesas de voto funcionarão na sede, podendo também funcionar nas instalações das Secções destacadas, quando tal se justifique.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> Na sede, a mesa de voto será constituída pela Mesa da Assembleia Geral e, nos demais casos, por mesas nomeadas pelo Presidente da Mesa da Assembleia Geral.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Na constituição das mesas de voto, cada lista poderá fazer-se representar por um seu elemento.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO PRIMEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        São elegíveis os sócios que satisfaçam, cumulativamente, os seguintes requisitos:
                                        <ol type='a' className='mt-3'>
                                            <li>Estejam no pleno gozo dos seus direitos sociais;</li>
                                            <li>Sejam maiores ou emancipados;</li>
                                            <li>Sejam associados há, pelo menos, três meses;</li>
                                            <li>Não façam parte dos corpos sociais de outras Associações congéneres;</li>
                                            <li>Não tenham sido destituídos dos corpos sociais da Associação por irregularidades cometidas no exercício das suas funções;</li>
                                            <li>Não sejam trabalhadores subordinados da Associação.</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_5" aria-expanded="false" aria-controls="capitulo_5">
                                Capítulo V - Da Gestão Financeira
                            </button>
                        </h2>
                        <div id="capitulo_5" class="accordion-collapse collapse" aria-labelledby="flush-capFive">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO SEGUNDO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        São receitas da Associação:
                                        <ol type='a' className='mt-3'>
                                            <li>Os produtos das quotas dos sócios;</li>
                                            <li>As comparticipações das quotas dos sócios, familiares e de outras entidades pela utilização dos serviços da Associação;</li>
                                            <li>Os subsídios e comparticipações oficiais;</li>
                                            <li>Os donativos, legados e heranças feitas a favor da Associação;</li>
                                            <li>Os rendimentos de bens próprios;</li>
                                            <li>O produto líquido de quaisquer espectáculos, festas e diversões;</li>
                                            <li>Quaisquer outras receitas não especificadas.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO TERCEIRO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        Constituem despesas da Associação, as resultantes de:
                                        <ol type='a' className='mt-3'>
                                            <li>Manter o Corpo de Bombeiros nas melhores condições operacionais;</li>
                                            <li>Prover o bom funcionamento das actividades desportivas, culturais, recreativas e de acção médica;</li>
                                            <li>Administração, designadamente, com os vencimentos dos empregados da Associação;</li>
                                            <li>Quaisquer outras resultantes dos fins estatutários da Associação.</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_6" aria-expanded="false" aria-controls="capitulo_6">
                                Capítulo VI - Da Reforma ou Alteração dos Estatutos
                            </button>
                        </h2>
                        <div id="capitulo_6" class="accordion-collapse collapse" aria-labelledby="flush-capSix">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO QUARTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM -</b> Os presentes estatutos só podem ser reformados ou alterados por deliberação da Assembleia Geral, convocada para esse fim, sob proposta da Direcção ou a requerimento fundamentado de, pelo menos, cinquenta sócios efectivos, no pleno gozo dos seus direitos.
                                    </p>
                                    <p>
                                        <b>DOIS -</b> À Assembleia Geral requerida pelos sócios aplicar-se-à o disposto nos números quatro, cinco e seis do artigo trigésimo sexto.
                                    </p>
                                    <p>
                                        <b>TRÊS -</b> Uma vez feita a convocatória, as alterações estatutárias propostas deverão ficar patentes aos sócios,
                                        na sede, e em quaisquer outras instalações da Associação, com a antecedência mínima de oito dias em relação à
                                        data marcada para a reunião da Assembleia Geral.
                                    </p>
                                    <p>
                                        <b>QUATRO -</b> As alterações estatutárias só poderão ser deliberadas mediante os votos favoráveis de três quartos dos sócios presentes e representados na reunião.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="flush-capSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#capitulo_7" aria-expanded="false" aria-controls="capitulo_7">
                                Capítulo VII - Da Dissolução
                            </button>
                        </h2>
                        <div id="capitulo_7" class="accordion-collapse collapse" aria-labelledby="flush-capSeven">
                            <div className="accordion-body mt-5">
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO QUINTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        A Associação extingue-se:
                                        <ol type='a' className='mt-3'>
                                            <li>Por deliberação da Assembleia Geral;</li>
                                            <li>Por absoluta carência de recursos para prosseguir os fins estatutários;</li>
                                            <li>Por decisão judicial que declare a sua insolvência.</li>
                                        </ol>
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>ARTIGO SEXAGÉSIMO SEXTO</h4>
                                <div className='container ml-5 mb-5 mt-3'>
                                    <p>
                                        <b>UM –</b> Extinta a Associação é eleita uma comissão liquidatária pela Assembleia Geral e pela entidade que decretou a extinção,
                                    </p>
                                    <p>
                                        <b>DOIS –</b> Os poderes da comissão liquidatária ficam limitados à prática dos actos meramente conservatórios e necessários, quer à liquidação do património social, quer à utilização dos negócios pendentes, sendo que, pelos actos restantes e pelos danos que deles advenham à Associação respondem solidariamente os titulares dos órgãos sociais que os praticarem.
                                    </p>
                                    <p>
                                        <b>TRÊS –</b> A liquidação e partilha dos bens da Associação, uma vez dissolvida, será feita nos termos da lei que define o Regime Jurídico das Associações Humanitárias de Bombeiros e demais legislação aplicável.
                                    </p>
                                </div>
                                <h4 className='h4 text-center'>Fundão, 03 de Março de 2011</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Estatutos