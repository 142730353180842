import React, { useState, useEffect } from 'react'
import { Chrono } from 'react-chrono'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import image_4 from '../../galeria/historia/historia_1.jpg'
import image_5 from '../../galeria/historia/historia_2.jpg'
import image_6 from '../../galeria/historia/historia_3.jpg'
import image_7 from '../../galeria/historia/historia_4.jpg'
import image_8 from '../../galeria/historia/historia_5.jpg'
import image_9 from '../../galeria/historia/historia_6.jpg'
import image_10 from '../../galeria/historia/historia_7.jpg'
import image_11 from '../../galeria/historia/historia_8.jpg'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}

function Historia() {

    const { ref, inVew } = useInView({
        threshold: 0.2
    })
    const animation = useAnimation()
    const [index, setIndex] = useState(-1);


    const [historyCards, setHistoryCards] = useState([
        { titleCard: 'Criação do Quartel', textCard: 'Criação dos alicerces do que viria a ser a prestigiada Corporação dos Bombeiros Voluntários de Fundão' },
        { titleCard: 'Criação da Associação', textCard: 'Criação e legalização da Associação Humanitária de Bombeiros Voluntários de Fundão' },
        { titleCard: 'Inscrição na Liga', textCard: 'Inscrição na Liga dos Bombeiros' },
        { titleCard: 'Novo Quartel e Medalha de Honra', textCard: 'Construção do novo quartel e medalha de honra do concelho do Fundão' },
        { titleCard: 'Secção de Silvares', textCard: 'Criação da secção de Silvares' },
        { titleCard: 'Medalha de Ouro', textCard: 'Medalha de ouro de mérito municipal' },
        { titleCard: 'Secção de Soalheira e Três Povos', textCard: 'Criação das secções da Soalheira e dos Três Povos' },
        { titleCard: 'Medalha de Serviço', textCard: 'Medalha de serviços distintos – Grau Ouro' },
        { titleCard: 'Museu de Viaturas', textCard: 'Inauguração do museu de viaturas' },
        { titleCard: 'Escola de Infantes e Cadetes', textCard: 'Criação da escola de infantes e cadetes' },
    ])

    const historyGallery = [
        { src: image_4, width: 1080, height: 800 },
        { src: image_5, width: 400, height: 300 },
        { src: image_6, width: 1050, height: 800 },
        { src: image_7, width: 1080, height: 800 },
        { src: image_8, width: 750, height: 850 },
        { src: image_9, width: 1080, height: 800 },
        { src: image_10, width: 1080, height: 800 },
        { src: image_11, width: 1080, height: 800 },

    ]
    const historia = [
        { title: '1898' },
        { title: '1927' },
        { title: '1932' },
        { title: '1979' },
        { title: '1986' },
        { title: '1991' },
        { title: '1993' },
        { title: '2002' },
        { title: '2004' },
        { title: '2014' }
    ]



    useEffect(() => {
        if (inVew) {
            animation.start({
                opacity: 1,
                transition: { delay: 3, duration: 3 }
            })
        }
    }, [inVew])


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerHistoria p-5 mb-5'>
                <h1 className='text-center'>História</h1>
            </div>
            <div className='container mt-5 mb-5'>
                <div className='container textArea mb-5'>
                    <p>
                        Em 1898 alguns fundanenses, dinamizados por António Joaquim de Almeida,
                        organizaram o embrião do que viria a ser a prestigiada Corporação dos Bombeiros
                        Voluntários do Fundão. Contudo, só em 1927 são lançadas as bases de organização e
                        fortalecimento da Corporação, quando foi criada e legalizada a Associação Humanitária
                        de Bombeiros Voluntários do Fundão cujos estatutos foram aprovados em 23 de maio de
                        1927.
                        <br /><br />
                        O seu 1º Comandante foi o notário e jornalista José dos Santos Barata que se devotou,
                        apaixonadamente, à causa dos Bombeiros até à sua prematura morte em março de 1936.
                        O seu exemplo de devoção e entrega à causa dos Bombeiros foi continuado pelos
                        Comandantes que se seguiram. Capitão Videira (1936-1938), Santos Marques (1938-
                        1942), António Pião (1942-1980), Luís da Silva Carvalho (1980-2000), António Antunes
                        (2000-2013) e pelo atual Comandante, José Sousa, desde fevereiro de 2014.
                        <br /><br />
                        Esta Associação teve nos Presidentes de Direcção figuras marcantes no seu
                        crescimento e desenvolvimento. Destacaram-se o Dr. Albano Torcato Salvado, o Dr.
                        Augusto Sousa Seco (1949-1962), o Dr. António Esteves Ladeira (1981-1986), o Dr.
                        Pedro Lopes Dias (1971-1980 / 1990-1992), o Dr. Miguel Campos (1993-2011), o Dr.
                        António Rodrigues (2011 - 2016) e o atual Eng.º Carlos Jerónimo.
                        <br /><br />
                        Estes Homens, devotados ao lema de abnegadamente servirem o próximo, souberam
                        conduzir e elevar a Corporação do Fundão a um lugar cimeiro entre as suas congéneres.
                        Em reconhecimento o Presidente da República agraciou a Associação com o Grau de
                        Cavaleiro da Ordem de Benemerência, aquando das comemorações das Bodas de Prata
                        em Maio de 1952; Por relevantes serviços prestados à causa do socorrismo, a Liga dos
                        Bombeiros Portugueses atribuiu-lhe, em 26 de Março de 1981, a Medalha de Ouro – 2
                        Estrelas; Em 2 de Junho de 2002, por ocasião do 75º aniversário, a Liga dos Bombeiros
                        Voluntários Portugueses conferiu-lhe a Medalha de Serviços Distintos – Grau Ouro –
                        pelos relevantes serviços prestados à causa dos Bombeiros ao longo de 75 anos.
                        <br /><br />
                        Para enfrentar e corresponder às novas e desafiantes exigências sociais houve
                        necessidade de construir um novo quartel-sede em 1979 e criadas as Secções Destacadas.
                        Com efeito, para que o socorro das populações mais distantes fosse mais rápido e eficaz
                        foram instaladas na periferia do concelho, nas localidades de Silvares (1986), Três Povos
                        e Soalheira (1993), três secções com quartéis próprios devidamente equipados para o
                        combate a incêndios e ocorrências de sinistros.<br /><br />
                        Para corresponder eficazmente às múltiplas solicitações a Associação possui em
                        condições operacionais 60 viaturas para intervenção em várias áreas de socorro e tem ao
                        seu serviço 44 funcionários assalariados.<br /><br />
                        No seio da AHBVF funciona o Núcleo de Dadores Benévolos de Sangue e o Posto de
                        Enfermagem que atualmente é para uso exclusivo dos bombeiros, mas até á pouco tempo
                        atras, durante mais de 25 anos prestou diariamente, importante assistência à comunidade.
                        <br /><br />
                        A AHBVF possui um Departamento de Formação vocacionado para a manutenção
                        da formação interna aos seus bombeiros e que também disponibiliza formação para
                        empresas, instituições e público em geral.<br /><br />
                        Do Corpo de Bombeiros do Fundão fazem parte cerca de 35 instrumentistas que
                        integram a “Fanfarra”.<br /><br />
                        Com o intuito de despertar nos mais novos o espírito de voluntariado foi criado em
                        2014 uma Escola de Infantes e Cadetes que é frequentada por quatro dezenas de crianças
                        e jovens com idades compreendidas entre os 6 e os 16 anos.<br /><br />
                        Muito longe já vão os tempos em que uma rudimentar motobomba, que em 1930 foi
                        calorosamente acolhida pela população fundanense, correspondia às necessidades que,
                        então, quase se resumiam aos esporádicos fogos urbanos.<br /><br />
                        Pelos inúmeros serviços prestados ao longo da sua já longa vida, a AHBVF é exemplo
                        único de Instituição Humanitária, vocacionada para servir a população que a ela recorre.
                        Indiferentes a credos, raças, religiões ou condição social, os nossos bombeiros voluntários
                        fazem do Corpo de Bombeiros Voluntários de Fundão o orgulho do nosso Concelho
                    </p>
                </div>
                <div ref={ref}>
                    <Chrono
                        items={historia}
                        slideShow
                        slideItemDuration={6500}
                        mode='VERTICAL_ALTERNATING'
                        hideControls
                        cardPositionHorizontal='TOP'
                        flipLayout='true'

                        theme={
                            {
                                primary: "#870008",
                                secondary: "aliceblue",
                                cardBgColor: "aliceblue",
                                cardForeColor: "#870008",
                                titleColor: "#870008"

                            }
                        }
                    >
                        {
                            historyCards.map(card => {
                                return (
                                    <motion.div
                                        initial={{
                                            opacity: 0
                                        }}
                                        animate={{
                                            opacity: 1
                                        }}
                                        transition={{ delay: 4, duration: 3 }}
                                    >
                                        <div>
                                            <h4 className='h4' style={{ color: '#870008' }}>{card.titleCard}</h4>
                                            <p>{card.textCard}</p>
                                        </div>
                                    </motion.div>
                                )
                            })
                        }
                    </Chrono>
                </div>


                <div className='container-fluid mt-5'>
                    <PhotoAlbum layout='columns' photos={historyGallery} padding={3} onClick={({ index }) => setIndex(index)} />
                    <Lightbox
                        slides={historyGallery}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Zoom]}
                    />
                </div>
            </div>


        </motion.div>
    )
}

export default Historia