import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Typography } from '@mui/material';
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import { fichaCadete } from '../functions/FunctionsFront';

function TabelaCadete() {
    const [listCadetes, setListCadetes] = useState([])
    

    const columns = useMemo(() => [
        {
            accessorKey: 'nome',
            header: 'Nome',
        },
        {
            accessorKey: 'localidade',
            header: 'Localidade',
        },
        {
            accessorKey: 'contacto',
            header: 'Contacto',
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            id: 'downloadPDF',
            header: 'Ficha',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <button className='btn btn-outline-secondary btnNovoSocio' onClick={(e) => fichaCadete(row.original.nome,row.original.nascimento,row.original.cc, row.original.morada, row.original.cp,row.original.localidade,row.original.concelho,row.original.contacto,row.original.email, row.original.logo)}>Ver</button>
            )
        }
    ], [])
    useEffect(() => {
        axios.get('/cadete/all_cadetes')
            .then((response) => {
                setListCadetes(response.data.cadetes)

            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Cadetes</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'></div>
            <div className='container-fluid'>
                <MaterialReactTable columns={columns} data={listCadetes}
                    enableColumnFilters={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnResizing
                    enableColumnActions={false}
                    enableSelectAll={false}
                    muiTableHeadCellProps={{
                        style: {
                            color: 'white',
                            fontSize: 24

                        }
                    }}
                    muiTableHeadRowProps={{
                        style: {
                            backgroundColor: 'orange'
                        }
                    }}
                    muiTopToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                    muiBottomToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                />
            </div>
            <br /><br />
        </div>
    )
}

export default TabelaCadete