import React, { useState } from 'react'
import Seccoes from './Seccoes'

import ana_martins from '../../galeria/corpo_ativo/soalheira/ana_martins.jpg'
import antonio_ferreira from '../../galeria/corpo_ativo/soalheira/antonio_ferreira.jpg'
import bruno_roberto from '../../galeria/corpo_ativo/soalheira/bruno_roberto.jpg'
import cristina_ramos from '../../galeria/corpo_ativo/soalheira/cristina_ramos.jpg'
import daniela_carvalho from '../../galeria/corpo_ativo/soalheira/daniela_carvalho.jpg'
import david_morgado from '../../galeria/corpo_ativo/soalheira/david_morgado.jpg'
import david_vicente from '../../galeria/corpo_ativo/soalheira/david_vicente.jpg'
import diogo_opiniao from '../../galeria/corpo_ativo/soalheira/diogo_opiniao.jpg'
import flavio_cruz from '../../galeria/corpo_ativo/soalheira/flavio_cruz.jpg'
import joao_mendes from '../../galeria/corpo_ativo/soalheira/joao_mendes.jpg'
import joao_ramos from '../../galeria/corpo_ativo/soalheira/joao_ramos.jpg'
import juliana_silvares from '../../galeria/corpo_ativo/soalheira/juliana_silvares.jpg'
import luis_silva from '../../galeria/corpo_ativo/soalheira/luis_silva.jpg'
import nelson_mendes from '../../galeria/corpo_ativo/soalheira/nelson_mendes.jpg'
import pedro_correia from '../../galeria/corpo_ativo/soalheira/pedro_correia.jpg'
import pedro_diogo from '../../galeria/corpo_ativo/soalheira/pedro_diogo.jpg'
import rogerio_afonso from '../../galeria/corpo_ativo/soalheira/rogerio_afonso.jpg'
import tomas_sanches from '../../galeria/corpo_ativo/soalheira/tomas_sanches.jpg'

import portrait from '../../images/portait.jpg'
import chefeDivisa from '../../images/divisas/chefe.png'
import subChefeDivisa from '../../images/divisas/sub-chefe.png'
import bombeiro1Divisa from '../../images/divisas/bombeiro-1.png'
import bombeiro2Divisa from '../../images/divisas/bombeiro-2.png'
import bombeiro3Divisa from '../../images/divisas/bombeiro-3.png'



import ABSC12 from '../../galeria/corpo_ativo/soalheira/viaturas/ABSC12.jpg'
import ABTD16 from '../../galeria/corpo_ativo/soalheira/viaturas/ABTD16.jpg'
import VLCI01 from '../../galeria/corpo_ativo/soalheira/viaturas/VLCI01.jpg'
import VRCI11 from '../../galeria/corpo_ativo/soalheira/viaturas/VRCI11.jpg'
import VFCI14 from '../../galeria/corpo_ativo/soalheira/viaturas/VRCI14.jpg'
import VTPT03 from '../../galeria/corpo_ativo/soalheira/viaturas/VTPT03.jpg'
import VTTU01 from '../../galeria/corpo_ativo/soalheira/viaturas/VTTU01.jpg'


function Soalheira() {

  let seccao = 'Soalheira'

  const [chefe, setChefe] = useState([
    { nome: 'Nelson Mendes', divisa: chefeDivisa, foto: nelson_mendes },
    
  ])
  const [subchefe, setSubchefe] = useState([
    { nome: 'Carlos Jorge Cabeças Santos', divisa: subChefeDivisa, foto: portrait },
    { nome: 'Pedro Nuno Marques Silva', divisa: subChefeDivisa, foto: portrait },
    { nome: 'Paulo Fernando Filipe Marques', divisa: subChefeDivisa, foto: portrait },
    { nome: 'Carlos Manuel Marques Magueijo', divisa: subChefeDivisa, foto: portrait },
    { nome: 'Nelson Lucas Santos Mendes', divisa: subChefeDivisa, foto: portrait },
    { nome: 'João Roberto Andrade', divisa: subChefeDivisa, foto: portrait },
    { nome: 'Paulo Jorge Nogueira Pires', divisa: subChefeDivisa, foto: portrait },
  ])

  const [bombeirosPrimeira, setBombeirosPrimeira] = useState([
    { nome: 'Bruno Roberto', divisa: bombeiro1Divisa, foto: bruno_roberto },
    { nome: 'Pedro Diogo', divisa: bombeiro1Divisa, foto: pedro_diogo },
    { nome: 'David Vicente', divisa: bombeiro1Divisa, foto: david_vicente },
    { nome: 'Flávio Cruz', divisa: bombeiro1Divisa, foto: flavio_cruz },
    { nome: 'António Ferreira', divisa: bombeiro1Divisa, foto: antonio_ferreira }
  ])

  const [bombeirosSegunda, setBombeirosSegunda] = useState([
    { nome: 'Diogo Opinião', divisa: bombeiro2Divisa, foto: diogo_opiniao },
    { nome: 'David Vicente', divisa: bombeiro2Divisa, foto: david_vicente },
    { nome: 'Ana Martins', divisa: bombeiro2Divisa, foto: ana_martins },
    { nome: 'David Morgado', divisa: bombeiro2Divisa, foto: david_morgado },
    { nome: 'Juliana Silvares', divisa: bombeiro2Divisa, foto: juliana_silvares },
    { nome: 'Pedro Correia', divisa: bombeiro2Divisa, foto: pedro_correia }
  ])

  const [bombeirosTerceira, setBombeirosTerceira] = useState([
    { nome: 'João Ramos', divisa: bombeiro3Divisa, foto: joao_ramos },
    { nome: 'João Mendes', divisa: bombeiro3Divisa, foto: joao_mendes },
    { nome: 'Rogério Afonso', divisa: bombeiro3Divisa, foto: rogerio_afonso },
    { nome: 'Cristina Ramos', divisa: bombeiro3Divisa, foto: cristina_ramos },
    { nome: 'Tomás Sanches', divisa: bombeiro3Divisa, foto: tomas_sanches },
    { nome: 'Daniela Carvalho', divisa: bombeiro3Divisa, foto: daniela_carvalho },
    { nome: 'Luis Silva', divisa: bombeiro3Divisa, foto: luis_silva }
  ])
  const [veiculos] = useState([
    { nome: ' ABSC12', foto: ABSC12, tipo: 'ambulancia_de_socorro' },
    { nome: ' ABTD16', foto: ABTD16, tipo: 'ambulancia_de_transportes_de_doentes' },
    { nome: ' VFCI14', foto: VFCI14, tipo: 'veiculos_florestal_de_combate_a_incendios' },
    { nome: ' VLCI01', foto: VLCI01, tipo: 'veiculos_ligeiro_de_combate_a_incendios' },
    { nome: ' VRCI11', foto: VRCI11, tipo: 'veiculos_rural_de_combate_a_incendios' },
    { nome: ' VTPT03', foto: VTPT03, tipo: 'veiculo_de_transporte_tático_de_pessoal' },
    { nome: ' VTTU01', foto: VTTU01, tipo: 'veiculos_tanque_grande_capacidade' },
])
  return (
    <Seccoes nameSeccao={seccao} chefeSec={chefe} subChefeSec={subchefe} secBomb_1={bombeirosPrimeira} secBomb_2={bombeirosSegunda} secBomb_3={bombeirosTerceira} SecViaturas={veiculos} />
  )
}

export default Soalheira