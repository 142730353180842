import React, { useState } from 'react'
import Seccoes from './Seccoes'


import alfredo_serra from '../../galeria/corpo_ativo/silvares/alfredo_serra.jpg'
import carlos_pires from '../../galeria/corpo_ativo/silvares/carlos_pires.jpg'
import carlos_ramos from '../../galeria/corpo_ativo/silvares/carlos_ramos.jpg'
import eduardo_marques from '../../galeria/corpo_ativo/silvares/eduardo_marques.jpg'
import hugo_diogo from '../../galeria/corpo_ativo/silvares/hugo_diogo.jpg'
import joao_gonçalves from '../../galeria/corpo_ativo/silvares/joao_gonçalves.jpg'
import joao_morgadinho from '../../galeria/corpo_ativo/silvares/joao_morgadinho.jpg'
import joaquim_dias from '../../galeria/corpo_ativo/silvares/joaquim_dias.jpg'
import luis_morgadinho from '../../galeria/corpo_ativo/silvares/luis_morgadinho.jpg'
import miguel_silva from '../../galeria/corpo_ativo/silvares/miguel_silva.jpg'
import paulo_marques from '../../galeria/corpo_ativo/silvares/paulo_marques.jpg'
import ruben_serra from '../../galeria/corpo_ativo/silvares/ruben_serra.jpg'
import rui_serra from '../../galeria/corpo_ativo/silvares/rui_serra.jpg'
import tania_serra from '../../galeria/corpo_ativo/silvares/tania_serra.jpg'
import telmo_lobo from '../../galeria/corpo_ativo/silvares/telmo_lobo.jpg'

import portrait from '../../images/portait.jpg'
import chefeDivisa from '../../images/divisas/chefe.png'
import subChefeDivisa from '../../images/divisas/sub-chefe.png'
import bombeiro1Divisa from '../../images/divisas/bombeiro-1.png'
import bombeiro2Divisa from '../../images/divisas/bombeiro-2.png'
import bombeiro3Divisa from '../../images/divisas/bombeiro-3.png'


import VLCI02 from '../../galeria/corpo_ativo/silvares/viaturas/VLCI02.jpg'
import VRCI13 from '../../galeria/corpo_ativo/silvares/viaturas/VRCI13.jpg'
import VRCI15 from '../../galeria/corpo_ativo/silvares/viaturas/VRCI15.jpg'



function Silvares() {

  let seccao = 'Silvares'

  const [chefe, setChefe] = useState([
    { nome: 'Abílio Machial Dias', divisa: chefeDivisa, foto: portrait },
    { nome: 'Francisco Luís Santos Barros', divisa: chefeDivisa, foto: portrait },
    { nome: 'Martinho Gomes Vaz', divisa: chefeDivisa, foto: portrait }
  ])
  const [subchefe, setSubchefe] = useState([
    { nome: 'Paulo Marques', divisa: subChefeDivisa, foto: paulo_marques },
    { nome: 'Rui Serra', divisa: subChefeDivisa, foto: rui_serra },
    { nome: 'Luís Morgadinho', divisa: subChefeDivisa, foto: luis_morgadinho },
  ])
  const [bombeirosPrimeira, setBombeirosPrimeira] = useState([
    { nome: 'Miguel Silva', divisa: bombeiro1Divisa, foto: miguel_silva },
  ])

  const [bombeirosSegunda, setBombeirosSegunda] = useState([
    { nome: 'Alfredo Serra', divisa: bombeiro2Divisa, foto: alfredo_serra },
    { nome: 'Tânia Serra', divisa: bombeiro2Divisa, foto: tania_serra },
  ])

  const [bombeirosTerceira, setBombeirosTerceira] = useState([
    { nome: 'Carlos Pires', divisa: bombeiro3Divisa, foto: carlos_pires },
    { nome: 'João Morgadinho', divisa: bombeiro3Divisa, foto: joao_morgadinho },
    { nome: 'Hugo Diogo', divisa: bombeiro3Divisa, foto: hugo_diogo },
    { nome: 'João Gonçalves', divisa: bombeiro3Divisa, foto: joao_gonçalves },
    { nome: 'Telmo Lobo', divisa: bombeiro3Divisa, foto: telmo_lobo },
    { nome: 'Ruben Serra', divisa: bombeiro3Divisa, foto: ruben_serra },
    { nome: 'Joaquim Dias', divisa: bombeiro3Divisa, foto: joaquim_dias },
    { nome: 'Carlos Ramos', divisa: bombeiro3Divisa, foto: carlos_ramos },
    { nome: 'Eduardo Marques', divisa: bombeiro3Divisa, foto: eduardo_marques },
    
  ])
  const [veiculos] = useState([
    { nome: 'VLCI02', foto: VLCI02, tipo: 'veiculos_ligeiro_de_combate_a_incendios' },
    { nome: 'VRCI13', foto: VRCI13, tipo: 'veiculos_rural_de_combate_a_incendios' },
    { nome: 'VRCI15', foto: VRCI15, tipo: 'veiculos_rural_de_combate_a_incendios' },
])

  return (
    <Seccoes nameSeccao={seccao} chefeSec={chefe} subChefeSec={subchefe} secBomb_1={bombeirosPrimeira} secBomb_2={bombeirosSegunda} secBomb_3={bombeirosTerceira} SecViaturas={veiculos} />
  )
}

export default Silvares