import React, { useState } from 'react'
import { Container, Row, Stack, Col } from 'react-bootstrap'
import image_dummy from '../../images/image_dummy_services.svg'
import { Link } from 'react-router-dom';

function Apresentacao() {
    const [imageSections, setImageSections] = useState([
        { image: image_dummy, section: 'Silvares', alt: 'alt 1', link_to: '/silvares' },
        { image: image_dummy, section: 'Três Povos', alt: 'alt 2', link_to: '/tresPovos' },
        { image: image_dummy, section: 'Soalheira', alt: 'alt 3', link_to: '/soalheira' }
    ])



    return (
        <Container fluid className='apresentacaoArea'>
            <Row>
                <Stack gap={3}>
                    <div>
                        <h1 className='h1 text-center mt-5 mb-5 weTitle'>Associação Humanitária de Bombeiros Voluntários de Fundão</h1>
                    </div>
                    <div className='weText mb-3'>
                        <p className='text-center'>
                            Bem-vindo à página da Associação Humanitária de Bombeiros Voluntários de Fundão. Temos como missão salvaguardar a vida humana e proteger propriedades em risco. 
                            Como um corpo de bombeiros voluntários, prestamos um serviço essencial para toda a comunidade, contando também com a sua contribuição.
                            {
                                //testefinal
                            }
                        </p>
                    </div>
                </Stack>
            </Row>

            <div>
                <h2 className='h2 text-center mb-5 areaText'>Secções</h2>
                <Row className='rowSections'>
                    {
                        imageSections.map((value, key) => {
                            return (
                                <Col className='sectionsArea mb-5' key={key} xs={6} md={4}>
                                    <Link to={value.link_to} style={{ textDecoration: "none", color: "white" }}><h3 className='h3 text-center'>{value.section}</h3></Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Container>
    )
}

export default Apresentacao