import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import axios from 'axios'
import { useForm } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';


function TabelaCorporacao() {
    const [listBombeiros, setListBombeiros] = useState([])
    const [listPosto] = useState([
        { 'id': 1, 'posto': 'Bombeiro de 1º' },
        { 'id': 2, 'posto': 'Bombeiro de 2ª' },
        { 'id': 3, 'posto': 'Bombeiro de 3ª' },
        { 'id': 4, 'posto': 'Chefe' },
        { 'id': 5, 'posto': 'Sub-Chefe' },
        { 'id': 6, 'posto': 'Quadro de Honra Bombeiro de 1ª' },
        { 'id': 7, 'posto': 'Quadro de Honra Bombeiro de 2ª' },
        { 'id': 8, 'posto': 'Quadro de Honra Bombeiro de 3ª' },
        { 'id': 9, 'posto': 'Quadro de Honra Chefe' },
        { 'id': 10, 'posto': 'Quadro de Honra Sub-Chefe' },
        { 'id': 11, 'posto': 'Quadro de Honra Oficial' },
        { 'id': 12, 'posto': 'Quadro de Comandante' },
        { 'id': 13, 'posto': 'Adjunto de Comando' },
        { 'id': 14, 'posto': '2º Comandante' },
        { 'id': 15, 'posto': 'Comandante' }]
    )
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [listSeccao] = useState([
        { 'id': 1, 'nome': 'Fundão' },
        { 'id': 2, 'nome': 'Silvares' },
        { 'id': 3, 'nome': 'Soalheira' },
        { 'id': 4, 'nome': 'Três Povos' },]

    );



    const columns = useMemo(() => [
        {
            accessorKey: 'nome_bombeiro',
            header: 'Nome',
        },

        {
            accessorKey: 'divisa_bombeiro',
            header: 'Posto',
        },
        {
            accessorKey: 'seccao_bombeiro',
            header: 'Secção',
        },
        {
            id: 'foto',
            header: 'Fotografia',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <img className='img-fluid img-thumbnail' src={row.original.image_bombeiro} alt='' width={'100px'} />
            )
        },
        {
            id: 'edita',
            header: 'Editar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <button className='btn btn-outline-secondary btnNovoSocio'>Editar</button>
            )
        },
        {
            id: 'remover',
            header: 'Remover',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <button className='btn btn-outline-secondary btnNovoSocio' onClick={() => deleteBombeiro(row.original.id_bombeiro)}>Remover</button>
            )
        },
    ], [])


    const validationSchema = Yup.object().shape({
        num_bombeiro: Yup.string()
            .required('Campo vazio')
            .matches(/^[0-9]+$/, 'Só números')
            .test('is-positive-integer', 'Número não inteiro positivo', value => {
                if (!value) return true;
                const intValue = parseInt(value);
                return intValue > 0 && Number.isInteger(intValue);
            }).test('verifica_bombeiro', 'Bombeiro já existente', async (value) => {
                if (!value) { return true }


                const response = await axios.get(`/corporacao/check_num`, { params: { num_bombeiro: value } })

                return !response.data.exists

            }),
        nome_bombeiro: Yup.string().required("Campo vazio").matches(/^[^_\-\/*]+$/, "Caracteres inválidos"),
        imagem_bombeiro: Yup
            .mixed()
            .test('fileType', 'Apenas imagens são permitidas!', value => {

                if (value) {
                    return value[0] && ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value[0].type)
                }
                return true;
            })
            .test('fileSize', 'A imagem deve ter no máximo 10MB!', value => {
                if (value) {
                    return value[0] && value[0].size <= 10000000;
                }
                return true;
            }).required('Campo Vazio'),
        id_seccao: Yup.string().required('Campo vazio'),
        id_divisa: Yup.string().required('Campo vazio'),
    })
    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }

    const {register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })

    useEffect(() => {
        bombeiroList()
    }, [])


    const bombeiroList = () => {
        axios.get('/bombeiros/all_bombeiros')
            .then((response) => {
                console.log(response)
                setListBombeiros(response.data.bombeiro)
            })
       
    }


    /*
        const seccaoList = () => {
            axios.get('/corporacao/get_seccao')
                .then((response) => {
                    setListSeccao(response.data.seccao)
    
                })
                .catch((err) => {
                    console.error(err)
    
                })
        }
    
    
        const postoList = () => {
            axios.get('/corporacao/get_divisa')
                .then((response) => {
                    setListPosto(response.data.divisa)
    
                })
                .catch((err) => {
                    console.error(err)
    
                })
        }
        */

    const deleteBombeiro = (id) => {
        axios.post(`/bombeiros/delete_bombeiro/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    openModal('Registo apagado com sucesso')
                    bombeiroList()
                }
            }).catch((error) => {
                console.error(error);
                openModal('Por favor contacte o suporte')
            })
    }

    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('num_bombeiro', data.num_bombeiro)
        formData.append('nome_bombeiro', data.nome_bombeiro)
        formData.append('imagem_bombeiro', data.imagem_bombeiro[0])
        formData.append('id_seccao', data.id_seccao)
        formData.append('id_divisa', data.id_divisa)

        axios.post('/bombeiros/new_bombeiro', formData)
            .then((response) => {
                openModal('Bombeiro Inserido')
                bombeiroList()
            }).catch(
                (err) => {
                    console.error(err)
                    openModal('Por favor contacte o suporte')
                }
            )
        reset()

    }
    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Sócios</h1>
            <hr className='lineSection mb-5' />
            <br />
            {/*imageSocios && (<img src={imageSocios} alt="dd" className='img-fluid' />)*/}
            <div className='container'>
                <form className='mt-5 mb-5' onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data'>
                    <div className='row mb-3'>
                        <div className="col">
                            <label htmlFor="num_bombeiro" className="form-label">Número</label>
                            <input type="text" className="form-control" autoComplete='off' name='num_bombeiro' {...register('num_bombeiro')} />
                            <p className='text-danger'>{errors.num_bombeiro?.message}</p>
                        </div>
                        <div className="col">
                            <label htmlFor="nome_bombeiro" className="form-label">Nome</label>
                            <input type="text" className="form-control" autoComplete='off' name='nome_bombeiro' {...register('nome_bombeiro')} />
                            <p className='text-danger'>{errors.nome_bombeiro?.message}</p>
                        </div>
                        <div className="col">
                            <label htmlFor="imagem_bombeiro" className="form-label">Imagem</label>
                            <input type="file" className="form-control" name='imagem_bombeiro' {...register('imagem_bombeiro')} />
                            <p className='text-danger'>{errors.imagem_bombeiro?.message}</p>
                        </div>

                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <label htmlFor="id_seccao" className="form-label">Secção</label>
                            <select className='form-select' {...register('id_seccao')} name='id_seccao'>
                                <option value="">Selecione a Secção</option>
                                {
                                    listSeccao.map((valueList) => {
                                        return (
                                            <option value={valueList.id}>
                                                {valueList.nome}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <p className='text-danger'>{errors.id_seccao?.message}</p>
                        </div>
                        <div className='col'>
                            <label htmlFor="id_divisa" className="form-label">Posto</label>
                            <select className='form-select' {...register('id_divisa')} name='id_divisa'>
                                <option value="">Selecione o Posto</option>
                                {
                                    listPosto.map((posto) => {
                                        return (
                                            <option value={posto.id}>
                                                {posto.posto}
                                            </option>
                                        )
                                    })

                                }
                            </select>
                            <p className='text-danger'>{errors.id_divisa?.message}</p>
                        </div>
                        <div className='col'>

                            <button type='submit' className='btn-lg recrutamento_button mt-4'>Inserir</button>
                            <Modal
                                title={
                                    <div>
                                        <h4 className='text-center fw-bold text-danger'>Inserção de Bombeiro</h4>
                                        <hr className='text-danger' />
                                    </div>
                                }
                                open={isModalVisible}
                                onOk={() => {
                                    setIsModalVisible(false)

                                }}
                                cancelButtonProps={{ style: { display: 'none' } }}
                            >
                                <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                            </Modal>
                        </div>
                    </div>
                </form>
            </div>
            <MaterialReactTable columns={columns} data={listBombeiros}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing
                enableColumnActions={false}

                muiTableHeadCellProps={{
                    style: {
                        color: 'white',
                        fontSize: 28

                    }
                }}
                muiTableHeadRowProps={{
                    style: {
                        backgroundColor: 'orange'
                    }
                }}
                muiTopToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
                muiBottomToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
            />
            <br />
        </div>
    )
}

export default TabelaCorporacao