import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import InserirNoticia from './InserirNoticia'
import TabelaNoticia from './TabelaNoticia'
import Footer from '../principal/Footer'
import { motion } from 'framer-motion'

function AdminNoticia() {

    const [menuSelected, setMenuSelected] = useState(1)

    return (
        <div className='container-fluid'>

            <h1 className='titleSection mt-4' style={{ marginLeft: '65px' }}>Área de Notícias</h1>
            <hr className='lineSection mb-3' />
            <div className='container-fluid'>
                <br />

                <ul className="nav justify-content-center mb-5">
                    <li className="nav-item">
                        <h4 className='h4 nav-link admin_links' onClick={() => { setMenuSelected(1) }} style={{ color: menuSelected == 1 ? 'red' : 'black' }}>Inserir Notícia</h4>
                    </li>
                    <li className="nav-item">
                        <h4 className='h4 nav-link admin_links' onClick={() => { setMenuSelected(2) }} style={{ color: menuSelected == 2 ? 'red' : 'black' }}>Ver Notícias</h4>
                    </li>
                </ul><br />
                {menuSelected == 1 ? <InserirNoticia /> : <TabelaNoticia />}
            </div>
        </div>

    )
}

export default AdminNoticia