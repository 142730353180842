import React, { useState, useEffect, CSSProperties } from 'react'
import {
    Editor, createButton, EditorProvider, Toolbar, BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    Separator
} from 'react-simple-wysiwyg'
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { Modal } from 'antd';
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios'
import FadeLoader from "react-spinners/FadeLoader";
import { motion } from 'framer-motion'

function AlteraFormacao() {
    const [loading, setLoading] = useState(true)
    const id_formacao = useParams()['id_formacao']
    const [formacao, setFormacao] = useState({})
    const history = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const BtnAlignTextRight = createButton('Align Right', <BsTextRight />, 'justifyRight')
    const BtnAlignTextCenter = createButton('Align Center', <BsTextCenter />, 'justifyCenter')
    const BtnAlignTextLeft = createButton('Align Left', <BsTextLeft />, 'justifyLeft')
    const [limite_formacao, setLimite_formacao] = useState({ data_limite_inscricao: '' })
    const [data_formacao, setData_formacao] = useState({ data_realizacao: '' })
    const validationSchema = Yup.object().shape({
        designacao: Yup.string().required("Campo Vazio"),
        enquadramento: Yup.string().required("Campo Vazio"),
        objetivos: Yup.string().required("Campo Vazio"),
        programa: Yup.string().required("Campo Vazio"),
        imagem_formacao: Yup.mixed().test('fileType', 'Apenas imagens são permitidas!', value => {

            if (value.length == 0) {
                return true;
            }
            return (
                value[0] && ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value[0].type)
            )
        })
            .test('fileSize', 'A imagem deve ter no máximo 10MB!', value => {
                if (value.length == 0) {
                    return true;
                }
                return (value[0] && value[0].size <= 10000000)
            }),

        duracao: Yup.string().required("Campo Vazio"),
        data_realizacao: Yup.date().required("Campo Vazio").typeError('Inválido').min(Yup.ref('data_realizacao'), 'Data inválida'),
        local_realizacao: Yup.string().required("Campo Vazio"),
        data_limite_inscricao: Yup.date().required("Campo Vazio").min(Yup.ref('data_limite_inscricao'), 'Data inválida').typeError('Inválido')
            .max(Yup.ref('data_realizacao'), 'Data deve ser menor ou igual à data de realização'),
    })

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }
    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('designacao', data.designacao)
        formData.append('enquadramento', data.enquadramento)
        formData.append('objetivos', data.objetivos)
        formData.append('programa', data.programa)
        formData.append('duracao', data.duracao)
        formData.append('data_realizacao', data.data_realizacao)
        formData.append('local_realizacao', data.local_realizacao)
        formData.append('data_limite_inscricao', data.data_limite_inscricao)
        formData.append('imagem_formacao', data.imagem_formacao[0])


        axios.post(`/formacao/update_formacao/${id_formacao}`, formData)
            .then((response) => {
                console.log(response)
            }).catch((err) => {
                console.error(err)

                if (err.response) {
                    console.error('Status do erro:', err.response.status);
                    console.error('Dados do erro:', err.response.data);
                } else if (err.request) {
                    console.error('Requisição não obteve resposta do servidor.');
                } else {
                    console.error('Erro ao processar a solicitação.', err.message);
                }
            })
        reset()
        openModal('Formação alterada com sucesso')

    }
    function castDate(myDate) {
        const the_date = myDate.split('/')
        const d_formacao = `${the_date[2]}-${the_date[1]}-${the_date[0]}`
        return d_formacao
    }
    const { control, register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })


    useEffect(() => {
        axios.get(`/formacao/selected_formacao/${id_formacao}`)
            .then((response) => {
                setFormacao(response.data.form[0])
                setValue('designacao', response.data.form[0].designacao)
                setValue('programa', response.data.form[0].programa)
                setValue('objetivos', response.data.form[0].objetivos)
                setValue('enquadramento', response.data.form[0].enquadramento)
                setValue('duracao', response.data.form[0].duracao)
                setValue('local_realizacao', response.data.form[0].local_realizacao)


                const start_date = castDate(response.data.form[0].data_realizacao)
                const end_date = castDate(response.data.form[0].data_limite_inscricao)
                setData_formacao((prev) => { return { ...prev, data_realizacao: start_date } })
                setLimite_formacao((prev) => { return { ...prev, data_limite_inscricao: end_date } })
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className='container-fluid'>
            <div className='container mt-5'>
                <Link className='float-end text-decoration-none fs-3' to={'/administracao/lista_formacoes'}>Voltar</Link>
                <motion.h1 className='h1 titleSection'
                    initial={{
                        opacity: 0,
                        x: '-100vw'
                    }}
                    transition={{ delay: 2.5, duration: 2 }}
                    animate={{
                        x: 0,
                        opacity: 1
                    }}
                >Editar Formação</motion.h1>
                <hr className='lineSection mb-5' />
            </div>
            {
                loading ?
                    <div className='loading-page'>
                        <FadeLoader
                            color={"red"}
                            loading={loading}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <div className='container mt-5'>
                        <div className='container containerImage p-1'>
                            <img src={formacao.imagem_formacao} alt='noticia' className='img-fluid imgNoticia' />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data' className='mt-5'>
                            <div className='row'>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="designacao" >Designação</label>
                                        <input type="text" className="form-control" autoComplete='off' defaultValue={formacao.designacao} name='designacao' {...register('designacao')} />
                                        <p className='text-danger'>{errors.designacao?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="data_realizacao" >Data Realização</label>
                                        <input type="date" className="form-control" autoComplete='off' name='data_realizacao' defaultValue={data_formacao.data_realizacao}  {...register('data_realizacao')} />
                                        <p className='text-danger'>{errors.data_realizacao?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="imagem_formacao" >Imagem Formação</label>
                                        <input type="file" className="form-control" name='imagem_formacao' {...register('imagem_formacao')} />
                                        <p className='text-danger'>{errors.imagem_formacao?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="duracao" >Duração</label>
                                        <input type="text" className="form-control" autoComplete='off' defaultValue={formacao.duracao} name='duracao' {...register('duracao')} />
                                        <p className='text-danger'>{errors.duracao?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="local_realizacao" >Local Formação</label>
                                        <input type="text" className="form-control" autoComplete='off' name='local_realizacao' {...register('local_realizacao')} />
                                        <p className='text-danger'>{errors.local_realizacao?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-sx'>
                                    <div className="form-group">
                                        <label htmlFor="data_limite_inscricao">Data Limite de Inscrição</label>
                                        <input type="date" className="form-control" autoComplete='off' name='data_limite_inscricao' defaultValue={limite_formacao.data_limite_inscricao} {...register('data_limite_inscricao')} />
                                        <p className='text-danger'>{errors.data_limite_inscricao?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="enquadramento">Enquadramento</label>
                                <EditorProvider>
                                    <Controller
                                        name='enquadramento'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' defaultValue={formacao.enquadramento} containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        <BtnItalic />
                                                        <BtnUnderline />
                                                        <Separator />
                                                        <BtnAlignTextLeft />
                                                        <BtnAlignTextCenter />
                                                        <BtnAlignTextRight />
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <Separator />
                                                        <BtnStyles />
                                                    </Toolbar>
                                                </Editor>
                                                {errors.enquadramento && (
                                                    <p className='text-danger'>{errors.enquadramento.message}</p>
                                                )}
                                            </div>
                                        )}
                                    >
                                    </Controller>
                                </EditorProvider>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="objetivos">Objetivos</label>
                                <EditorProvider>
                                    <Controller
                                        name='objetivos'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' defaultValue={formacao.objetivos} containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        <BtnItalic />
                                                        <BtnUnderline />
                                                        <Separator />
                                                        <BtnAlignTextLeft />
                                                        <BtnAlignTextCenter />
                                                        <BtnAlignTextRight />
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <Separator />
                                                        <BtnStyles />
                                                    </Toolbar>
                                                </Editor>
                                                {errors.objetivos && (
                                                    <p className='text-danger'>{errors.objetivos.message}</p>
                                                )}
                                            </div>
                                        )}
                                    >
                                    </Controller>
                                </EditorProvider>
                            </div>
                            <div className='form-group'>
                                <label htmlFor="programa">Programa</label>
                                <EditorProvider>
                                    <Controller
                                        name='programa'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div>
                                                <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' defaultValue={formacao.programa} containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        <BtnItalic />
                                                        <BtnUnderline />
                                                        <Separator />
                                                        <BtnAlignTextLeft />
                                                        <BtnAlignTextCenter />
                                                        <BtnAlignTextRight />
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <Separator />
                                                        <BtnStyles />
                                                    </Toolbar>
                                                </Editor>
                                                {errors.programa && (
                                                    <p className='text-danger'>{errors.programa.message}</p>
                                                )}
                                            </div>
                                        )}
                                    >
                                    </Controller>
                                </EditorProvider>
                            </div>
                            <br />
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-outline-secondary btn-lg btnNovoSocio me-md-2" type="submit">Enviar</button>
                                <button className="btn btn-outline-secondary btn-lg btnNovoSocio" type="reset">Limpar</button>
                            </div>
                            <Modal
                                title={
                                    <div>
                                        <h4 className='text-center fw-bold text-danger'>Alteração Formação</h4>
                                        <hr className='text-danger' />
                                    </div>
                                }
                                open={isModalVisible}
                                onOk={() => {
                                    setIsModalVisible(false)
                                    history('/administracao/lista_formacoes')
                                }}
                                cancelButtonProps={{ style: { display: 'none' } }}
                            >
                                <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                            </Modal>
                        </form>
                    </div>
            }

            <div className='clearfix mt-5'></div>
        </div >
    )
}

export default AlteraFormacao
