import React from 'react'
import logo from '../../images/logo_image.png'


function AdminPanel() {

    return (
        <div className='container-fluid'>
            <h1 className='h1 text-center mt-5 mb-5'>Área de Administração dos Bombeiros Voluntários do Fundão</h1>
            <img src={logo} className='img-fluid mx-auto d-block mb-5' width="30%" alt='' />
        </div>
    )
}

export default AdminPanel