import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import { Modal } from 'antd';

import logoBombeiros from '../../images/logo_image.png'
import image_dummy from '../../images/image_dummy_services.svg'
import TestemunhosIntro from '../principal/TestemunhosIntro'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}

function Recrutamento() {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const [imageSections, setImageSections] = useState([
        { image: image_dummy, section: 'section 1', alt: 'alt 1' },
        { image: image_dummy, section: 'section 2', alt: 'alt 2' },
        { image: image_dummy, section: 'section 3', alt: 'alt 3' }
    ])

    const [escolaridade, setEscolaridade] = useState([{ tipo: "primário" }, { tipo: "básico" }, { tipo: "secundário" }, { tipo: "universitário" }])


    const validationSchema = Yup.object().shape({
        nome: Yup.string().required("Campo Vazio"),
        morada: Yup.string().required("Campo Vazio"),
        localidade: Yup.string().required("Campo Vazio"),
        concelho: Yup.string().required("Campo Vazio"),
        cp: Yup.string().required("Campo Vazio").matches(/^[0-9]{4}-[0-9]{3}$/, 'O código postal deve ter o formato 0000-000'),
        email: Yup.string().required('Campo Vazio').email('Email inválido').test('verifica_email', 'Email já existente', async (value) => {
            if (!value) { return true }


            const response = await axios.get(`/recrutamento/check_email`, { params: { email: value } })

            return !response.data.exists

        }),
        cc: Yup.string().required('Campo vazio').matches(/^\d{8}$/, 'Número de Cartão de Cidadão inválido')
            .test('verifica_cc', 'CC já existente', async (value) => {
                if (!value) { return true }

                const response = await axios.get(`/recrutamento/check_cc`, { params: { cc: value } })
                return !response.data.exists

            }),
        cv: Yup.mixed().test('fileType', 'Apenas em formato PDF', value => {

            if (value) {
                return value[0] && value[0].type === 'application/pdf';
            }
            return true;
        })
            .test('fileSize', 'O PDF deve ter no máximo 10MB', value => {
                if (value) {
                    return value[0] && value[0].size <= 10000000;
                }
                return true;
            }).required('Campo Vazio'),

        grupoSanguinio: Yup.string().required("Campo Vazio"),
        nascimento: Yup.date().required("Campo Vazio").typeError('Inválido').min(Yup.ref('nascimento'), 'Data inválida'),
        habilitacoes: Yup.string().required("Campo Vazio"),
        cartaConducao: Yup.string().required("Campo Vazio"),
        situacaoProfissional: Yup.string().required("Campo Vazio"),
        contacto: Yup.string().matches(/^(9[1236][0-9]{7})|(2\d{8})$/, 'Insira um número de telefone válido').required("Campo Vazio"),
        estadoCivil: Yup.string().required("Campo Vazio"),
        conhecimento: Yup.boolean().oneOf([true], 'Tem que concordar com os termos e condições').required("Tem que concordar com os termos e condições")
    })


    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }

    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('nome', data.nome)
        formData.append('morada', data.morada)
        formData.append('cp', data.cp)
        formData.append('localidade', data.localidade)
        formData.append('concelho', data.concelho)
        formData.append('cc', data.cc)
        formData.append('nascimento', data.nascimento)
        formData.append('estadoCivil', data.estadoCivil)
        formData.append('grupoSanguinio', data.grupoSanguinio)
        formData.append('habilitacoes', data.habilitacoes)
        formData.append('cartaConducao', data.cartaConducao)
        formData.append('situacaoProfissional', data.situacaoProfissional)
        formData.append('contacto', data.contacto)
        formData.append('email', data.email)
        formData.append('cv', data.cv[0])


        axios.post('/recrutamento/novo_recrutamento', formData)
            .then((response) => {
            }).catch((err) => {
                console.error(err)

                if (err.response) {
                    console.error('Status do erro:', err.response.status);
                    console.error('Dados do erro:', err.response.data);
                } else if (err.request) {
                    console.error('Requisição não obteve resposta do servidor.');
                } else {
                    console.error('Erro ao processar a solicitação.', err.message);
                }
            })
            reset()
        openModal('Inscrição realizada com sucesso')
        
    }


    const { watch, register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerRecrutamento P-5 mb-5'>
                <h1 className='h1 text-center'>Recrutamento</h1>
            </div>
            <div className='container'>
                <h2 className='h2 mt-5 titleSection'>Junta-te a Nós!</h2>
                <hr className='mb-5 lineSection' />
            </div>
            <div className='container'>
                <p className='mb-5'>
                    Os Bombeiros Voluntários do Fundão abriram oficialmente as inscrições para recruta de bombeiros
                    voluntários na sua corporação. As inscrições estão abertas através deste formulário, ou presencialmente no
                    Quartel dos Bombeiros Voluntários do Fundão.
                    <br />
                    O objetivo desta campanha de integração de recrutas é dar continuidade à tradição de formação de novos bombeiros,
                    tal como cadetes e infantes, que possam vir a assegurar a excelência do serviço público prestado pela corporação.
                    <br />
                    Podem aderir pessoas entre os 17 e os 44 anos, com escolaridade mínima obrigatória, com capacidade física e
                    psíquica para a posição, e que sejam corajosas, altruístas, responsáveis, com bom espírito de equipa,
                    e prontas para aceitar um novo desafio profissional e pessoal.
                </p>
            </div>
            {/**
             * <div className='container-fluid g-0 mb-5'>
                <TestemunhosIntro />
            </div>
             */}
            <div className='container'>
                <h3 className='h3 titleSection mb-3'>Direitos e vantagens para quem aceita o desafio</h3>
                <div className='container'>
                    <ul className='list-group list-group-flush'>
                        <li className='list-group-item list-group-item-secondary'>Reembolso de propinas e taxas de inscrição</li>
                        <li className='list-group-item list-group-item-light'>Frequência de ações de formação em socorro e emergência médica</li>
                        <li className='list-group-item list-group-item-light'>Isenção de pagamentos no Serviço Nacional de Saúde</li>
                        <li className='list-group-item list-group-item-light'>Aumento do tempo de serviço para efeitos de aposentação, e outros</li>
                        <li className='list-group-item list-group-item-light'>Reembolso de 50 % das despesas suportadas com berçários, creches e estabelecimentos da educação pré-escolar públicos</li>
                        <li className='list-group-item list-group-item-light'>Redução de 50 % em todas as taxas e emolumentos cobradas pelos organismos tutelados pelo membro do Governo responsável pela área da administração interna</li>
                        <li className='list-group-item list-group-item-light'>Acesso aos refeitórios da administração central e local nas condições dadas aos trabalhadores em funções públicas e entrada gratuita nos museus e monumentos nacionais</li>
                        <li className='list-group-item list-group-item-secondary'>Benefícios de âmbito municipal ao abrigo de regulamento próprio</li>
                    </ul>
                </div>
            </div>
            <div className='container mb-5 mt-5'>
                <form className='mb-5' onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data'>
                    <div className="form-floating mb-3 mt-3">
                        <input type="text" className="form-control" autoComplete='off' name="nome"  {...register('nome')} />
                        <label htmlFor="nome">Nome</label>
                        <p className='text-danger'>{errors.nome?.message}</p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="morada"  {...register('morada')} />
                                <label htmlFor="morada_recruta">Morada</label>
                                <p className='text-danger'>{errors.morada?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name='cp' {...register('cp')} />
                                <label htmlFor="codigo_recruta">Código Postal</label>
                                <p className='text-danger'>{errors.cp?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name='localidade' {...register('localidade')} />
                                <label htmlFor="localidade">Localidade</label>
                                <p className='text-danger'>{errors.localidade?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="concelho" {...register('concelho')} />
                                <label htmlFor="localidade">Concelho</label>
                                <p className='text-danger'>{errors.concelho?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="cc"  {...register('cc')} />
                                <label htmlFor="cc">CC</label>
                                <p className='text-danger'>{errors.cc?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="date" className="form-control" autoComplete='off' name="nascimento"  {...register('nascimento')} />
                                <label htmlFor="nascimento">Data Nascimento</label>
                                <p className='text-danger'>{errors.nascimento?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <select className="form-select"  {...register('estadoCivil')}>
                                    <option value="">Selecione o estado</option>
                                    <option value="Solteiro">Solteiro</option>
                                    <option value="Divorciado">Divorciado</option>
                                    <option value="Casado">Casado</option>
                                    <option value="Viuvo">Viuvo</option>
                                </select>
                                <label htmlFor="floatingSelect">Estado Civil</label>
                                <p className='text-danger'>{errors.estadoCivil?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="grupoSanguinio" {...register('grupoSanguinio')} />
                                <label htmlFor="grupoSanguinio">Grupo Sanguinio</label>
                                <p className='text-danger'>{errors.grupoSanguinio?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <select className="form-select" id="habilitacoes" {...register('habilitacoes')}>
                                    <option value="">Selecione a habilitação</option>
                                    <option value="Básico">Básico</option>
                                    <option value="Secundário">Secundário</option>
                                    <option value="Profissional">Profissional</option>
                                    <option value="Tecnológico">Tecnológico</option>
                                    <option value="Licenciatura">Licenciatura</option>
                                    <option value="Mestrado">Mestrado</option>
                                    <option value="Doutoramento">Doutoramento</option>
                                </select>
                                <label htmlFor="floatingSelect">Habilitações</label>
                                <p className='text-danger'>{errors.habilitacoes?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <select className="form-select"  {...register('cartaConducao')}>
                                    <option value="">Selecione </option>
                                    <option value="não">Não</option>
                                    <option value="ligeiros">Ligeiros</option>
                                    <option value="pesados">Pesados</option>
                                </select>
                                <label htmlFor="cartaConducao">Carta de Condução</label>
                                <p className='text-danger'>{errors.cartaConducao?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <select className="form-select" {...register('situacaoProfissional')}>
                                    <option value="">Selecione a sua situação</option>
                                    <option value="Desempregado">Desempregado</option>
                                    <option value="Estudante">Estudante</option>
                                    <option value="Empregado">Empregado por conta de outrém</option>
                                    <option value="Empresario">Empregado por conta própria</option>
                                    <option value="Reformado">Reformado</option>
                                </select>
                                <label htmlFor="situacaoProfissional">Situação Profissional</label>
                                <p className='text-danger'>{errors.situacaoProfissional?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="contacto" maxLength="9" {...register('contacto')} />
                                <label htmlFor="contacto">Contacto</label>
                                <p className='text-danger'>{errors.contacto?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="email" {...register('email')} />
                                <label htmlFor="email">Email</label>
                                <p className='text-danger'>{errors.email?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group mt-3 mb-3">
                                <label htmlFor="cv" className="form-label">Insira o Curriculo</label>
                                <input className="form-control form-control" type="file" {...register('cv')} />
                                <p className='text-danger'>{errors.cv?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-8'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" {...register('conhecimento')} />
                                <label className="form-check-label" htmlFor="conhecimento">
                                    Autorizo recolher e tratar os meus dados pessoais para efeitos de contactos posteriores para
                                    divulgação de eventos e tratamento de questões relacionadas com o meu estatuto de associado/a.
                                    Tenho consciência de que estes dados serão apenas para uso exclusivo da Associação e não serão
                                    facultados a terceiros,seja por que motivo for, sem a minha autorização expressa nesse sentido.
                                </label>
                                <p className='text-danger'>{errors.conhecimento?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-grid gap-2">
                        <button className="btn recrutamento_button" type="submit">Enviar</button>
                        <button className="btn recrutamento_button" type="reset">Limpar</button>
                    </div>
                    <Modal
                        title={
                            <div>
                                <h4 className='text-center fw-bold text-danger'>Inscrição Recrutamento</h4>
                                <hr className='text-danger'/>
                            </div>
                        }
                        open={isModalVisible}
                        onOk={() => {
                            setIsModalVisible(false)
                        }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                    </Modal>
                </form>
            </div>
        </motion.div >
    )
}

export default Recrutamento