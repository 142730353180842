import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Typography } from '@mui/material';
import axios from 'axios'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { fichaFormando } from '../functions/FunctionsFront';
import CryptoJS from 'crypto-js'
import { Modal } from 'antd';


function TabelaFormacao() {

    const [listFormacoes, setListFormacoes] = useState([])
    const [listFormandos, setListFormandos] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    let theID = ''
    const history = useNavigate()
    const encrptID = (id) => {

        let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()

        while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
            encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
        }

        return encryptedId

    }

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'designacao',
            header: 'Designação',
        },
        {
            accessorKey: 'duracao',
            header: 'Duração',
        },
        {
            accessorKey: 'data_realizacao',
            header: 'Data Realização',
        },
        {
            accessorKey: 'data_limite_inscricao',
            header: 'Data Limite',
        },
        {
            accessorKey: 'local_realizacao',
            header: 'Local',
        },
        {
            id: 'verDetalhe',
            header: 'Detalhe',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <Link className='btn btn-outline-secondary btnNovoSocio' to={`/administracao/detalhe_formacao/${theID = encrptID(row.original.id_formacao)}`}>Ver</Link>
            )
        },
        {
            id: 'edita',
            header: 'Editar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <Link className='btn btn-outline-secondary btnNovoSocio' to={`/administracao/altera_formacao/${theID = encrptID(row.original.id_formacao)}`}>Editar</Link>
            )
        },
        {
            id: 'apaga',
            header: 'Apagar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <button className='btn btn-outline-secondary btnNovoSocio' onClick={() => deleteFormacao(row.original.id_formacao)}>Apagar</button>
            )
        }
    ], [])

    useEffect(() => {
        formacoesAdmin();
    }, [])

    const formacoesAdmin = () => {
        axios.get('/formacao/all_formacoes_ad')
            .then((response) => {
                setListFormacoes(response.data.formacoes)

            })
            .catch((err) => {
                console.error(err)
            })
        seeFormandos()
    }
    function seeFormandos() {
        axios.get(`/formacao/all_formandos`)
            .then((response) => {
                setListFormandos(response.data.formandos)
            })
            .catch((err) => console.log(err))
    }

    const deleteFormacao = (id) => {
        axios.post(`/formacao/delete_formacao/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    openModal('Formação apagada com sucesso')
                    formacoesAdmin();
                }
            }).catch((error) => {
                console.error(error);
                openModal('Por favor contacte o suporte')
            })
    }
    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Formações</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'></div>
            <div className='container-fluid'>
                <MaterialReactTable columns={columns} data={listFormacoes}
                    enableColumnFilters={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnResizing
                    enableColumnActions={false}
                    enableSelectAll={false}
                    muiTableHeadCellProps={{
                        style: {
                            color: 'white',
                            fontSize: 24

                        }
                    }}
                    muiTableHeadRowProps={{
                        style: {
                            backgroundColor: 'orange'
                        }
                    }}
                    muiTopToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                    muiBottomToolbarProps={{
                        style: {
                            backgroundColor: 'orangered'
                        }
                    }}
                    renderDetailPanel={({ row }) => {
                        return (
                            <Box className='container'>


                                <table className='table table-hover align-middle'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Nome</th>
                                            <th>Contato</th>
                                            <th>Email</th>
                                            <th>Exportar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listFormandos.map((value, key) => {
                                                if (value.id_formacao == row.original.id_formacao) {
                                                    return (

                                                        <tr key={key} className='text-center'>
                                                            <td>{value.nome}</td>
                                                            <td>{value.contacto}</td>
                                                            <td>{value.email}</td>
                                                            <td>
                                                                <button className='btn btn-outline-secondary btnNovoSocio' onClick={(e) => fichaFormando(value.nif, value.nome, value.nascimento, value.cc, value.sexo, value.validade_cc, value.habilitacao, value.nacionalidade, value.naturalidade, value.morada, value.cp, value.localidade, value.concelho, value.contacto, value.email, value.situacao_profissional, value.nome_empresa, value.morada_empresa, value.localidade_empresa, value.cp_empresa, value.concelho_empresa, value.profissao)}>PDF</button>
                                                            </td>
                                                        </tr>

                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Box>
                        )
                    }}
                />
            </div>
            <br /><br />
            <Modal
                title={
                    <div>
                        <h4 className='text-center fw-bold text-danger'>Alteração Formação</h4>
                        <hr className='text-danger' />
                    </div>
                }
                open={isModalVisible}
                onOk={() => {
                    setIsModalVisible(false)
                    
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
            </Modal>
        </div>
    )
}

export default TabelaFormacao