import React from 'react'
import logo from '../../images/logo_image.png'
import { Link } from 'react-router-dom';
import useAuth from '../../auth/useAuth';

function NavbarMenu({ isAdmin }) {

    const { auth } = useAuth()

    const adminMenu = (funcao) => {

        if (funcao == 'formacao') {
            return (
                <li className="nav-item dropdown">
                    <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Formação</a>
                    <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                        <li>
                            <Link to="/administracao/nova_formacao" className="dropdown-item" >Inserir Formação</Link>
                        </li>
                        <li>
                            <Link to="/administracao/lista_formacoes" className="dropdown-item" >Ver Formações</Link>
                        </li>
                    </ul>
                </li>
            )
        }
        else if (funcao == 'comunicacao') {
            return (
                <div className='row'>
                    <div className='col'>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Notícias</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                                <li>
                                    <Link to="/administracao/nova_noticia" className="dropdown-item" >Inserir Notícia</Link>
                                </li>
                                <li>
                                    <Link to="/administracao/lista_noticias" className="dropdown-item" >Ver Notícias</Link>
                                </li>
                            </ul>
                        </li>
                    </div>
                    <div className='col'>
                        <li className="nav-item">
                            <Link to="/administracao/lista_mensagens" className="nav-link" >Mensagens</Link>
                        </li>
                    </div>
                </div>
            )
        }
        else if (funcao == 'recrutamento') {
            return (
                <div className='row'>
                    <div className='col'>
                        <li className="nav-item">
                            <Link to="/administracao/lista_socios" className="nav-link" >Sócios</Link>
                        </li>
                    </div>
                    <div className='col'>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Bombeiros</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                                <li>
                                    <Link to="/administracao/lista_recrutamento" className="dropdown-item" >Ver Recrutamento</Link>
                                </li>
                                <li>
                                    <Link to="/administracao/lista_cadetes" className="dropdown-item" >Ver Cadetes</Link>
                                </li>
                            </ul>
                        </li>
                    </div>
                </div>
            )
        }
        else if (funcao == 'admin') {
            return (
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Notícias</a>
                        <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                            <li>
                                <Link to="/administracao/nova_noticia" className="dropdown-item" >Inserir Notícia</Link>
                            </li>
                            <li>
                                <Link to="/administracao/lista_noticias" className="dropdown-item" >Ver Notícias</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Formação</a>
                        <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                            <li>
                                <Link to="/administracao/nova_formacao" className="dropdown-item" >Inserir Formação</Link>
                            </li>
                            <li>
                                <Link to="/administracao/lista_formacoes" className="dropdown-item" >Ver Formações</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link to="/administracao/lista_socios" className="nav-link" >Sócios</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/administracao/corporacao" className="nav-link" >Corporação</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/administracao/lista_mensagens" className="nav-link" >Mensagens</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Bombeiros</a>
                        <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                            <li>
                                <Link to="/administracao/lista_recrutamento" className="dropdown-item" >Ver Recrutamento</Link>
                            </li>
                            <li>
                                <Link to="/administracao/lista_cadetes" className="dropdown-item" >Ver Cadetes</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            )
        }
    }
    return (

        <div className="navbar navbar-expand-lg" >
            <div className="container">
                {
                    isAdmin == 0 ? <Link to="/" className="navbar-brand">
                        <img src={logo} alt="logo" height={'70px'} />
                    </Link> : ''
                }
                <button className="navbar-light navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    {
                        isAdmin == 0 ? <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" >Inicio</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Associação</a>
                                <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                                    <li>
                                        <Link to="/mensagem_presidente" className="dropdown-item" >Mensagem do Presidente</Link>
                                    </li>
                                    <li>
                                        <Link to="/orgaos_sociais" className="dropdown-item" >Orgãos Sociais</Link>
                                    </li>
                                    <li>
                                        <Link to="/estatutos" className="dropdown-item" >Estatutos</Link>
                                    </li>
                                    <li>
                                        <Link to="/noticias" className="dropdown-item" >Notícias</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#" id="navbarAssociao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Bombeiros</a>
                                <ul className="dropdown-menu" aria-labelledby="navbarAssociao">
                                    <li>
                                        <Link to="/mensagem_comandante" className="dropdown-item" >Mensagem do Comandante</Link>
                                    </li>
                                    <li>
                                        <Link to="/historia" className="dropdown-item" >História</Link>
                                    </li>
                                    <li>
                                        <Link to="/missao" className="dropdown-item" >Missão</Link>
                                    </li>
                                    <li>
                                        <Link to="/corpo_ativo" className="dropdown-item" >Corpo Activo</Link>
                                    </li>
                                    <li>
                                        <Link to="/quadro_honra" className="dropdown-item" >Quadro de Honra</Link>
                                    </li>
                                    <li>
                                        <Link to="/viaturas" className="dropdown-item" >Viaturas</Link>
                                    </li>
                                    <li>
                                        <Link to="/escolinha" className="dropdown-item" >Escolinha</Link>
                                    </li>
                                    <li>
                                        <Link to="/fanfarra" className="dropdown-item" >Fanfarra</Link>
                                    </li>
                                    <li className="dropdown" id='sub-dropdown'>
                                        <a className="dropdown-item" href="#">Secções</a>
                                        <ul className="dropdown-menu" id='sub-dropdown-menu'>
                                            <li>
                                                <Link to="/silvares" className="dropdown-item" >Silvares</Link>
                                            </li>
                                            <li>
                                                <Link to="/soalheira" className="dropdown-item" >Soalheira</Link>
                                            </li>
                                            <li>
                                                <Link to="/tresPovos" className="dropdown-item" >Três Povos</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/recrutamento" className="dropdown-item" >Recrutamento</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/socio" className="nav-link" >Sócio</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" id="navbarFormacao" role="button" data-bs-toggle="dropdown" aria-expanded="false">Formação</a>
                                <ul className="dropdown-menu" aria-labelledby="navFormacao">
                                    <li>
                                        <Link to="/departamento" className="dropdown-item" >Departamento De Formação</Link>
                                    </li>
                                    <li>
                                        <Link to="/principios" className="dropdown-item" >Princípios</Link>
                                    </li>
                                    <li>
                                        <Link to="/formacoes" className="dropdown-item" >Formações</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/contactos" className="nav-link" >Contactos</Link>
                            </li>
                        </ul>
                            :
                            <ul className="navbar-nav ms-auto">
                                {adminMenu(auth.funcao)}
                                <li className="nav-item">
                                    <div className='row'>
                                        <div className='co-md-10'></div>
                                        <div className='co-md-2'>
                                            <Link to="/" className="nav-link float-end" >Sair</Link>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default NavbarMenu