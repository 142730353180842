import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import {
    Editor, createButton, EditorProvider, Toolbar, BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    Separator
} from 'react-simple-wysiwyg'
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom'

function InserirNoticia() {
    const history = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const BtnAlignTextRight = createButton('Align Right', <BsTextRight />, 'justifyRight')
    const BtnAlignTextCenter = createButton('Align Center', <BsTextCenter />, 'justifyCenter')
    const BtnAlignTextLeft = createButton('Align Left', <BsTextLeft />, 'justifyLeft')

    const validationSchema = Yup.object().shape({
        titulo_noticia: Yup.string().required("Campo Vazio").matches(/^[^_\-\/*]+$/, "Caracteres inválidos"),
        imagem_noticia: Yup
            .mixed()
            .test('fileType', 'Apenas imagens são permitidas!', value => {

                if (value) {
                    return value[0] && ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value[0].type)
                }
                return true;
            })
            .test('fileSize', 'A imagem deve ter no máximo 10MB!', value => {
                if (value) {
                    return value[0] && value[0].size <= 10000000;
                }
                return true;
            }).required('Campo Vazio'),
        corpo_noticia: Yup.string().required('Campo vazio')
    })
    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }
    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('titulo_noticia', data.titulo_noticia)
        formData.append('imagem_noticia', data.imagem_noticia[0])
        formData.append('corpo_noticia', data.corpo_noticia)

        axios.post('/noticia/nova_noticia', formData)
            .then((response) => {
                console.log(response)
            }).catch((err) => console.error(err))
        reset()
        openModal('Notícia inserida com sucesso')
    }
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })
    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Inserir Notícia</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'>
                <br />
                <form className='mt-5 mb-5' onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data'>
                    <div className='row mb-3'>
                        <div className="col">
                            <label htmlFor="titulo_noticia" className="form-label">Título</label>
                            <input type="text" className="form-control" autoComplete='off' name='titulo_noticia' {...register('titulo_noticia')} />
                            <p className='text-danger'>{errors.titulo_noticia?.message}</p>
                        </div>
                        <div className="col">
                            <label htmlFor="imagem_noticia" className="form-label">Imagem</label>
                            <input type="file" class="form-control" name='imagem_noticia' {...register('imagem_noticia')} />
                            <p className='text-danger'>{errors.imagem_noticia?.message}</p>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor="corpo_noticia"><h4>Corpo Notícia</h4></label>
                        <EditorProvider>
                            <Controller
                                name='corpo_noticia'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                            <Toolbar>
                                                <BtnUndo />
                                                <BtnRedo />
                                                <Separator />
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <Separator />
                                                <BtnAlignTextLeft />
                                                <BtnAlignTextCenter />
                                                <BtnAlignTextRight />
                                                <Separator />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <Separator />
                                                <BtnClearFormatting />
                                                <Separator />
                                                <BtnStyles />
                                            </Toolbar>
                                        </Editor>
                                        {errors.corpo_noticia && (
                                            <p className='text-danger'>{errors.corpo_noticia?.message}</p>
                                        )}
                                    </div>
                                )}
                            >
                            </Controller>
                        </EditorProvider>
                    </div>
                    <button type="submit" className="btn btn-outline btnNovoSocio ms-3 float-end">Inserir</button>
                    <button type="reset" className="btn btn-outline btnNovoSocio mb-5 float-end" >Limpar</button>
                    <Modal
                        title={
                            <div>
                                <h4 className='text-center fw-bold text-danger'>Criação de Notícia</h4>
                                <hr className='text-danger' />
                            </div>
                        }
                        open={isModalVisible}
                        onOk={() => {
                            setIsModalVisible(false)
                            history('/administracao/lista_noticias')
                        }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                    </Modal>
                </form>
            </div>
            <div className='clearfix'></div>
        </div>
    )
}

export default InserirNoticia