import React, { useState, useEffect } from 'react'
import {
    Editor, createButton, EditorProvider, Toolbar, BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    Separator
} from 'react-simple-wysiwyg'
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import { Modal } from 'antd';



function EnvioEmail() {
    const history = useNavigate()
    const idUser = useParams()['id']
    const [userEmail, setUserEmail] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [statusCode, setStatusCode] = useState(false)


    const validationSchema = Yup.object().shape({
        mensagem_email: Yup.string().required("Campo Vazio"),
        assunto_email: Yup.string().required("Campo Vazio")
    })

    let nome_u = JSON.stringify(userEmail.nome)
    let email_u = JSON.stringify(userEmail.email)
    let assunto_u = JSON.stringify(userEmail.assunto)
    let mensagem_u = JSON.stringify(userEmail.mensagem)

    const preloadData = {
        nome: nome_u,
        email: email_u,
        assunto: assunto_u,
        mensagem: mensagem_u,
        assunto_email: '',
        mensagem_email: ''
    }

    const { control, register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: preloadData

    })


    useEffect(() => {
        axios.get(`/form_contato/message_email/${idUser}`)
            .then((response) => {
                setUserEmail(response.data.user[0])
                setValue('email', response.data.user[0].email)


            })
            .catch((err) => console.log(err))
    }, [idUser, setValue])


    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    };



    const BtnAlignTextRight = createButton('Align Right', <BsTextRight />, 'justifyRight')
    const BtnAlignTextCenter = createButton('Align Center', <BsTextCenter />, 'justifyCenter')
    const BtnAlignTextLeft = createButton('Align Left', <BsTextLeft />, 'justifyLeft')





    const onSubmit = (data) => {

        let email_user = data.email
        let send_email = data.mensagem_email
        let assunto = data.assunto_email
        axios.post(`/form_contato/enviar_email/${idUser}`, { email_user, assunto, send_email },
            {
                validateStatus: (status) => {
                    return status < 600
                }
            })
            .then((response) => {
                if (response.data.codigo === 200) {
                    setStatusCode(true)
                }
                else {
                    setStatusCode(false)
                }
            }).catch((err) => {
                console.log(err)
            })

        statusCode ? openModal('Email não enviado, poderá não ser válido') : openModal('Email enviado com sucesso')
        reset()
    }





    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Enviar Email</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'>
                <form onSubmit={handleSubmit(onSubmit)} className='mt-5'>
                    <div className='row'>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="nome" >Nome</label>
                                <input type="text" className="form-control" autoComplete='off' id='nome' name='nome' defaultValue={userEmail.nome} readOnly />
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="email" >Email</label>
                                <input type="email" className="form-control" autoComplete='off' id='email' name='email' defaultValue={userEmail.email} {...register('email')} readOnly />
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="assunto" >Assunto</label>
                                <input type="email" className="form-control" autoComplete='off' id='assunto' name='assunto' defaultValue={userEmail.assunto} readOnly />
                            </div>
                        </div>
                    </div>
                    <label htmlFor="mensagem" className='mt-3' >Mensagem recebida</label>
                    <textarea name='mensagem' className='form-control' cols="10" id='mensagem' rows={10} defaultValue={userEmail.mensagem} readOnly></textarea>
                    <div className='row'>
                        <div className='col-md col-sx mt-3'>
                            <div className="form-group">
                                <label htmlFor="assunto_email" >Assunto Mensagem</label>
                                <input type="text" className="form-control" autoComplete='off' name='assunto_email' {...register('assunto_email')} />
                                <p className='text-danger'>{errors.assunto_email?.message}</p>
                            </div>
                        </div>
                        <div className='col-md col-sx'></div>
                        <div className='col-md col-sx'></div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="mensagem_email">Mensagem</label>
                        <EditorProvider>
                            <Controller
                                id='mensagem_email'
                                name='mensagem_email'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                            <Toolbar>
                                                <BtnUndo />
                                                <BtnRedo />
                                                <Separator />
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <Separator />
                                                <BtnAlignTextLeft />
                                                <BtnAlignTextCenter />
                                                <BtnAlignTextRight />
                                                <Separator />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <Separator />
                                                <BtnClearFormatting />
                                                <Separator />
                                                <BtnStyles />
                                            </Toolbar>
                                        </Editor>
                                        {errors.mensagem_email && (
                                            <p className='text-danger'>{errors.mensagem_email.message}</p>
                                        )}
                                    </div>
                                )}
                            >
                            </Controller>
                        </EditorProvider>
                    </div>
                    <br />
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-outline-secondary btn-lg btnNovoSocio me-md-2" type="submit">Enviar</button>
                        <button className="btn btn-outline-secondary btn-lg btnNovoSocio" type="reset">Limpar</button>
                        <Modal
                            title="Envio de email"
                            open={isModalVisible}
                            onOk={() => {
                                setIsModalVisible(false)
                                history('/administracao/lista_mensagens')
                            }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <p>{modalContent}</p>
                        </Modal>
                    </div>

                </form>
            </div>
            <div className='clearfix mt-5'></div>
        </div >
    )
}

export default EnvioEmail