import React, { useState, useEffect } from 'react'
import {
    Editor, createButton, EditorProvider, Toolbar, BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    Separator
} from 'react-simple-wysiwyg'
import { BsTextCenter, BsTextLeft, BsTextRight } from "react-icons/bs";
import { Modal } from 'antd';
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import {  useNavigate } from 'react-router-dom'
import axios from 'axios'

function InserirFormacao() {
    const history = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const BtnAlignTextRight = createButton('Align Right', <BsTextRight />, 'justifyRight')
    const BtnAlignTextCenter = createButton('Align Center', <BsTextCenter />, 'justifyCenter')
    const BtnAlignTextLeft = createButton('Align Left', <BsTextLeft />, 'justifyLeft')

    const validationSchema = Yup.object().shape({
        designacao: Yup.string().required("Campo Vazio"),
        enquadramento: Yup.string().required("Campo Vazio"),
        objetivos: Yup.string().required("Campo Vazio"),
        programa: Yup.string().required("Campo Vazio"),
        imagem_formacao: Yup.mixed().test('fileType', 'Apenas imagens são permitidas!', value => {

            if (value) {
                return value[0] && ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value[0].type)
            }
            return true;
        })
            .test('fileSize', 'A imagem deve ter no máximo 10MB!', value => {
                if (value) {
                    return value[0] && value[0].size <= 10000000;
                }
                return true;
            }).required('Campo Vazio'),

        duracao: Yup.string().required("Campo Vazio"),
        data_realizacao: Yup.date().required("Campo Vazio").typeError('Inválido').min(Yup.ref('data_limite_inscricao'), 'Data inferior à data de inscrição'),
        local_realizacao: Yup.string().required("Campo Vazio"),
        data_limite_inscricao: Yup.date().required("Campo Vazio").typeError('Inválido').max(Yup.ref('data_realizacao'),'Data superior à data de realização')
    })

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }
    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append('designacao', data.designacao)
        formData.append('enquadramento', data.enquadramento)
        formData.append('objetivos', data.objetivos)
        formData.append('programa', data.programa)
        formData.append('duracao', data.duracao)
        formData.append('data_realizacao', data.data_realizacao)
        formData.append('local_realizacao', data.local_realizacao)
        formData.append('data_limite_inscricao', data.data_limite_inscricao)
        formData.append('imagem_formacao', data.imagem_formacao[0])

        axios.post('/formacao/nova_formacao', formData)
            .then((response) => {
                openModal('Formação inserida com sucesso')
            }).catch((err) => {
                console.error(err)

                if (err.response) {
                    console.error('Status do erro:', err.response.status);
                    console.error('Dados do erro:', err.response.data);
                } else if (err.request) {
                    console.error('Requisição não obteve resposta do servidor.');
                } else {
                    console.error('Erro ao processar a solicitação.', err.message);
                }
                openModal('Formação não inserida com sucesso')
            })
        reset()
        
    }


    const { control, register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })

    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Inserir Formação</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'>
                <form onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data' className='mt-5'>
                    <div className='row'>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="designacao" ><h4>Designação</h4></label>
                                <input type="text" className="form-control" autoComplete='off' name='designacao' {...register('designacao')} />
                                <p className='text-danger'>{errors.designacao?.message}</p>
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="data_realizacao" ><h4>Data Realização</h4></label>
                                <input type="date" className="form-control" autoComplete='off' name='data_realizacao' {...register('data_realizacao')} />
                                <p className='text-danger'>{errors.data_realizacao?.message}</p>
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="imagem_formacao" ><h4>Imagem Formação</h4></label>
                                <input type="file" className="form-control" name='imagem_formacao' {...register('imagem_formacao')} />
                                <p className='text-danger'>{errors.imagem_formacao?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="duracao" ><h4>Duração</h4></label>
                                <input type="text" className="form-control" autoComplete='off' name='duracao' {...register('duracao')} />
                                <p className='text-danger'>{errors.duracao?.message}</p>
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="local_realizacao" ><h4>Local Formação</h4></label>
                                <input type="text" className="form-control" autoComplete='off' name='local_realizacao' {...register('local_realizacao')} />
                                <p className='text-danger'>{errors.local_realizacao?.message}</p>
                            </div>
                        </div>
                        <div className='col-md col-sx'>
                            <div className="form-group">
                                <label htmlFor="data_limite_inscricao"><h4>Data Limite de Inscrição</h4></label>
                                <input type="date" className="form-control" autoComplete='off' name='data_limite_inscricao' {...register('data_limite_inscricao')} />
                                <p className='text-danger'>{errors.data_limite_inscricao?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="enquadramento"><h4>Enquadramento</h4></label>
                        <EditorProvider>
                            <Controller
                                name='enquadramento'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                            <Toolbar>
                                                <BtnUndo />
                                                <BtnRedo />
                                                <Separator />
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <Separator />
                                                <BtnAlignTextLeft />
                                                <BtnAlignTextCenter />
                                                <BtnAlignTextRight />
                                                <Separator />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <Separator />
                                                <BtnClearFormatting />
                                                <Separator />
                                                <BtnStyles />
                                            </Toolbar>
                                        </Editor>
                                        {errors.enquadramento && (
                                            <p className='text-danger'>{errors.enquadramento.message}</p>
                                        )}
                                    </div>
                                )}
                            >
                            </Controller>
                        </EditorProvider>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="objetivos"><h4>Objetivos</h4></label>
                        <EditorProvider>
                            <Controller
                                name='objetivos'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                            <Toolbar>
                                                <BtnUndo />
                                                <BtnRedo />
                                                <Separator />
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <Separator />
                                                <BtnAlignTextLeft />
                                                <BtnAlignTextCenter />
                                                <BtnAlignTextRight />
                                                <Separator />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <Separator />
                                                <BtnClearFormatting />
                                                <Separator />
                                                <BtnStyles />
                                            </Toolbar>
                                        </Editor>
                                        {errors.objetivos && (
                                            <p className='text-danger'>{errors.objetivos.message}</p>
                                        )}
                                    </div>
                                )}
                            >
                            </Controller>
                        </EditorProvider>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="programa"><h4>Programa</h4></label>
                        <EditorProvider>
                            <Controller
                                name='programa'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <Editor value={field.value} onChange={(value) => field.onChange(value)} className='form-control' containerProps={{ style: { height: '400px', overflowY: 'auto' } }}>
                                            <Toolbar>
                                                <BtnUndo />
                                                <BtnRedo />
                                                <Separator />
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <Separator />
                                                <BtnAlignTextLeft />
                                                <BtnAlignTextCenter />
                                                <BtnAlignTextRight />
                                                <Separator />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <Separator />
                                                <BtnClearFormatting />
                                                <Separator />
                                                <BtnStyles />
                                            </Toolbar>
                                        </Editor>
                                        {errors.programa && (
                                            <p className='text-danger'>{errors.programa.message}</p>
                                        )}
                                    </div>
                                )}
                            >
                            </Controller>
                        </EditorProvider>
                    </div>
                    <br />
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-outline-secondary btn-lg btnNovoSocio me-md-2" type="submit">Enviar</button>
                        <button className="btn btn-outline-secondary btn-lg btnNovoSocio" type="reset">Limpar</button>
                    </div>
                    <Modal
                            title={
                                <div>
                                    <h4 className='text-center fw-bold text-danger'>Criação de Formação</h4>
                                    <hr className='text-danger'/>
                                </div>
                            }
                            open={isModalVisible}
                            onOk={() => {
                                setIsModalVisible(false)
                                history('/administracao/lista_formacoes')
                            }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                        </Modal>
                </form>
            </div>
            <div className='clearfix mt-5'></div>
        </div >
    )
}

export default InserirFormacao