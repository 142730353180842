import React from 'react'
import { Container, Row} from 'react-bootstrap'
import { motion } from 'framer-motion'

import dummyImage from '../../images/presidente_associacao.jpg'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function MensagemPresidente() {
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Container fluid
            >
                <Row className='bannerImage P-5 mb-5'>
                    <h1 className='h1 text-center'>Mensagem Presidente</h1>
                </Row>
                <Container className='mt-5 mb-5'>
                    <motion.h1 className='h1 ml-5 titleSection'
                        initial={{
                            opacity: 0,
                            x: '-100vw'
                        }}
                        transition={{ delay: 2.5, duration: 2 }}
                        animate={{
                            x: 0,
                            opacity: 1
                        }}
                    >Carlos Jerónimo</motion.h1>
                    <hr className='lineSection' />

                    <img src={dummyImage} alt='dummy' className='img-fluid rounded  imgDummy' />

                    <p className='messageWelcome mt-5'>
                        <br />Caros amigos e amigas,<br /><br />
                        É com enorme satisfação que vos dou as boas-vindas ao website da Associação Humanitária de Bombeiros Voluntários do Fundão(AHBVF)!<br /><br />
                        Este é um momento importante para nossa instituição e comunidade, pois este novo espaço online representa um grande passo na nossa jornada de servir e proteger.

                    </p>
                    <p className='messageWelcome'>
                        A nossa missão como associação humanitária, que detém um Corpo de Bombeiros, sempre foi estar ao lado dos nossos concidadãos,
                        nos momentos de necessidade. Agora, com este site moderno e interativo, esperamos fortalecer ainda mais os nossos
                        laços com a comunidade e fornecer informações vitais de forma mais eficaz.
                    </p>
                    <p className='messageWelcome '>
                        Neste site, encontrará uma riqueza de recursos e informações. Desde notícias e atualizações sobre as nossas atividades e eventos até
                        dicas de segurança e orientações em situações de emergência, estamos empenhados em tornar este espaço uma fonte valiosa de conhecimento
                        e apoio para todos.<br /><br />
                        Gostaríamos de agradecer a todos os membros da equipa que trabalharam incansavelmente para tornar este projeto uma realidade.
                        O seu esforço e dedicação são verdadeiramente admiráveis. Também gostaríamos de expressar a nossa gratidão aos nossos patrocinadores
                        e à comunidade em geral, que nos apoiam continuamente na nossa missão.
                        Este site não é apenas uma ferramenta online; é uma extensão da nossa dedicação em servir. Continuaremos a responder a cada chamada de
                        emergência, a treinar e aprimorar as nossas competências, e a esforçar-nos para fazer da nossa comunidade um lugar mais seguro.<br /><br />
                        Convidamos todos a explorar este novo espaço, a fornecer feedback e a envolver-se connosco. Queremos ouvir as suas opiniões e as
                        suas necessidades para melhorar ainda mais os nossos serviços.
                        Mais uma vez, bem-vindos ao novo site da Associação Humanitária de Bombeiros Voluntários do Fundão. <br /><br />
                        Juntos, continuaremos a proteger e a servir a nossa comunidade com dedicação e paixão.<br /><br />
                        Com gratidão e respeito,

                    </p>
                    <p className='messageWelcome'>
                        O Presidente da Direção da AHBVF,<br />
                        <b>Carlos Manuel Gomes Jerónimo</b>

                    </p>
                </Container>
            </Container>
        </motion.div>
    )
}

export default MensagemPresidente