import React, { useState, useEffect } from 'react'
//import PhotoSwipeLightbox from 'photoswipe/lightbox';
//import 'photoswipe/style.css';
import { motion } from 'framer-motion'
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import portrait from '../../images/portait.jpg'



import alvaro_nabais from '../../galeria/corpo_ativo/fundao/honra/alvaro_nabais_bombeiro_1_honra.jpg'
import artur_ramos from '../../galeria/corpo_ativo/fundao/honra/artur_ramos_subchefe_honra_2.jpg'
import fernanda_guterres from '../../galeria/corpo_ativo/fundao/honra/fernanda_guterres_oficial_bombeira_2_2.jpg'
import filipe_couto from '../../galeria/corpo_ativo/fundao/honra/filipe_couto_bombeiro_2_honra.jpg'
import joao_garcia from '../../galeria/corpo_ativo/fundao/honra/joao_garcia_sub_chefe_honra.jpg'
import joao_jose_robalo from '../../galeria/corpo_ativo/fundao/honra/joao_jose_robalo_ventura_sub_chefe_honra.jpg'
import jose_correia from '../../galeria/corpo_ativo/fundao/honra/jose_correia_2_comandante.jpg'
import jose_goncalves from '../../galeria/corpo_ativo/fundao/honra/jose_goncalves_bombeiro_1_honra.jpg'
import jose_jacinto from '../../galeria/corpo_ativo/fundao/honra/jose_jacinto_bombeiro_1_honra.jpg'
import Jose_luis_sousa from '../../galeria/corpo_ativo/fundao/honra/Jose_luis_sousa_sub_chefe_honra.jpg'
import luis_pinheiro from '../../galeria/corpo_ativo/fundao/honra/luis_pinheiro_bombeiro_3_honra.jpg'






import honraCom_2 from '../../images/divisas/quadro-honra-2-comandante.png'
import honraAdjunto from '../../images/divisas/quadro-honra-adjunto.png'
import honraOficial from '../../images/divisas/quadro-honra-oficial.png'
import honraChefe from '../../images/divisas/quadro-honra-chefe.png'
import honraSubChefe from '../../images/divisas/quadro-honra-sub-chefe.png'
import honraBombeiro_1 from '../../images/divisas/quadro-honra-bombeiro-1.png'
import honraBombeiro_2 from '../../images/divisas/quadro-honra-bombeiro-2.png'
import honraBombeiro_3 from '../../images/divisas/quadro-honra-bombeiro-3.png'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function QuadroHonra() {

    const idDiv = 'fotoHonra'

    const [comando, setComando] = useState([
        { posto: '2º Comandante', divisa: honraCom_2, nome: 'José Correia', foto: jose_correia },
       ]
    )

    const [oficial, setOficial] = useState(
        [{ posto: 'Oficial Bombeiro de 2ª', divisa: honraOficial, nome: 'Fernanda Guterres', foto: fernanda_guterres }]
    )
/*
    const [chefe, setChefe] = useState([
        { posto: 'Chefe', divisa: honraChefe, nome: 'João Garcia', foto: joao_garcia },
        ]
    )
*/
    const [subchefe, setSubChefe] = useState([
        { posto: 'Subchefe', divisa: honraSubChefe, nome: 'Artur Ramos', foto: artur_ramos },
        { posto: 'Subchefe', divisa: honraSubChefe, nome: 'João Garcia', foto: joao_garcia },
        { posto: 'Subchefe', divisa: honraSubChefe, nome: 'João  Robalo', foto: joao_jose_robalo },
        { posto: 'Subchefe', divisa: honraSubChefe, nome: 'José Sousa', foto: Jose_luis_sousa },
        ]
    )

    const [bombeiro_1, setBombeiro_1] = useState([
        { posto: 'Bombeiro de 1º', divisa: honraBombeiro_1, nome: 'Álvaro Nabais', foto: alvaro_nabais },
        { posto: 'Bombeiro de 1º', divisa: honraBombeiro_1, nome: 'Jorge Goncalves', foto: jose_goncalves },
        ]
    )

    const [bombeiro_2, setBombeiro_2] = useState([
    
    
        { posto: 'Bombeiro de 2º', divisa: honraBombeiro_2, nome: 'Filipe Couto', foto: filipe_couto },
        ]
    )

    const [bombeiro_3, setBombeiro_3] = useState([
    
        { posto: 'Bombeiro de 3º', divisa: honraBombeiro_3, nome: 'José Jacinto', foto: jose_jacinto },
        { posto: 'Bombeiro de 3º', divisa: honraBombeiro_3, nome: 'Luís Pinheiro', foto: luis_pinheiro },
        
        ]
    )

    const [selectedInfo, setSelectedInfo] = useState([])
    const [openLight, setOpenLight] = useState(false)
    const [descriptionMaxLines, setDescriptionMaxLines] = useState(10);
    const [descriptionTextAlign, setDescriptionTextAlign] = useState("start");
    let slides = 0
    const getInfo = (nome, foto) => {
        setSelectedInfo({ nome, foto })
        setOpenLight(true)
        
    }
    
    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerHonra p-5 mb-5'>
                <h1 className='text-center'>Quadro de Honra</h1>
            </div>
            <div className='container mb-5'>
                <div className='row'>
                    <h2 className='h2 mt-5 titleSection'>Comando</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        comando.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.posto}</h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Oficial</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        oficial.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.posto}</h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                  
                    {/*
                          <h2 className='h2 mt-5 titleSection'>Chefe</h2>
                          <hr className='mb-5 lineSection' />
                        chefe.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'><br /></h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })*/
                    }

                    <h2 className='h2 mt-5 titleSection'>Subchefe</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        subchefe.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' onClick={() => { getInfo(value.nome, value.foto) }}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'><br /></h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 1ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeiro_1.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'><br /></h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 2ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeiro_2.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'><br /></h5>
                                            <img className='img-fluid rounded divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Bombeiro de 3ª</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        bombeiro_3.map((value) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery' onClick={() => { getInfo(value.nome, value.foto) }}>
                                        <img src={value.foto} alt='' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'><br /></h5>
                                            <img className='img-fluid divisaGallery m-1' src={value.divisa} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Lightbox
                open={openLight}
                close={() => setOpenLight(false)}
                plugins={[Captions]}
                slides={[
                    {
                        src: selectedInfo.foto,
                        title: <h1 className='h1 mt-5'>{selectedInfo.nome}</h1>,
                        description: <div className='container mb-5'>
                        </div>,
                        alt: "teste"

                    }
                ]}
                captions={{ descriptionTextAlign, descriptionMaxLines }}
                carousel={{ finite: slides <= 1 }}
                render={{
                    buttonPrev: slides <= 1 ? () => null : undefined,
                    buttonNext: slides <= 1 ? () => null : undefined,
                }}
            />
        </motion.div>
    )
}

export default QuadroHonra