import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../auth/useAuth";


const RequireAuth = () => {
    const { auth } = useAuth()
    const location = useLocation()

    return (auth?.accessToken ? <Outlet /> : <Navigate to="/administracao" state={{ from: location }} replace />)
}

export default RequireAuth