import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Typography } from '@mui/material';
import axios from 'axios'
import { Link } from 'react-router-dom'

function TabelaContato() {
    const [listContatos, setListContatos] = useState([])
    const columns = useMemo(() => [
        {
            accessorKey: 'data',
            header: 'Data',
        },
        {
            accessorKey: 'hora',
            header: 'Hora',
        },
        {
            accessorKey: 'nome', //normal accessorKey
            header: 'Nome',
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            id: 'EnviarEmail',
            header: 'Enviar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                row.original.respondido == 0 ?
                <Link className='text-decoration-none fst-normal text-secondary' to={`/administracao/enviar_email/${row.original.id}`}>Responder</Link>
                : <p className='text-success'>Enviado</p>
            )
        }
    ], [])
    useEffect(() => {
        axios.get('/form_contato/all_messages')
            .then((response) => {
                setListContatos(response.data.user_message)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])
    return (
        <div className='container-fluid mb-5'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Mensagens Recebidas</h1>
            <hr className='lineSection mb-5' />
            <div className='container mt-5'></div>
            <MaterialReactTable columns={columns} data={listContatos}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing
                enableColumnActions={false}
                renderDetailPanel={({ row }) => {
                    return (
                        <Box
                            sx={{
                                display: 'grid',
                                margin: 'auto',
                                gridTemplateColumns: '1fr 1fr',
                                width: '100%',
                            }}
                        >
                            <Typography className='mt-2'><b>Mensagem</b><br />{row.original.mensagem}</Typography>
                        </Box>
                    )
                }}
                muiTableHeadCellProps={{
                    style: {
                        color: 'white',
                        fontSize: 28

                    }
                }}
                muiTableHeadRowProps={{
                    style: {
                        backgroundColor: 'orange'
                    }
                }}
                muiTopToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
                muiBottomToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
            />
        </div>
    )
}

export default TabelaContato