import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

import foto_1 from '../../images/photo_1.jpg'
import foto_2 from '../../images/formacao_2.jpg'
import foto_3 from '../../images/image_three.jpg'
import foto_4 from '../../images/escolinha-6.png'

function TestemunhosIntro() {
    const [testemunhos, setTestemuhos] = useState([
        { nome: 'Hugo Sampaio', testemunho: '"Hoje, pedi auxílio e os Bombeiros do Fundão. Responderam, como sempre, e resgataram um animal em sofrimento que se refugiou em minha casa."', imageTestemunho: foto_1 },
        { nome: 'Carla Rolão', testemunho: '"Não há palavras para descrever o trabalho excecional que têm feito. Muito obrigado."', imageTestemunho: foto_2 },
        { nome: 'Virgínia Silva', testemunho: '"“Obrigada a todos os bombeiros pela entrega, dedicação e empenho na defesa das nossas vidas. bem-haja!"', imageTestemunho: foto_3 },
        { nome: 'Hélio Raposo', testemunho: '"Estão sempre prontos para agir nos momentos de necessidade. Cumprem a missão de proteger e servir. Orgulho de contribuir para esta nobre missão. Juntos, somos mais fortes!"', imageTestemunho: foto_4 },
    ])
    return (
        <div className='container-fluid g-0'>
            <Carousel fade controls={false} className="testemunhosArea">
                {
                    testemunhos.map((value, key) => {
                        return (
                            <Carousel.Item key={key}>
                                <img className='d-block w-100' src={value.imageTestemunho} />
                                <Carousel.Caption className='carouselTestemunho'>
                                    <h1 className='h1 mb-5'>{value.testemunho}</h1>
                                    <h4 className='h4 text-center'>{value.nome}</h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default TestemunhosIntro