import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function DetalheRecruta() {
    const cc = useParams()['cc']
    const [recruta, setRecruta] = useState([])
    useEffect(() => {
        axios.get(`/recrutamento/detalhe_recruta/${cc}`).then((response) => {
            setRecruta(response.data.recruta)
            console.log(response.data.recruta)

        })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {
                recruta.map((value) => {
                    return (
                        <div className='container mt-5'>
                            <Link className='float-end text-decoration-none fs-3' to={'/administracao/lista_recrutamento'}>Voltar</Link>
                            <motion.h1 className='h1 titleSection'
                                initial={{
                                    opacity: 0,
                                    x: '-100vw'
                                }}
                                transition={{ delay: 2.5, duration: 2 }}
                                animate={{
                                    x: 0,
                                    opacity: 1
                                }}
                            >Detalhe Recruta</motion.h1>
                            <hr className='lineSection' />
                            <div className='container recruta_area'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Nome</b><br />
                                            {value.nome}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Data Nascimento</b><br />
                                            {value.nascimento}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Cartão de Cidadão</b><br />
                                            {value.cc}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Morada</b><br />
                                            {value.morada} {value.cp}<br />{value.localidade}
                                        </p>
                                    </div>
                                    <div className='col-md-3'>
                                        <p>
                                            <b>Concelho</b><br />
                                            {value.concelho}
                                        </p>
                                    </div>
                                    <div className='col-md-3'></div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Grupo Sanguinio</b><br />
                                            {value.grupo_sanguinio}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Contacto</b><br />
                                            {value.contacto}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Email</b><br />
                                            {value.email}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Estado Cívil</b><br />
                                            {value.estado_civil}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Situação Profissional</b><br />
                                            {value.situacao_profissional}
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p>
                                            <b>Carta de Condução</b><br />
                                            {value.carta_conducao}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                        </div>
                    )
                })
            }

        </motion.div>
    )
}

export default DetalheRecruta