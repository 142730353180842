import React, { useState, useContext } from 'react'
import logo from '../../images/logo_image.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import * as Yup from 'yup'
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { UserContext } from '../../context/userContext';



function AdminPrincipal() {
    const { setAuth } = useContext(UserContext)
    const [seePassword, setSeePassword] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }
    const validationSchema = Yup.object().shape({
        emailAdmin: Yup.string().required("Campo vazio").email("Email inválido"),
        passAdmin: Yup.string().required("Campo vazio")
    })
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })
    const navigate = useNavigate()
    const onSubmit = async (data) => {

        const response = await axios.get('/geral/login_admin', { params: { email: data.emailAdmin, password: data.passAdmin } })
        if (response.data.token === 'nao_existe') {
            openModal('Email não existe')
        }
        else if (response.data.token == '') {
            openModal('Dados incorrectos')
        } else {
            const email = data.emailAdmin
            const accessToken = response.data.token
            const funcao = response.data.funcao
            
            setAuth({ email, accessToken, funcao })
            
            navigate('/administracao/admin_hub')
        }
    }
    return (
        <div>
            <div className='container-fluid'>
                <img src={logo} className='img-fluid mx-auto d-block mt-5 mb-3' width={"15%"}  alt=''/>
                <div className='container'>
                    <h3 className='h3 text-center'>LOGIN ADMINISTRAÇÃO</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='formAdmin mx-auto d-block mt-3  mb-5'>
                        <div className='form-floating mb-3'>
                            <input type="email" className='form-control' autoComplete='off' {...register('emailAdmin')} />
                            <label className='floatingInput'>Email</label>
                        </div>
                        <p className='text-danger'>{errors.emailAdmin?.message}</p>
                        <div className='form-floating input-group mb-3'>
                            <input type={seePassword == true ? 'text' : 'password'} className='form-control' autoComplete='off' {...register('passAdmin')} />
                            <label className='floatingInput'>Password</label>
                            <div className='input-group-append' onClick={() => setSeePassword(!seePassword)}>
                                <span className='input-group-text'>
                                    {seePassword ? <BsEyeSlash style={{ width: '43px', height: '43px' }} /> : <BsEye style={{ width: '43px', height: '43px' }} />}
                                </span>
                            </div>
                        </div>
                        <p className='text-danger'>{errors.passAdmin?.message}</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn btn-outline-secondary btn-lg btnNovoSocio me-md-2" type="submit">Entrar</button>
                            <button className="btn btn-outline-secondary btn-lg btnNovoSocio" type="reset">Limpar</button>
                        </div>
                        <Modal
                            title="Autenticação Administração"
                            open={isModalVisible}
                            onOk={() => {
                                setIsModalVisible(false)
                            }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <p className='text-center mt-3'>{modalContent}</p>
                        </Modal>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AdminPrincipal