import React, { useState } from 'react'
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Modal } from 'antd';

function InscreverFormacao() {
    const history = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const id_formacao = useParams()['id_formacao']

    const validationSchema = Yup.object().shape({
        nomeFormando: Yup.string().required("Campo vazio"),
        nascFormando: Yup.date().required("Campo Vazio").max(new Date(), 'Data de nascimento inválida').test('age', 'Deve ter idade igual ou superior a 18 anos', value => {
            const diff = Date.now() - new Date(value).getTime();
            const ageDate = new Date(diff);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);
            return age >= 18;
        }).typeError('Inválido'),
        sexoFormando: Yup.string().required('Selecione um valor'),
        ccFormando: Yup.string().required('Campo vazio').matches(/^\d{8}$/, 'Número de Cartão de Cidadão inválido')
            .test('verifica_cc', 'CC já existente', async (value) => {
                if (!value) { return true }

                const response = await axios.get(`/formacao/check_cc`, { params: { cc: value, formacao: id_formacao } })
                return !response.data.exists

            }),
        nifFormando: Yup.string().test('nifSocio', 'NIF inválido', (value) => {
            const nif = value.replace(/\D/g, '');
            if (nif.length !== 9) {
                return false;
            }
            const checkDigit = parseInt(nif.substring(8, 9), 10);
            let totalSum = 0;
            for (let i = 0; i < 8; i++) {
                totalSum += parseInt(nif.substring(i, i + 1), 10) * (9 - i);
            }
            const computedCheckDigit = 11 - (totalSum % 11);
            return computedCheckDigit === checkDigit;
        }).test('verifica_nif', 'NIF já existente', async (value) => {
            if (!value) { return true }

            const response = await axios.get(`/formacao/check_nif`, { params: { nif: value, formacao: id_formacao } })
            return !response.data.exists

        }).required("Campo Vazio"),
        validadeFormando: Yup.string().required('Campo vazio').typeError('Inválido'),
        habilitacoesFormando: Yup.string().required('Selecione um valor'),
        nacionalidadeFormando: Yup.string().required('Campo vazio'),
        naturalidadeFormando: Yup.string().required('Campo vazio'),
        moradaFormando: Yup.string().required('Campo vazio'),
        localidadeFormando: Yup.string().required('Campo vazio'),
        cpFormando: Yup.string().required("Campo Vazio").matches(/^[0-9]{4}-[0-9]{3}$/, 'O código postal deve ter o formato 0000-000'),
        concelhoFormando: Yup.string().required('Campo vazio'),
        contactoFormando: Yup.string().matches(/^(9[1236][0-9]{7})|(2\d{8})$/, 'Insira um número de telefone válido').required("Campo Vazio"),
        emailFormando: Yup.string().required('Campo Vazio').email('Email inválido').test('verifica_email', 'Email já existente', async (value) => {
            if (!value) { return true }


            const response = await axios.get(`/formacao/check_email`, { params: { email: value, formacao: id_formacao } })

            return !response.data.exists

        }),
        profissionalFormando: Yup.string().required('Selecione um valor'),
        empresaFormando: Yup.string().when("profissionalFormando", {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio')
        }),
        eMoradaFormando: Yup.string().when('profissionalFormando', {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio')
        }),
        eLocalidadeFormando: Yup.string().when('profissionalFormando', {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio')
        }),
        eCpFormando: Yup.string().when('profissionalFormando', {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio').matches(/^[0-9]{4}-[0-9]{3}$/, 'O código postal deve ter o formato 0000-000'),
        }),
        eConcelhoFormando: Yup.string().when('profissionalFormando', {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio')
        }),
        profissaoFormando: Yup.string().when('profissionalFormando', {
            is: (value) => ['Empregado', 'Empresario'].includes(value),
            then: (schema) => schema.required('Campo vazio')
        }),
        conhecimentoFormando: Yup.boolean().oneOf([true], 'Tem que concordar com os termos e condições')
    })

    const { register, watch, handleSubmit, formState: { errors }, reset, } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const profissionalFormando = watch('profissionalFormando')

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    };

    const onSubmit = (data) => {
        data.id_formacao = id_formacao
        axios.post('/formacao/inscrever_formacao', data)
            .then((response) => {
                console.log(response.data)
            })
            .catch((err) =>
                console.error(err)
            )
        reset()
        openModal('Inscrição na formação com Sucesso')
    }

    return (
        <div className='container'>
            <motion.h1 className='h1 titleSection mt-5'
                initial={{
                    opacity: 0,
                    x: '-100vw'
                }}
                transition={{ delay: 2.5, duration: 2 }}
                animate={{
                    x: 0,
                    opacity: 1
                }}
            >Inscrição Formação</motion.h1>
            <hr className='lineSection mb-5' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <legend className='mb-3'>Informação Pessoal</legend>
                <div className='form-floating'>
                    <input type="text" className='form-control' autoComplete='off' {...register('nomeFormando')} />
                    <label className='floatingInput'>Nome Completo</label>
                    <p className='text-danger'>{errors.nomeFormando?.message}</p>
                </div>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="date" className='form-control' autoComplete='off' {...register('nascFormando')} />
                            <label className='floatingInput'>Data Nascimento</label>
                            <p className='text-danger'>{errors.nascFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className="form-floating">
                            <select className="form-select" id="sexoFormando"  {...register('sexoFormando')}>
                                <option value="">Selecione o Sexo</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Feminino">Feminino</option>
                            </select>
                            <label htmlFor="sexoFormando">Sexo</label>
                            <p className='text-danger'>{errors.sexoFormando?.message}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('ccFormando')} />
                            <label className='floatingInput'>Cartao Cidadão</label>
                            <p className='text-danger'>{errors.ccFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('nifFormando')} />
                            <label className='floatingInput'>NIF</label>
                            <p className='text-danger'>{errors.nifFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="date" className='form-control' autoComplete='off' {...register('validadeFormando')} />
                            <label className='floatingInput'>Validade</label>
                            <p className='text-danger'>{errors.validadeFormando?.message}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className="form-floating">
                            <select className="form-select" id="habilitacoes" {...register('habilitacoesFormando')}>
                                <option value="">Selecione a habilitação</option>
                                <option value="Básico">Básico</option>
                                <option value="Secundário">Secundário</option>
                                <option value="Profissional">Profissional</option>
                                <option value="Tecnológico">Tecnológico</option>
                                <option value="Licenciatura">Licenciatura</option>
                                <option value="Mestrado">Mestrado</option>
                                <option value="Doutoramento">Doutoramento</option>
                            </select>
                            <label htmlFor="floatingSelect">Habilitações</label>
                            <p className='text-danger'>{errors.habilitacoesFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('nacionalidadeFormando')} />
                            <label className='floatingInput'>Nacionalidade</label>
                            <p className='text-danger'>{errors.nacionalidadeFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('naturalidadeFormando')} />
                            <label className='floatingInput'>Naturalidade</label>
                            <p className='text-danger'>{errors.naturalidadeFormando?.message}</p>
                        </div>
                    </div>
                </div>
                <div className='form-floating mb-3'>
                    <input type="text" className='form-control' autoComplete='off' {...register('moradaFormando')} />
                    <label className='floatingInput'>Morada</label>
                    <p className='text-danger'>{errors.moradaFormando?.message}</p>
                </div>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('localidadeFormando')} />
                            <label className='floatingInput'>Localidade</label>
                            <p className='text-danger'>{errors.localidadeFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('cpFormando')} />
                            <label className='floatingInput'>Código Postal</label>
                            <p className='text-danger'>{errors.cpFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('concelhoFormando')} />
                            <label className='floatingInput'>Concelho</label>
                            <p className='text-danger'>{errors.concelhoFormando?.message}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('contactoFormando')} />
                            <label className='floatingInput'>Contacto</label>
                            <p className='text-danger'>{errors.contactoFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>
                        <div className='form-floating'>
                            <input type="text" className='form-control' autoComplete='off' {...register('emailFormando')} />
                            <label className='floatingInput'>Email</label>
                            <p className='text-danger'>{errors.emailFormando?.message}</p>
                        </div>
                    </div>
                    <div className='col-md col-xs'>

                    </div>
                </div>
                <legend className=' mt-3 mb-3'>Situação Profissional</legend>
                <div className='row'>
                    <div className='col-md col-xs'>
                        <div className="form-floating">
                            <select className="form-select" {...register('profissionalFormando')}>
                                <option value="">Selecione a sua situação</option>
                                <option value="Desempregado">Desempregado</option>
                                <option value="Estudante">Estudante</option>
                                <option value="Empregado">Empregado por conta de outrém</option>
                                <option value="Empresario">Empregado por conta própria</option>
                                <option value="Reformado">Reformado</option>
                            </select>
                            <label htmlFor="floatingSelect">Situação</label>
                            <p className='text-danger'>{errors.profissionalFormando?.message}</p>
                        </div>
                    </div>
                    {

                        profissionalFormando === 'Empregado' || profissionalFormando === 'empresario' ?
                            <div className='col-md col-xs'>
                                <div className='form-floating'>
                                    <input type="text" className='form-control' autoComplete='off' {...register('empresaFormando')} />
                                    <label className='floatingInput'>Empresa</label>
                                    <p className='text-danger'>{errors.empresaFormando?.message}</p>
                                </div>
                            </div>
                            :
                            <div className='col-md col-xs'></div>
                    }
                </div>
                {
                    profissionalFormando === 'Empregado' || profissionalFormando === 'Empresario' ?
                        <div>
                            <div className='form-floating'>
                                <input type="text" className='form-control' autoComplete='off' {...register('eMoradaFormando')} />
                                <label className='floatingInput'>Morada</label>
                                <p className='text-danger'>{errors.eMoradaFormando?.message}</p>
                            </div>
                            <div className='row'>
                                <div className='col-md col-xs'>
                                    <div className='form-floating'>
                                        <input type="text" className='form-control' autoComplete='off' {...register('eLocalidadeFormando')} />
                                        <label className='floatingInput'>Localidade </label>
                                        <p className='text-danger'>{errors.eLocalidadeFormando?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-xs'>
                                    <div className='form-floating'>
                                        <input type="text" className='form-control' autoComplete='off' {...register('eCpFormando')} />
                                        <label className='floatingInput'>Código Postal</label>
                                        <p className='text-danger'>{errors.eCpFormando?.message}</p>
                                    </div>
                                </div>
                                <div className='col-md col-xs'>
                                    <div className='form-floating'>
                                        <input type="text" className='form-control' autoComplete='off' {...register('eConcelhoFormando')} />
                                        <label className='floatingInput'>Concelho</label>
                                        <p className='text-danger'>{errors.eConcelhoFormando?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='form-floating'>
                                <input type="text" className='form-control' autoComplete='off' {...register('profissaoFormando')} />
                                <label className='floatingInput'>Profissão</label>
                                <p className='text-danger'>{errors.profissaoFormando?.message}</p>
                            </div>
                        </div>
                        :
                        ''
                }
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" {...register('conhecimentoFormando')} />
                            <label className="form-check-label" htmlFor="conhecimentoFormando">
                                Autorizo recolher e tratar os meus dados pessoais para efeitos de contactos posteriores para
                                divulgação de eventos e tratamento de questões relacionadas com o meu estatuto de associado/a.
                                Tenho consciência de que estes dados serão apenas para uso exclusivo da Associação e não serão
                                facultados a terceiros,seja por que motivo for, sem a minha autorização expressa nesse sentido.
                            </label>
                            <p className='text-danger'>{errors.conhecimentoFormando?.message}</p>
                        </div>
                    </div>
                </div>
                <div className="d-grid gap-2 mt-5 mb-5">
                    <button type='submit' className='btn recrutamento_button'>Inscrever-se</button>
                    <button type='reset' className='btn recrutamento_button'>Limpar</button>
                </div>
                <Modal
                    title={
                        <div>
                            <h4 className='text-center fw-bold text-danger'>Inscrição Formação</h4>
                            <hr className='text-danger' />
                        </div>
                    }
                    open={isModalVisible}
                    onOk={() => {
                        setIsModalVisible(false)
                        history('/formacoes')
                    }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                </Modal>
            </form>

        </div>
    )
}

export default InscreverFormacao