import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box } from '@mui/material';
import axios from 'axios'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

function TabelaNoticia() {
    const [listNoticias, setListNoticias] = useState([])
    let theID = ''
    const encrptID = (id) => {

        let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()

        while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
            encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
        }

        return encryptedId

    }
    const arquivaNoticia = (id_noticia) => {
        axios.post(`/noticia/arquivar_noticia/${id_noticia}`)
            .then((response) => {
                console.log(response.data)
            })
            .catch((err) => console.error(err))
    }

    const destaqueNoticia = (id_noticia) => {
        axios.post(`/noticia/destacar_noticia/${id_noticia}`)
            .then((response) => {
                console.log(response.data)
            })
            .catch((err) => console.error(err))
    }
    const columns = useMemo(() => [
        {
            accessorKey: 'data_noticia',
            header: 'Data Publicação',
        },
        {
            accessorKey: 'hora_noticia',
            header: 'Hora Publicação',
        },
        {
            accessorKey: 'criador_noticia',
            header: 'Criador Publicação',
        },
        {
            accessorKey: 'titulo_noticia',
            header: 'Título',
        },
        {
            id: 'arquivar',
            header: 'Arquivar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                row.original.arquivo == 0 ? <p className='arquiva text-success' onClick={() => arquivaNoticia(row.original.id_noticia)}>Sim</p> : <p className='arquiva text-danger' onClick={() => arquivaNoticia(row.original.id_noticia)}>Não</p>
            )
        },
        {
            id: 'destaque',
            header: 'Destaque',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                row.original.destaque_noticia == 0 ? <p className='arquiva text-success' onClick={() => destaqueNoticia(row.original.id_noticia)}>destacar</p> : <p className='arquiva text-danger' onClick={() => destaqueNoticia(row.original.id_noticia)}>cancelar</p>
            )
        },
        {
            id: 'edita',
            header: 'Editar',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <Link className='btn btn-outline-secondary btnNovoSocio' to={`/administracao/altera_noticia/${theID = encrptID(row.original.id_noticia)}`}>Editar</Link>
            )
        }
    ], [])



    const loadNoticias = () => {
        axios.get('/noticia/all_noticias_admin')
            .then((response) => {
                setListNoticias(response.data.noticias)
            })
            .catch((err) => console.error(err))
    }
    useEffect(() => {
        loadNoticias()

        const intervalNoticias = setInterval(() => {
            loadNoticias()
        }, 2000)

        return () => clearInterval(intervalNoticias)
    }, [])
    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Notícias</h1>
            <hr className='lineSection mb-5' />
            <br />
            <MaterialReactTable columns={columns} data={listNoticias}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing
                enableColumnActions={false}
                renderDetailPanel={({ row }) => {
                    return (
                        <Box>
                            <div className='container-fluid'>
                                <div className='container containerImage p-1'>
                                    <img src={row.original.imagem_noticia} alt='noticia' className='img-fluid imgNoticia' />
                                </div>
                                <div className='container p-1 mb-5'>
                                    <div className='container'>
                                        <div className='fs-5 mt-3 text_noticia' dangerouslySetInnerHTML={{ __html: row.original.corpo_noticia }} />
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )
                }}
                muiTableHeadCellProps={{
                    style: {
                        color: 'white',
                        fontSize: 26

                    }
                }}
                muiTableHeadRowProps={{
                    style: {
                        backgroundColor: 'orange'
                    }
                }}
                muiTopToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
                muiBottomToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
            />
            <br />
        </div>
    )
}

export default TabelaNoticia