import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios';
import { useParams } from 'react-router-dom'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function Noticia() {
    const id_noticia = useParams()['id_noticia']
    const [noticia, setNoticia] = useState([])
    useEffect(() => {
        axios.get(`/noticia/selected_noticia/${id_noticia}`)
            .then((response) => {
                setNoticia(response.data.noti)
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {
                noticia.map((value, key) => {
                    return (
                        <div className='container mt-5' key={key}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <motion.h1 className='h1 titleSection'
                                        initial={{
                                            opacity: 0,
                                            x: '-100vw'
                                        }}
                                        transition={{ delay: 2.5, duration: 2 }}
                                        animate={{
                                            x: 0,
                                            opacity: 1
                                        }}
                                    >{value.titulo_noticia}</motion.h1>
                                </div>
                            </div>
                            <hr className='lineSection mb-5' />
                            <div className='container containerImage p-1'>
                                <img src={value.imagem_noticia} alt='noticia' className='img-fluid imgNoticia' />
                            </div>
                            <div className='container mt-3 mb-5'>
                                <div dangerouslySetInnerHTML={{ __html: value.corpo_noticia }} />
                            </div>
                        </div>
                    )
                })
            }
        </motion.div>
    )
}

export default Noticia