import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useForm, Controller } from "react-hook-form"
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import { Modal } from 'antd';
import EscolinhaLogo from '../../images/escolinhaLogo.png'
import escolinha_1 from '../../galeria/escolinha/escolinha_1.jpg'
import escolinha_2 from '../../galeria/escolinha/escolinha_2.jpg'
import escolinha_3 from '../../galeria/escolinha/escolinha_3.jpg'
import escolinha_4 from '../../galeria/escolinha/escolinha_4.jpg'
import escolinha_5 from '../../galeria/escolinha/escolinha_5.jpg'
import escolinha_6 from '../../galeria/escolinha/escolinha_6.jpg'
import escolinha_7 from '../../galeria/escolinha/escolinha_7.jpg'
import escolinha_8 from '../../galeria/escolinha/escolinha_8.jpg'



const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function InfCadetes() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [index, setIndex] = useState(-1);
    const fotosEscolinha = [
        { src: escolinha_1, width: 780, height: 1080 },
        { src: escolinha_2, width: 650, height: 1080 },
        { src: escolinha_3, width: 1080, height: 950 },
        { src: escolinha_4, width: 1080, height: 950 },
        { src: escolinha_5, width: 1080, height: 800 },
        { src: escolinha_6, width: 1080, height: 800 },
        { src: escolinha_7, width: 780, height: 1080 },
        { src: escolinha_8, width: 650, height: 1080 },

    ]


    const validationSchema = Yup.object().shape({
        nome: Yup.string().required("Campo Vazio"),
        morada: Yup.string().required("Campo Vazio"),
        localidade: Yup.string().required("Campo Vazio"),
        concelho: Yup.string().required("Campo Vazio"),
        cp: Yup.string().required("Campo Vazio").matches(/^[0-9]{4}-[0-9]{3}$/, 'O código postal deve ter o formato 0000-000'),
        email: Yup.string().required('Campo Vazio').email('Email inválido').test('verifica_email', 'Email já existente', async (value) => {
            if (!value) { return true }


            const response = await axios.get(`/cadete/check_email`, { params: { email: value } })

            return !response.data.exists

        }),
        cc: Yup.string().required('Campo vazio').matches(/^\d{8}$/, 'Número de Cartão de Cidadão inválido')
            .test('verifica_cc', 'CC já existente', async (value) => {
                if (!value) { return true }

                const response = await axios.get(`/cadete/check_cc`, { params: { cc: value } })
                return !response.data.exists

            }),

        grupoSanguinio: Yup.string().required("Campo Vazio"),
        nascimento: Yup.date().required("Campo Vazio").typeError('Inválido').min(Yup.ref('nascimento'), 'Data inválida'),
        contacto: Yup.string().matches(/^(9[1236][0-9]{7})|(2\d{8})$/, 'Insira um número de telefone válido').required("Campo Vazio"),
        conhecimentoSocio: Yup.boolean().oneOf([true], 'Tem que concordar com os termos e condições').required("Tem que concordar com os termos e condições")
    })

    const openModal = (content) => {
        setModalContent(content);
        setIsModalVisible(true);
    }

    const onSubmit = (data) => {

        axios.post('/cadete/novo_cadete', data)
            .then((response) => {
                console.log(response)
            }).catch((err) => {
                console.error(err)

                if (err.response) {
                    console.error('Status do erro:', err.response.status);
                    console.error('Dados do erro:', err.response.data);
                } else if (err.request) {
                    console.error('Requisição não obteve resposta do servidor.');
                } else {
                    console.error('Erro ao processar a solicitação.', err.message);
                }
            })
        openModal('Inscrição na escolinha realizada com sucesso')
        reset()
    }



    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema)
    })




    return (

        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerEscolinha p-5 mb-5'>
                <h1 className='text-center'>Escola Infantes e Cadetes</h1>
            </div>
            <div className='container introEscola'>
                <div className='container'>
                    <img src={EscolinhaLogo} alt="logoEscola" className='img-fluid missaoLogo mb-3' />
                </div>
                <p>
                    Fundada em 2014, a escolinha de infantes e cadetes dos Bombeiros Voluntários de Fundão iniciou funções em 2015,
                    e desde aí, tem integrado crianças dos 6 aos 16 anos no seio da corporação. Entre os 6 e 13 anos as crianças
                    são integradas nos infantes, e entre os 13 e os 16 nos cadetes, pois apenas aos 17 anos podem integrar um estágio oficial.
                </p>
                <br />
                <p>
                    A nossa escolinha permite que, nos seus tempos livres, os jovens participem em atividades didáticas com base
                    na preservação ambiental e em várias áreas de socorro. Estas atividades permitem-lhes aprender valores
                    fundamentais como a coragem, independência e altruísmo, e até capacidades indispensáveis para o seu futuro
                    como o trabalho em equipa, responsabilidade, boas capacidades físicas, noções de primeiros socorros,
                    e consciência social e ambiental.
                </p>
                <br />
                <p>
                    Estando o futuro nas mãos dos nossos jovens, a nossa Escola Infantes e Cadetes espera continuar a formar
                    jovens que consigam manter a excelência do serviço, e que venham a ser adultos despertos,
                    responsáveis e sensíveis para a prevenção, defesa e preservação da natureza. Os nossos jovens
                    são os alicerces da nossa comunidade.
                </p>

                <div className='container-fluid mt-5 mb-5'>
                    <PhotoAlbum layout='columns' photos={fotosEscolinha} padding={3} onClick={({ index }) => setIndex(index)} />
                    <Lightbox
                        slides={fotosEscolinha}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Zoom]}
                    />
                </div>
            </div>
            <div className='container'>
                <h2 className='h2 mt-5 titleSection mb-3'>Como fazer a inscrição?</h2>
                <p>
                    As inscrições estão abertas. Faça agora a sua inscrição através do formulário disponibilizado
                    a seguir, ou se necessário, dirija-se ao nosso quartel com a seguinte documentação:
                </p>
                <ul>
                    <li>Bilhete de Identidade ou Cartão de Cidadão, do candidato/a e dos pais</li>
                    <li>Indicação do Grupo Sanguíneo</li>
                    <li>O candidato/a deve estar acompanhado do seu representante legal</li>
                </ul>
                <form className='mb-5' onSubmit={handleSubmit(onSubmit)} encType='multipart-form-data'>
                    <div className="form-floating mb-3 mt-3">
                        <input type="text" className="form-control" autoComplete='off' name="nome"  {...register('nome')} />
                        <label htmlFor="nome">Nome</label>
                        <p className='text-danger'>{errors.nome?.message}</p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="morada"  {...register('morada')} />
                                <label htmlFor="morada_recruta">Morada</label>
                                <p className='text-danger'>{errors.morada?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name='cp' {...register('cp')} />
                                <label htmlFor="codigo_recruta">Código Postal</label>
                                <p className='text-danger'>{errors.cp?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name='localidade' {...register('localidade')} />
                                <label htmlFor="localidade">Localidade</label>
                                <p className='text-danger'>{errors.localidade?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="concelho" {...register('concelho')} />
                                <label htmlFor="localidade">Concelho</label>
                                <p className='text-danger'>{errors.concelho?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="cc"  {...register('cc')} />
                                <label htmlFor="cc">CC</label>
                                <p className='text-danger'>{errors.cc?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="date" className="form-control" autoComplete='off' name="nascimento"  {...register('nascimento')} />
                                <label htmlFor="nascimento">Data Nascimento</label>
                                <p className='text-danger'>{errors.nascimento?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mb-3 mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="grupoSanguinio" {...register('grupoSanguinio')} />
                                <label htmlFor="grupoSanguinio">Grupo Sanguinio</label>
                                <p className='text-danger'>{errors.grupoSanguinio?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="contacto" maxLength="9" {...register('contacto')} />
                                <label htmlFor="contacto">Contacto</label>
                                <p className='text-danger'>{errors.contacto?.message}</p>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-floating mt-3">
                                <input type="text" className="form-control" autoComplete='off' name="email" {...register('email')} />
                                <label htmlFor="email">Email</label>
                                <p className='text-danger'>{errors.email?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" {...register('conhecimentoSocio')} />
                                <label className="form-check-label" htmlFor="conhecimentoSocio">
                                    Autorizo recolher e tratar os meus dados pessoais para efeitos de contactos posteriores para
                                    divulgação de eventos e tratamento de questões relacionadas com o meu estatuto de associado/a.
                                    Tenho consciência de que estes dados serão apenas para uso exclusivo da Associação e não serão
                                    facultados a terceiros,seja por que motivo for, sem a minha autorização expressa nesse sentido.
                                </label>
                                <p className='text-danger'>{errors.conhecimentoSocio?.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-grid gap-2">
                        <button className="btn recrutamento_button" type="submit">Enviar</button>
                        <button className="btn recrutamento_button" type="reset">Limpar</button>
                    </div>
                    <Modal
                        title={
                            <div>
                                <h4 className='text-center fw-bold text-danger'>Inscrição Escolinha</h4>
                                <hr className='text-danger' />
                            </div>
                        }
                        open={isModalVisible}
                        onOk={() => {
                            setIsModalVisible(false)
                        }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                    >
                        <h5 className='h5 text-center mt-2 fw-bold'>{modalContent}</h5>
                    </Modal>
                </form>
            </div>

        </motion.div>
    )
}

export default InfCadetes