import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import axios from 'axios'
import image_1 from '../../images/image_one.jpg'
import image_2 from '../../images/image_two.jpg'
import image_3 from '../../images/image_three.jpg'

function UltimasNoticias() {
  const [lastNews, setLastNews] = useState([])
  const [encIDs, setEncIDs] = useState([])

  const encrptID = (id) => {

    let encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()

    while (encryptedId.includes("/") || encryptedId.includes("%") || encryptedId.includes("+")) {
      encryptedId = CryptoJS.DES.encrypt(id.toString(), 'bvFundao').toString()
    }

    return encryptedId

  }

  useEffect(() => {
    axios.get('/noticia/last_noticias')
      .then((response) => {
        setLastNews(response.data.noticias)
        const theID = response.data.noticias.map(noti => encrptID(noti.id_noticia))
        setEncIDs(theID)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <Container className='noticeArea'>
      <h1 className='h1 text-center mb-5 mt-3 titleSection'>Ultimas Notícias</h1>
      <div>
        <Row className='mb-5 sectionNoticia d-flex justify-content-center'>
          {
            lastNews.map((value, index) => {
              const id_noticia = encIDs[index]
              return (
                <Col className='mb-5 col-sm-6 col-md-4' key={index}>
                  <div className="cardNotice">
                    <div className="cardNotice-flipper">
                      <div className="cardNotice-front">
                        <img src={value.imagem_noticia} alt="Avatar" style={{ width: "300px", height: "400px" }} />
                      </div>
                      <div className="cardNotice-back mb-5">
                        <h2 className='h2 mt-5 mb-5 text-uppercase'>{value.titulo_noticia}</h2>
                        <div className='d-grid'>
                          <Link to={`/noticia/${encodeURIComponent(id_noticia)}`}><button className='btn btnNoticia'>Ver Noticia</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>
    </Container>
  )
}

export default UltimasNoticias