import React, { useState } from 'react'
import { motion } from 'framer-motion'

import logoDepartamento from '../../images/departamento_formacao.jpg'


function MissaoValores() {
    const [open, setOpen] = useState(false)
    const [panelID, setPanelID] = useState(0)
    const openPane = (id) => {

        if (id == 1) {
            setOpen(true)
            setPanelID(id)
        }

        if (id == 2) {
            setOpen(true)
            setPanelID(id)
        }
    }

    const containerTransition = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { delay: 1.5, duration: 1.5 },
        },
        exit: {
            x: '-100vw',
            transition: { ease: 'easeInOut' }
        }
    }


    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerPrincipios p-5 mb-5'>
                <h1 className='text-center'>Princípios</h1>
            </div>
            <div className='container mb-5'>
                <h1 className='h1 text-center titleSection mb-3'>Missão</h1>
                <div className='container mb-5'>
                    <p className='departementText'>
                        O Departamento de Formação da AHBV Fundão tem como missão contribuir, nas suas áreas de competência,
                        para o desenvolvimento dos seus bombeiros, entidades públicas ou privadas e pessoas singulares,
                        através da valorização pessoal e profissional, seguindo uma política e princípios definidos na missão dos
                        Bombeiros Voluntários do Fundão sobretudo no que se refere à educação e formação.
                    </p>
                </div>
                <h1 className='h1 text-center titleSection mb-3'>Valores</h1>
                <div className='container mb-5'>
                    <p className='departementText'>
                        O Departamento de Formação na sua relação com os formadores, formandos,
                        com organismos públicos e privados, orienta-se pelos seguintes valores:
                        <ul className='mt-3'>
                            <li >Responsabilidade de forma a responder às necessidades de formação do Corpo de Bombeiros;</li>
                            <li>Profissionalismo, iniciativa e eficiência;</li>
                            <li>Honestidade e lealdade nas relações internas e externas;</li>
                            <li>Empenho e trabalho de equipa.</li>
                        </ul>
                    </p>
                </div>
                <h1 className='h1 text-center titleSection mb-3'>Formação Intraempresa</h1>
                <div className='container mb-5'>
                    <p className='departementText'>
                        O Departamento de Formação desenvolve cursos intraempresas preparados com base num levantamento de necessidades
                        efetuado na empresa por forma a ir de encontro às necessidades da empresa e dos trabalhadores, assim como dos requisitos legais.
                        A formação poderá ser ministrada nas instalações das empresas ou em outras acordadas por ambas as partes.<br /><br />
                        A aposta no capital humano é uma das áreas chave que as empresas procuram desenvolver.
                        Aproveite o mote e aposte no desenvolvimento das competências dos seus recursos humanos e contacte-nos!<br /><br />
                        A emissão de certificados de Formação Profissional será concretizada pelo departamento, através da Plataforma <b>SIGO</b>,
                        de acordo com as orientações e modelo publicado pela Portaria 474/2010, de 8 de Julho. Procura-se assegurar que todas as ações desenvolvidas vão ao encontro das necessidades e expetativas dos
                        seus destinatários, consubstanciando-se no desenvolvimento e crescimento do próprio departamento.<br /><br />
                        
                    </p>
                    <h3 className='h3 text-center'><b>Aposte na formação, garanta competências!</b></h3>
                </div>
            </div>
        </motion.div>
    )
}

export default MissaoValores