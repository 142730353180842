import React from 'react'
import { Container, Row} from 'react-bootstrap'
import { motion } from 'framer-motion'
import comandanteFoto from '../../images/imagem_comandante.jpg'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}

function MensagemComandante() {
    return (
        <motion.div
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Container fluid>
                <Row className='bannerImage P-5 mb-5'>
                    <h1 className='h1 text-center'>Mensagem do Comandante</h1>
                </Row>
                <Container className='mt-5'>
                    <motion.h1 className='h1 ml-5 titleSection'
                        initial={{
                            opacity: 0,
                            x: '-100vw'
                        }}
                        transition={{ delay: 2.5, duration: 2 }}
                        animate={{
                            x: 0,
                            opacity: 1
                        }}
                    >José de Sousa</motion.h1>
                    <hr className='lineSection' />
                    <figure>
                        <img src={comandanteFoto} alt='José de Sousa' className='img-fluid rounded  imgDummy mt-3' />
                    </figure>
                    <p className='messageWelcome mt-5'>
                        Caros internautas, visitantes do nosso sítio na internet, em meu nome e dos
                        restantes quatro elementos de comando do nosso Corpo de Bombeiros, dou-
                        vos as boas-vindas a nossa casa virtual.
                    </p>
                    <p className='messageWelcome'>
                        Num Mundo cada vez mais digital que por um lado tende a afastar as pessoas
                        fisicamente, por outro também possibilita entrar na casa dos outros sem sair
                        da própria casa, no fundo, é isso que pretendemos explorar e proporcionar
                        aos internautas, é desta forma que pretendemos abrir a porta das nossas
                        casas no Fundão, Silvares, Soalheira e Três Povos ao Mundo, para que nos
                        possam visitar a qualquer hora do dia na noite e assim ficarem a conhecer-
                        nos melhor.<br /><br />
                        Pretendemos através desta forma de comunicar, uma maior aproximação às
                        pessoas mostrando-lhe um pouco de quem somos e o que fazemos, dando-
                        lhes a conhecer as nossas casas por dentro, os nossos veículos, equipamentos
                        e demais meios de socorro; mas acima de tudo apresentar-vos também o
                        ativo mais importante, os nossos bombeiros com e sem farda.
                    </p>
                    <p className='messageWelcome mb-5'>
                        Em nome da estrutura de comando e de todo o Corpo de Bombeiros, dizer-
                        vos que é nossa intenção, sob o desígnio “Juntos somos mais fortes”
                        continuarmos a honrar esta casa e esta causa em prole da comunidade
                        que honrosamente servimos, com total empenho, dedicação e entrega a
                        todos os valores que juramos defender.<br/><br/>
                        Espero que as vossas passagens por aqui sejam agradáveis, visitem-nos
                        sempre que desejarem e mantenham-se atentos as nossas informações.
                        Obrigado pelo carinho, simpatia e admiração, demonstrado para com os
                        vossos Soldados da Paz Bombeiros Voluntários do Fundão.
                    </p>
                </Container>
            </Container>
        </motion.div>

    )
}

export default MensagemComandante