import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'



pdfMake.vfs = pdfFonts.pdfMake.vfs


function socioPDF(data_inscricao, nome_socio, nasc_socio, morada_socio, cod_postal, localidade_socio, email_socio, nif_socio, contato_socio, logo) {

    const titleDocument = [
        {
            image: logo,
            width: 150,
            height: 155,
            alignment: 'center',
            margin: [0, 20, 0, 0],

        }
    ]

    const documentBody = [
        {
            text: 'Associação Humanitária de Bombeiros Voluntários de Fundão',
            fontSize: 16,
            bold: true,
            margin: [15, 140, 0, 30],
            alignment: 'center',
            style: 'header'
        },
        {
            text: 'FICHA DE NOVO SÓCIO',
            fontSize: 14,
            bold: true,
            margin: [15, 0, 0, 0],
            alignment: 'left',
            style: 'header',
            color: '#870008'
        },
        {
            text: '________________________________________________',
            fontSize: 14,
            margin: [15, 0, 0, 0],
            alignment: 'left',
            style: 'header',
            color: '#870008'
        },
        /* {
             columns: [
                 {
 
                     image: image_socio,
                     width: 90,
                     height: 110,
                     alignment: 'left',
                     margin: [40, 10, 40, 0]
 
                 },
                 {
                     text: [
                         { text: 'Nome', bold: true, fontSize: 14, color: '#870008' }, { text: `\n${nome_socio}\n\n`, fontSize: 14 },
                         { text: 'Data Nascimento', bold: true, fontSize: 14, color: '#870008' }, { text: `\n${nasc_socio}`, fontSize: 14 }
                     ],
                     margin: [50, 10, 40, 0]
 
                 }
 
             ]
         },*/
        {
            table: {
                widths: ['auto', '*'],
                body: [
                    [
                        { text: 'Data Inscrição:', bold: true, fontSize: 12 },
                        { text: data_inscricao, fontSize: 12 }
                    ],

                    [
                        { text: 'Nome:', bold: true, fontSize: 12 },
                        { text: nome_socio, fontSize: 12 }
                    ],
                    [
                        { text: 'Data de Nascimento:', bold: true, fontSize: 12 },
                        { text: nasc_socio, fontSize: 12 }
                    ],
                    [
                        { text: 'NIF:', bold: true, fontSize: 12 },
                        { text: nif_socio, fontSize: 12 }
                    ],
                    [
                        { text: 'Contacto:', bold: true, fontSize: 12 },
                        { text: contato_socio, fontSize: 12 }
                    ],
                    [
                        { text: 'Email:', bold: true, fontSize: 12 },
                        { text: email_socio, fontSize: 12 }
                    ],
                    [
                        { text: 'Morada:', bold: true, fontSize: 12 },
                        { text: `${morada_socio}, ${cod_postal} ${localidade_socio}`, fontSize: 12 }
                    ]
                ]
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#d3f2f1' : null;
                }
            },
            margin: [40, 80, 40, 0]
        },
        {
            text: '______________________________________________________________________',
            fontSize: 14,
            margin: [15, 240, 0, 0],
            alignment: 'center',
            style: 'header',
            color: '#870008'
        },
        {
            text: `Associação Humanitária de Bombeiros Voluntários de Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `Rua Cidade da Covilhã nº 18 6230-346 Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `275 772 700 - 962 993 960 / sec.dir@bvfundao.pt`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
    ]
    const footerDocument = []

    const documentDefenitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 30],
        header: [titleDocument],
        content: [documentBody],
        footer: [footerDocument]
    }
    pdfMake.createPdf(documentDefenitions).open()
}

function fichaFormando(nif, nome, nascimento, cc, sexo, validade_cc, habilitacao, nacionalidade, naturalidade, morada, cp, localidade, concelho, contacto, email, situacao_profissional, nome_empresa, morada_empresa, localidade_empresa, cp_empresa, concelho_empresa, profissao) {

    const titleDocument = [
        {
            text: 'Associação Humanitária de Bombeiros Voluntários de Fundão',
            fontSize: 20,
            bold: true,
            margin: [0, 25, 0, 0],
            alignment: 'center',
            style: 'header'
        },
    ]

    const documentBody = [
        {
            text: 'FICHA DE FORMANDO',
            fontSize: 18,
            bold: true,
            margin: [0, 5, 0, 0],
            alignment: 'center',
            style: 'header',
            color: '#870008'
        },
        {
            text: 'Informação Pessoal_____________________________________________________________________',
            fontSize: 14,
            margin: [0, 20, 0, 20],
            alignment: 'left',
            style: 'header',
            color: 'grey'
        },

        {
            table: {
                body: [
                    [
                        { text: [{ text: 'Nome', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${nome}\n\n`, fontSize: 12 }], colSpan: '4' },
                        {}, {}, {}
                    ],
                    [
                        { text: [{ text: 'Data Nascimento', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${nascimento}\n\n`, fontSize: 12 }] },
                        { text: [{ text: 'Nacionalidade', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${nacionalidade}`, fontSize: 12 }] },
                        { text: [{ text: 'Naturalidade', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${naturalidade}`, fontSize: 12 }] },
                        { text: [{ text: 'Sexo', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${sexo}`, fontSize: 12 }] },
                    ],
                    [
                        { text: [{ text: 'Morada', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${morada} ${cp} ${localidade}\n\n`, fontSize: 12 }], colSpan: '3' },
                        {}, {},
                        { text: [{ text: 'Concelho', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${concelho}`, fontSize: 12 }] }
                    ],
                    [
                        { text: [{ text: 'CC', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${cc}\n\n`, fontSize: 12 }] },
                        { text: [{ text: 'Validade', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${validade_cc}`, fontSize: 12 }] },
                        { text: [{ text: 'NIF', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${nif}`, fontSize: 12 }], colSpan: '2' },
                        {}
                    ],
                    [
                        { text: [{ text: 'Contacto', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${contacto}`, fontSize: 12 }] },
                        { text: [{ text: 'Email', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${email}`, fontSize: 12 }] },
                        { text: [{ text: 'Habilitações', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${habilitacao}`, fontSize: 12 }], colSpan: '2' }, {}
                    ]


                ]
            },
            layout: 'noBorders',
            margin: [50, 0, 0, 0]
        },
        {
            text: 'Situação Profissional____________________________________________________________________',
            fontSize: 14,
            margin: [0, 50, 0, 20],
            alignment: 'left',
            style: 'header',
            color: 'grey'
        },

        {

            table: {
                body: [
                    [
                        { text: [{ text: 'Situação Profissional', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${situacao_profissional}\n\n`, fontSize: 12 }] },
                        { text: [{ text: 'Nome da Empresa', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${nome_empresa}`, fontSize: 12 }], colSpan: '2' }, {}, {}
                    ],
                    [
                        { text: [{ text: 'Morada', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${morada_empresa} ${cp_empresa}, ${localidade_empresa}\n\n`, fontSize: 12 }], colSpan: '3' },
                        {}, {},
                        { text: [{ text: 'Concelho', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${concelho_empresa}`, fontSize: 12 }] }
                    ],
                    [
                        { text: [{ text: 'Profissão', bold: true, fontSize: 12, color: '#870008' }, { text: `\n${profissao}`, fontSize: 12 }] },
                        {}, {}, {}
                    ]
                ]
            },
            layout: 'noBorders',
            margin: [50, 0, 0, 0]


        },
        {
            text: 'Assinatura:______________________________________________________',
            fontSize: 14,
            margin: [0, 50, 0, 0],
            alignment: 'right',
            style: 'header',
            color: 'black'
        },
        {
            text: 'OBSERVAÇÔES: Estes dados serão inseridos no Sistema de Informação e Gestão da Oferta Educativa e Formativa (SIGO), em cumprimento do estipuldo na Portaria nº 474/2010 de 8 de Julho. Esta entidade formadora garante a estrita confidencialidade no tratamento ',
            fontSize: 8,
            margin: [0, 100, 0, 0],
            alignment: 'left',
            style: 'header',
            color: '#870008'
        },
        {
            text: '___________________________________________________________________________________',
            fontSize: 14,
            margin: [15, 5, 0, 0],
            alignment: 'center',
            style: 'header',
            color: '#870008'
        },
        {
            text: `Associação Humanitária de Bombeiros Voluntários de Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `Rua Cidade da Covilhã nº 18 6230-346 Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `275 772 700 - 962 993 960 / sec.dir@bvfundao.pt`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
    ]
    const footerDocument = []

    const documentDefenitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 30],
        header: [titleDocument],
        content: [documentBody],
        footer: [footerDocument]
    }
    pdfMake.createPdf(documentDefenitions).open()
}

function fichaCadete(nome, nascimento, cc, morada, cp, localidade, concelho, contacto, email, logo) {
    const titleDocument = [
        {
            image: logo,
            width: 170,
            height: 180,
            alignment: 'center',
            margin: [0, 20, 0, 0],

        }
    ]

    const documentBody = [
        {
            text: 'Associação Humanitária de Bombeiros Voluntários de Fundão',
            fontSize: 20,
            bold: true,
            margin: [0, 160, 0, 0],
            alignment: 'center',
            style: 'header'
        },
        {
            text: 'FICHA DE CADETE',
            fontSize: 18,
            bold: true,
            margin: [0, 5, 0, 0],
            alignment: 'center',
            style: 'header',
            color: '#870008'
        },
        {

            margin: [150, 50, 0, 0],
            table: {
                widths: [200, 150, 150],
                body: [
                    [
                        { text: `Nome: ${nome}`, fontSize: 12, colSpan: 2, fillColor: `#d3f2f1` },
                        {},
                        { text: `Nascimento: ${nascimento}`, fontSize: 12, fillColor: `#d3f2f1` }
                    ],
                    [
                        { text: `Morada: ${morada}, ${cp} ${localidade}`, fontSize: 12, colSpan: 2, fillColor: `#d3f2f1` },
                        {},
                        { text: `Concelho: ${concelho}`, fontSize: 12, fillColor: `#d3f2f1` }
                    ],
                    [
                        { text: `Cartão de Cidadão: ${cc}`, fontSize: 12, fillColor: `#d3f2f1` },
                        { text: `Contacto: ${contacto}`, fontSize: 12, fillColor: `#d3f2f1` },
                        { text: `Email: ${email}`, fontSize: 12, fillColor: `#d3f2f1` }
                    ]
                ]
            }
        },
        {
            text: '___________________________________________________________________________________',
            fontSize: 14,
            margin: [15, 150, 0, 0],
            alignment: 'center',
            style: 'header',
            color: '#870008'
        },
        {
            text: `Associação Humanitária de Bombeiros Voluntários de Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `Rua Cidade da Covilhã nº 18 6230-346 Fundão`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
        {
            text: `275 772 700 - 962 993 960 / sec.dir@bvfundao.pt`,
            fontSize: 8,
            alignment: 'center',
            style: 'header',
            color: 'gray'
        },
    ]
    const footerDocument = []

    const documentDefenitions = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15, 50, 15, 30],
        header: [titleDocument],
        content: [documentBody],
        footer: [footerDocument]
    }
    pdfMake.createPdf(documentDefenitions).open()
}

export { socioPDF, fichaFormando, fichaCadete }