import React from 'react'
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
function Footer() {
    return (
        <footer className='footer'>
            <div className='container footerArea'>
                <div className='container'>
                    <h6 className='h6 text-center mb-3'>Associação Humanitária de Bombeiros Voluntários de Fundão</h6>
                    <p className='text-center'>Rua Cidade da Covilhã nº 18 6230-346 Fundão</p>
                    <p className='text-center'>275 772 700/962 993 960 - sec.dir@bvfundao.pt</p>
                </div>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md col-sm'>
                            <h6 className='h6 text-center mb-1'>Secção de Silvares</h6>
                            <p className='text-center'>Bairro de Nossa Senhora de Fátima<br />238 6230-640 Silvares</p>
                            <p className='text-center'>275 662 231</p>
                        </div>
                        <div className='col-md col-sm'>
                            <h6 className='h6 text-center mb-1'>Secção Três Povos</h6>
                            <p className='text-center'>Rua da Estrada 98<br />6230-614 Três Povos</p>
                            <p className='text-center'>275 931 365</p>
                        </div>
                        <div className='col-md col-sm'>
                            <h6 className='h6 text-center mb-1'>Secção da Soalheira</h6>
                            <p className='text-center'>Rua do Vale do Pisco<br />6005-300 Soalheira</p>
                            <p className='text-center'>272 419 740</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <h5 className='h5 text-center'>Visite-nos nas redes sociais</h5>
                    <div className="social-media d-flex justify-content-center">
                        <ul className="list-inline">
                            <li className='list-inline-item'><a href="https://www.facebook.com/people/Bombeiros-Volunt%C3%A1rios-do-Fund%C3%A3o/100064761994173/?locale=pt_PT" target="_blank"><FaFacebook className='infoMedia' /></a></li>
                            <li className='list-inline-item'><a href="https://www.linkedin.com/company/bvfundao" target="_blank"><FaLinkedin className='infoMedia' /></a></li>
                            <li className='list-inline-item'><a href="https://www.instagram.com/bv.fundao/" target="_blank"><FaInstagram className='infoMedia' /></a></li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-12 text-md-center text-center '>
                            <p>Website realizado por  Fernando Gomes <br />
                                2023 &copy;</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer