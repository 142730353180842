import React from 'react'
import { WiDaySunny } from "react-icons/wi";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { FaAmbulance } from "react-icons/fa";


function LinkButtons() {
    return (
        <div className="icon-bar">
            <div className='row stickyRows'>
                <div className='col-md-7 col-sm-7 stickyText mb-4'>
                    <p className='stickyPar'>Meteorologia</p>
                </div>
                <div className='col-md-5 col-sm-5 stickyIcon'>
                    <a href="https://www.ipma.pt/pt/otempo/prev.localidade.hora/#Castelo%20Branco&Fund%C3%A3o" target='blank' className="linkedin" alt='Meteorologia' title='Meteorologia'><TiWeatherPartlySunny className='stickyIc' /></a>
                </div>
            </div>
            <div className='row stickyRows'>
                <div className='col-md-7 col-sm-7 stickyText mb-4'>
                    <p className='stickyPar'>Risco de incêndio e UV</p>
                </div>
                <div className='col-md-5 col-sm-5 stickyIcon'>
                    <a href="https://www.ipma.pt/pt/riscoincendio/rcm.pt/#0#0504" target='blank' className="youtube" alt="Risco de incêndio e UV" title='Risco de incêndio e UV'><WiDaySunny className='stickyIc' /></a>
                </div>
            </div>
            <div className='row stickyRows'>
                <div className='col-md-7 col-sm-7 stickyText'>
                    <p className='stickyPar'>Ocorrências ativas</p>
                </div>
                <div className='col-md-5 col-sm-5 stickyIcon'>
                    <a href="https://ocorrenciasativas.pt/" target='blank' className="google" alt="Ocorrências ativas" title='Ocorrências ativas'><FaAmbulance className='stickyIc' /></a>
                </div>
            </div>
        </div>

    )
}

export default LinkButtons