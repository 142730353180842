import React, { useState } from 'react'
import { motion } from 'framer-motion'

import antonio_rondao from '../../galeria/orgaos_sociais/antonio_rondao.jpg'
import armando_ferreira from '../../galeria/orgaos_sociais/armando_ferreira.jpg'
import carlos_jeronimo from '../../galeria/orgaos_sociais/carlos_jeronimo.jpg'
import filipe_batista from '../../galeria/orgaos_sociais/filipe_batista.jpg'
import helder_carvalho from '../../galeria/orgaos_sociais/helder_carvalho.jpg'
import joao_infante from '../../galeria/orgaos_sociais/joao_infante_2.jpg'
import jorge_boucho from '../../galeria/orgaos_sociais/jorge_boucho.jpg'
import jose_carvalho from '../../galeria/orgaos_sociais/jose_carvalho.jpg'
import jose_fernandes from '../../galeria/orgaos_sociais/jose_fernandes.jpg'
import jose_prazeres from '../../galeria/orgaos_sociais/jose_prazeres.jpg'
import julio_lopes from '../../galeria/orgaos_sociais/julio_lopes.jpg'
import luis_campos from '../../galeria/orgaos_sociais/luis_campos.jpg'
import manuel_milheiro from '../../galeria/orgaos_sociais/manuel_milheiro.jpg'
import maria_diogo from '../../galeria/orgaos_sociais/maria_diogo.jpg'

const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}


function OrgaosSociais() {
    const [assembleia, setAssembleia] = useState([
        { nome: 'Manuel Joaquim', cargo: 'Presidente', foto: manuel_milheiro },
        { nome: 'Filipe Batista', cargo: '1º Secretário' , foto: filipe_batista},
    ])
    const [direcao, setDirecao] = useState([
        { nome: 'Carlos Jerónimo', cargo: 'Presidente' , foto: carlos_jeronimo},
        { nome: 'José Fernandes', cargo: 'Vice-Presidente' , foto: jose_fernandes},
        { nome: 'Luís Campos', cargo: 'Tesoureiro' , foto: luis_campos},
        { nome: 'José Carvalho', cargo: 'Tesoureiro-Adjunto' , foto: jose_carvalho},
        { nome: 'Júlio Lopes', cargo: '1º Secretário' , foto: julio_lopes},
        { nome: 'António Rondão', cargo: 'Vogal' , foto: antonio_rondao},
        { nome: 'Helder Carvalho', cargo: 'Vogal' , foto: helder_carvalho},
        { nome: 'Jorge Boucho', cargo: 'Vogal' , foto: jorge_boucho}
    ])
    const [fiscal, setFiscal] = useState([
        { nome: 'João Infante', cargo: 'Vice-Presidente' , foto: joao_infante},
        { nome: 'José Prazeres', cargo: 'Concelho Fiscal' , foto: jose_prazeres},
        { nome: 'Armando Ferreira', cargo: 'Secretário Relator' , foto: armando_ferreira},
        { nome: 'Maria Diogo', cargo: 'Concelho Fiscal' , foto: maria_diogo},
    ])

    return (
        <motion.div className='container-fluid'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerOrgaos p-5 mb-5'>
                <h1 className='h1 text-center'>Orgãos Sociais</h1>
            </div>
            <div className='container mb-5'>
                <div className='row'>
                    <h2 className='h2 mt-5 titleSection'>Assembleia Geral</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        assembleia.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.cargo}</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <h2 className='h2 mt-5 titleSection'>Direção</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        direcao.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3'>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.cargo}</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <h2 className='h2 mt-5 titleSection'>Concelho Fiscal</h2>
                    <hr className='mb-5 lineSection' />
                    {
                        fiscal.map((value, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 my-3' key={index}>
                                    <div className='boxGallery'>
                                        <img src={value.foto} alt='' className='img-fluid rounded' />
                                        <div className='contentGallery'>
                                            <h4 className='nomeGallery mb-5'>{value.nome}</h4>
                                            <h5 className='postoGallery mb-3'>{value.cargo}</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </motion.div>
    )
}

export default OrgaosSociais