import React, { useEffect, useState, useMemo } from 'react'
import MaterialReactTable from 'material-react-table'
import { Box, Typography } from '@mui/material';
import axios from 'axios'
import { socioPDF } from '../functions/FunctionsFront'
import logo from '../../images/logo_image.png'

function TabelaSocio() {
    const [listSocios, setListSocios] = useState([])
    const [imageSocios, setImageSocios] = useState("")
    const columns = useMemo(() => [
        {
            accessorKey: 'nome_socio',
            header: 'Nome',
        },
        {
            accessorKey: 'contato_socio',
            header: 'Contato',
        },
        {
            accessorKey: 'email_socio',
            header: 'Email',
        },
        {
            accessorKey: 'nif_socio',
            header: 'NIF',
        },
        {
            accessorKey: 'data_inscricao',
            header: 'Data Inscrição',
        }
    ], [])

    useEffect(() => {
        axios.get('/socio/all_socios')
            .then((response) => {
                setListSocios(response.data.socios)
                console.log(response.data.socios)
            })
            .catch((err) => {
                console.error(err)

            })
    }, [])
    return (
        <div className='container-fluid'>
            <h1 className='h1 ml-5 titleSection text-center mt-3'>Lista de Sócios</h1>
            <hr className='lineSection mb-5' />
            <br />
            {/*imageSocios && (<img src={imageSocios} alt="dd" className='img-fluid' />)*/}
            <MaterialReactTable columns={columns} data={listSocios}
                enableColumnFilters={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnResizing
                enableColumnActions={false}
                renderDetailPanel={({ row }) => {
                    return (
                        <Box>
                            <div className='container-fluid'>
                                <div className='d-flex d-flex justify-content-center'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            {/*<img src={row.original.stringImage} className='img-fluid img-thumbnail center-block d-block mx-auto img_socio_tb' />*/}
                                            <button className='btn btn-primary btn-block mt-1  w-100' onClick={(e) => socioPDF(row.original.data_inscricao, row.original.nome_socio, row.original.nasc_socio, row.original.morada_socio, row.original.postal_socio, row.original.localidade_socio, row.original.email_socio, row.original.nif_socio, row.original.contato_socio, row.original.logo_corporacao)}>Criar PDF</button>
                                        </div>
                                        <div className='col-sm-6'>
                                            <p className='mt-3'>
                                                <b>Nome</b><br />
                                                {row.original.nome_socio}
                                            </p>
                                            <p>
                                                <b>Data de Nascimento</b><br />
                                                {row.original.nasc_socio}
                                            </p>
                                            <p>
                                                <b>Morada</b>
                                                <br />
                                                {row.original.morada_socio}, {row.original.postal_socio}
                                                <br />
                                                {row.original.localidade_socio}
                                            </p>
                                            <p>
                                                <b>Email</b>
                                                <br />
                                                {row.original.email_socio}
                                            </p>
                                            <p>
                                                <b>NIF</b>
                                                <br />
                                                {row.original.nif_socio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    )
                }}
                muiTableHeadCellProps={{
                    style: {
                        color: 'white',
                        fontSize: 28

                    }
                }}
                muiTableHeadRowProps={{
                    style: {
                        backgroundColor: 'orange'
                    }
                }}
                muiTopToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
                muiBottomToolbarProps={{
                    style: {
                        backgroundColor: 'orangered'
                    }
                }}
            />
            <br />
        </div>
    )
}

export default TabelaSocio