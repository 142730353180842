import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Parallax } from 'react-scroll-parallax';


import pa_1 from '../../images/parallax_images/pa_1.jpg'
import pa_2 from '../../images/parallax_images/pa_2.jpg'
import pa_3 from '../../images/parallax_images/pa_3.jpg'
import pa_4 from '../../images/parallax_images/pa_4.jpg'
import pa_5 from '../../images/parallax_images/pa_5.jpg'
import pa_6 from '../../images/parallax_images/pa_6.jpg'
import pa_7 from '../../images/parallax_images/pa_7.jpg'
import pa_8 from '../../images/parallax_images/pa_8.jpg'
import pa_9 from '../../images/parallax_images/pa_9.jpg'


const containerTransition = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        x: '-100vw',
        transition: { ease: 'easeInOut' }
    }
}

function Missao() {
    return (
        <motion.div className='container-fluid g-0'
            variants={containerTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className='row bannerMissao P-5 mb-5'>
                <h1 className='h1 text-center'>Missão</h1>
            </div>
            <div className='container'>
                <p className='mt-2'>
                    A Associação Humanitária de Bombeiros Voluntários de Fundão tem como missão a salvaguarda das vidas humanas,
                    proteção de bens e preservação do ambiente, e a prestação de outros serviços previstos nos regulamentos internos
                    e demais legislação aplicável, com empenhamento contínuo na formação e operacionalidade para dar uma eficaz
                    resposta às emergências, mantendo em atividade, para o efeito, um corpo de bombeiros voluntários empenhado em
                    conservar intemporal o lema “VIDA POR VIDA”.
                </p>
                <h1 className='h1 titleSection text-center mb-3'>Visão</h1>
                <p>
                    Instituição de referência, reconhecida e certificada pela qualidade dos seus serviços, baseada no trabalho de equipa e numa gestão
                    sustentável, orientada para o apoio humanitário numa lógica de avaliação e melhoria contínua de processos,
                    na procura de qualidade no cumprimento das nossas missões, consolidando as respostas operacionais e atuando de
                    uma forma proactiva às necessidades emergentes da comunidade e aos desafios que se lhes colocam.
                </p>
                <h1 className='h1 titleSection text-center mb-3'>Valores</h1>
                <p className='mb-5'>
                    É promovido e desenvolvido o respeito pela pessoa humana, tendo em conta a natureza unitária da pessoa humana e o respeito pela sua dignidade.
                    Os nossos Valores são os princípios condutores da nossa Instituição, os quais estão presentes em todas as atividades.
                </p>
            </div>
            <div className='container-fluid items_area p-0'>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_1} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Voluntariado</p>
                                <p className='fs-4 text-center'>A <i>alma mater</i> da nossa organização</p>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Honestidade</p>
                                <p className='fs-4 text-center'>Serviços prestados com base na transparência e seriedade</p>
                            </Parallax>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_2} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_3} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Capacidade de cooperação</p>
                                <p className='fs-4 text-center'>Envolvimento de todos os parceiros na identificação e construção de soluções adequadas
                                    para as questões emergentes do nosso tempo</p>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className='row  g-0'>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Respeito/Ética</p>
                                <p className='fs-4 text-center'>Respeitar a condição de todos os que recorrem aos nossos serviços e daqueles que connosco colaboram</p>
                            </Parallax>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_4} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                </div>
                <div className='row  g-0'>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_5} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Hierarquia</p>
                                <p className='fs-4 text-center'>Representada pelos diferentes níveis de comando da organização, é estabelecida obedecendo a
                                    três diferentes áreas: estratégica, tática e operacional; embora possuindo autonomia encontram-se, em parte, interligadas entre si
                                </p>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className='row  g-0'>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Disciplina</p>
                                <p className='fs-4 text-center'>Capacidade de obedecer a um sistema lógico e positivo de realizar as tarefas necessárias, de maneira ordenada, sistemática e metódica</p>
                            </Parallax>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_6} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_7} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Segurança</p>
                                <p className='fs-4 text-center'>Como bem comum que visa realizar o apoio humanitário de forma a não colocar em perigo o próprio socorro e quem o promove</p>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Confiança</p>
                                <p className='fs-4 text-center'>Criar um ambiente de confiança mútua, entre nós e os que nos apoiam, inspirando-nos na generosidade, partilha e respeito pelas especificidades de cada um</p>
                            </Parallax>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_8} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6 col-xs-6'>
                        <Parallax speed={1}>
                            <img src={pa_9} className='img-fluid img-pa' />
                        </Parallax>
                    </div>
                    <div className='col-md-6 col-xs-6 text-pa'>
                        <div className='t-pa'>
                            <Parallax speed={1}>
                                <p className='fs-2 text-center'>Proximidade, Humanismo e Tolerância </p>
                                <p className='fs-4 text-center'>Ter a capacidade de se colocar no lugar do outro de forma a dar respostas assertivas</p>
                            </Parallax>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='h1 titleSection text-center mt-5 mb-3'>Áreas de Atuação</h1>
            <div className='container '>
                <div className='row'>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Prevenção e combate de incêndios</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Salvamentos</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Resgate em grande ângulo/ altura</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Desencarceramento em acidentes rodoviários e ferroviários</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Intervenção em incidentes elétricos, hidráulico, com matérias perigosas, ou com redes de gás</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Corte de árvores em risco iminente de queda</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Intervenção em locais com produtos perigosos</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Emergência médica pré-hospitalar</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-xs-4 p-3'>
                        <div className='t-pa'>
                            <p className='fs-4 text-center'>Salvamento aquático ou afogamentos</p>
                        </div>
                    </div>
                </div>

            </div>
        </motion.div>
    )
}

export default Missao